<template>
  <Modal
    :open="isOpenned"
    :class="size"
  >
    <template slot="content">
      <div
        class="emd-container _highlight_header _footer_end _footer_spaced_mobile"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">
            {{ $t('modal.password.title') }}
          </h2>
          <button
            v-if="!closeless"
            class="emd-btn _type_link"
            v-on:click="closeModal()"
          >
            <i class="uil uil-times" />
          </button>
        </div>
        <form
          @submit="confirmPassword"
          name="confirmPassword"
          novalidate="true"
        >
          <fieldset>
            <div class="emd-container__content">
              <emd-alert
                :variant="formError ? 'danger' : 'success'"
                v-if="showAlert"
                @cancel="showAlert = false"
                :title="
                  formError
                    ? $t('account.settings.alert.error-title')
                    : $t('account.settings.alert.success-title')
                "
              >
                <template slot="content">
                  <div v-if="formError">
                    <p
                      v-for="(error, index) in errors"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                  <p v-else>{{ $t('account.settings.saved-success') }}</p>
                </template>
              </emd-alert>
              <p
                class="eu-mb_3"
                v-if="text"
              >
                {{ text }}
              </p>
              <div class="el-chunk">
                <div class="emd-form-group">
                  <label
                    class="emd-label _required"
                    for="password"
                    >{{ $t('modal.password.type-password') }}</label
                  >
                  <div class="emd-input-group">
                    <input
                      class="emd-input fs-hide"
                      :type="inputType"
                      v-model="password"
                      id="password"
                      autofocus
                    />
                    <a
                      v-on:click="toggleInput()"
                      class="emd-input-icon__link"
                    >
                      <i
                        v-if="inputType === 'password'"
                        class="uil uil-eye"
                      />
                      <i
                        v-if="inputType === 'text'"
                        class="uil uil-eye-slash"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="emd-container__footer">
              <button
                class="emd-btn _color_primary _type_text"
                type="button"
                v-on:click="closeModal()"
                :disabled="loadingPassword"
              >
                {{ $t('modal.password.cancel') }}
              </button>
              <button
                class="emd-btn _color_primary"
                type="submit"
                :disabled="!password || loadingPassword"
              >
                <span v-if="!loadingPassword">{{
                  !textButtonSubmit ? $t('modal.password.ok') : textButtonSubmit
                }}</span>
                <emd-loader
                  v-if="loadingPassword"
                  :type="''"
                ></emd-loader>
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from '@/components/project/globals/Modal.vue'
import { EmdLoader } from 'emerald-vue/src/components'
import EmdAlert from '@/components/emerald/molecules/EmdAlert.vue'

export default {
  name: 'ModalPassword',
  components: {
    Modal,
    EmdLoader,
    EmdAlert
  },
  props: {
    callback: {
      type: Function,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    textButtonSubmit: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: '_size_small'
    },
    closeless: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isOpenned: true,
      password: null,
      inputType: 'password',
      secretKey: null,
      errors: [],
      loadingPassword: false,
      debounceTimer: undefined,
      showAlert: false,
      formError: false
    }
  },
  methods: {
    openModal: function () {
      this.isOpenned = true
    },
    closeModal: function () {
      this.$emit('cancel')
    },
    toggleInput: function () {
      this.inputType = this.inputType === 'text' ? 'password' : 'text'
    },
    confirmPassword: function (e) {
      e.preventDefault()
      this.loadingPassword = true
      this.showAlert = false
      const password = window.btoa(this.password)
      const msgErrorDefault = this.$t('modal.password.error-default')
      this.callback(password)
        .then(res => {
          this.loadingPassword = false
          this.formError = false
          this.showAlert = true
          this.closeModal()
        })
        .catch(err => {
          this.formError = true
          this.showAlert = true
          this.loadingPassword = false
          if (err.body && Array.isArray(err.body.errors)) {
            err.body.errors.forEach(error => {
              if (!this.errors.includes(error.message)) {
                this.errors.push(error.message)
              }
            })
          } else {
            this.errors.push(msgErrorDefault)
          }
          this.loadingPassword = false
          clearTimeout(this.debounceTimer)
          this.debounceTimer = setTimeout(() => {
            this.errors = []
            this.typeError = null
          }, 7000)
        })
    },
    closeAlert: function () {
      this.errors = []
    }
  }
}
</script>
