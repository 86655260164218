<template>
  <emd-card>
    <emd-card-header>
      <emd-text> {{ $t('header') }}</emd-text>
      <template #actions>
        <a
          v-if="canShowShop"
          id="link-shop-payment-links"
          target="_blank"
          rel="noopener noreferrer"
          class="emd-btn _color_primary _size_small"
          :href="$sanitize(urlShop)"
          @click="shopTrack"
        >
          <i class="uil uil-plus eu-mr_1" />
          {{ $t('btn-create') }}
        </a>
      </template>
    </emd-card-header>

    <emd-card-content customClass="emd-table-responsive eu-no-padding">
      <table-links
        :items="items"
        :toDetail="toDetail"
      />

      <empty-list-filter v-if="showEmptyListFilter" />
      <empty-list
        v-if="showEmptyList"
        entity="payment-links"
      />

      <pagination
        v-if="showPagination"
        type="list"
        :action="action"
        :pagination="pagination"
        :totalShowing="totalItems"
      />
    </emd-card-content>
  </emd-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  EmdCard,
  EmdCardHeader,
  EmdCardContent,
  EmdText
} from 'emerald-vue/src/components'

import EmptyList from '@/components/project/globals/EmptyList.vue'
import EmptyListFilter from '@/components/project/globals/EmptyListFilter.vue'
import Pagination from '@/components/project/globals/Pagination.vue'

import buildUrl from '@/utils/buildUrl.utils'
import shopEnabled from '@/actions/shop.actions'

import TableLinks from './components/TableLinks.vue'

export default {
  name: 'PaymentLinksTable',

  components: {
    EmdCard,
    EmdCardHeader,
    EmdCardContent,
    EmdText,

    EmptyList,
    EmptyListFilter,
    Pagination,
    TableLinks
  },

  props: {
    showPagination: {
      type: Boolean,
      default: true
    },

    action: {
      type: String,
      default: 'GET_PAYMENTS_LINKS'
    },

    isLoading: Boolean,
    items: Array,
    pagination: Object,
    totalItems: Number
  },

  data () {
    return {
      toDetail: {
        name: 'paymentLinksDetail',
        params: {
          id: 'id'
        },
        query: ['created_at']
      }
    }
  },

  computed: {
    ...mapGetters([
      'merchantId',
      'accountId',
      'permissionIsEnabled',
      'componentIsEnabled',
      'shopIsEnabled'
    ]),

    canShowShop () {
      return shopEnabled(
        this.permissionIsEnabled,
        this.componentIsEnabled,
        this.shopIsEnabled
      )
    },

    showEmptyList () {
      return this.emptyList() && Object.keys(this.routeQuery).length <= 2
    },

    showEmptyListFilter () {
      return this.emptyList() && Object.keys(this.routeQuery).length >= 2
    },

    urlShop () {
      return buildUrl({
        url: process.env.VUE_APP_SHOP_URL,
        pathParams: `${this.merchantId}/${this.accountId}/items`
      })
    }
  },

  methods: {
    emptyList () {
      return !this.isLoading && this.pagination?.total_items === 0
    },

    routeQuery () {
      const routeQuery = { ...this.$route.query }
      delete routeQuery.date_filters
      delete routeQuery.period_type

      return routeQuery
    },

    shopTrack () {
      this.$gtag.event('click', {
        event_category: 'shopClick',
        event_label: 'Link shop página de links de pagamento'
      })
    }
  }
}
</script>

<i18n>
  {
    "pt-BR": {
      "header": "Lista de links de pagamento",
      "btn-create": "Criar novo link"
    },
    "en-US": {
      "header": "List of payment links",
      "btn-create": "Create new link"
    }
  }
</i18n>
