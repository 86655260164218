import { DashInstance, catchError } from '@/store'
import filters from '@/utils/charges_new_filter'
import {
  isPrepaid,
  isPrivateLabel,
  PAYMENT_METHOD_ENUM
} from '../../enums/payment-method.enum'

const charge = {
  state: {
    charges: { items: [], pagination: {} },
    loadingList: true,
    filterOptions: filters
  },
  mutations: {
    toggleLoadingList (state, status) {
      state.loadingList = status
    },
    SET_CHARGES (state, charges) {
      state.charges = charges
    }
  },
  getters: {
    charges (state) {
      return state.charges
    }
  },
  actions: {
    GET_CHARGES (context, params = {}) {
      context.commit('toggleLoadingList', true)
      const isTestAccount =
        context.rootGetters.account.account_type.value === 'test'
      return DashInstance.charge
        .getAll(
          params.merchantId,
          params.accountId,
          params.query,
          isTestAccount
        )
        .then(res => {
          res.items.map(item => {
            if (isPrepaid(item)) {
              item.payment_method = `${item.payment_method} ${item.last_transaction_funding_source}`
              return
            }
            if (isPrivateLabel(item.payment_method)) {
              item.payment_method = PAYMENT_METHOD_ENUM.PRIVATE_LABEL
            }
          })
          context.commit('SET_CHARGES', res)
          context.commit('toggleLoadingList', false)
          Promise.resolve(res)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_CHARGE (context, params = {}) {
      return DashInstance.charge
        .getById(
          params.merchantId,
          params.accountId,
          params.chargeId,
          params.query
        )
        .then(res => {
          res.payment_method =
            res.payment_method === 'private_label'
              ? 'Private Label'
              : res.payment_method
          return Promise.resolve(res)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_TRANSACTIONS (context, params = {}) {
      return DashInstance.charge
        .getTransactions(params.merchantId, params.accountId, params.chargeId)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_TRANSACTION (context, params = {}) {
      return DashInstance.charge
        .getTransactionById(
          params.merchantId,
          params.accountId,
          params.transactionId
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    CANCEL_CHARGE (context, params = {}) {
      return DashInstance.charge
        .cancel(
          params.merchantId,
          params.accountId,
          params.chargeId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    CAPTURE_CHARGE (context, params = {}) {
      return DashInstance.charge
        .capture(
          params.merchantId,
          params.accountId,
          params.chargeId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    SEND_CHARGE_REPORT_FILE (context, params = {}) {
      return DashInstance.charge
        .reportFile(params.merchantId, params.accountId, params.body)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    ATTEMPT_CHARGE (context, params = {}) {
      return DashInstance.charge
        .attempt(
          params.merchantId,
          params.accountId,
          params.chargeId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    RESYNC_CHARGE (context, params = {}) {
      return DashInstance.charge
        .resync(
          params.merchantId,
          params.accountId,
          params.chargeId,
          params.query
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    RETRY_CHARGE (context, params = {}) {
      return DashInstance.charge
        .retry(
          params.merchantId,
          params.accountId,
          params.chargeId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    GET_LETTER (context, params = {}) {
      return DashInstance.charge
        .getLetter(
          params.merchantId,
          params.accountId,
          params.chargeId,
          params.query
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    toggleLoadingList ({ commit }, status) {
      commit('toggleLoadingList', status)
    }
  }
}

export default charge
