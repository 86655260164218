<template>
  <div class="eu-display_flex eu-align-items_center eu-width_100">
    <div class="emd-form-group eu-py_2 eu-width_100">
      <input
        :data-fullstory="fullstoryId"
        class="emd-input"
        type="text"
        v-model="copiable"
        disabled
      />
    </div>
    <div
      class="emd-tip _hoverble eu-mx_1"
      v-if="!viewCopy"
    >
      <div class="emd-tip__container">
        <div
          class="emd-tip__box _color_contrast _arrow_top _position_bottom _no_shadow eu-mt_2 eu-ml_2"
        >
          {{ copyOrNot }}
        </div>
      </div>
      <button
        class="emd-action-button header-button"
        @click="handleClicked"
      >
        <emerald-icon
          icon="copy"
          data-testid="button-copy"
        />
      </button>
    </div>
  </div>
</template>
<script>
import EmeraldIcon from '@/components/emerald/atoms/EmeraldIcon'

export default {
  name: 'CopyClipboard',
  components: {
    EmeraldIcon
  },
  data () {
    return {
      isCopied: false
    }
  },
  computed: {
    copyOrNot () {
      return this.isCopied
        ? this.$t('account.keys.copied')
        : this.$t('account.keys.copy')
    }
  },
  props: {
    copiable: String,
    secondaryButtons: Boolean,
    viewCopy: Boolean,
    fullstoryId: String
  },
  methods: {
    handleClicked () {
      this.$copyText(this.copiable).then(() => {
        this.isCopied = true
      })
    }
  }
}
</script>
