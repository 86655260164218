<template>
  <div class="el-core__header__right coreRight">
    <div class="emd-drawer _right">
      <div
        class="emd-drawer__trigger coreRight-trigger"
        :class="{ _open: isOpen }"
      >
        <button
          class="emd-action-button"
          @click="toggleDrawerMenu"
        >
          <i class="uil uil-ellipsis-v emd-action-button__icon"></i>
        </button>
      </div>
      <div
        class="coreRight-content"
        :class="{ _open: isOpen, 'coreRight-content__open': isOpen }"
      >
        <div
          class=""
          v-if="canShowMoreList || canShowAppsList"
        >
          <emd-dropdown>
            <template v-slot:trigger>
              <div>
                <ul>
                  <li
                    v-for="item in getPopoverItems"
                    :key="item.title"
                  >
                    <button
                      class="coreRight-items"
                      @click="item.action"
                    >
                      {{ item.title }}
                    </button>
                  </li>
                </ul>
              </div>
            </template>
          </emd-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EmdDropdown } from 'emerald-vue/src/components'

import { cannyUrl, cannyInit } from '@/utils'
import { isSeller } from '@/enums/user-role.enum'
import shopEnabled from '@/actions/shop.actions'

export default {
  name: 'AppCoreHeaderRight',
  components: {
    EmdDropdown
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    merchant: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    },
    profile: {
      type: Object,
      default: null
    }
  },
  created () {
    const Canny = window?.Canny

    if (Canny && this.isEmployee) {
      cannyInit(Canny, this.profile.email, this.merchant.id, this.merchant.name)
    }
  },
  data () {
    return {
      profileOpen: false,
      popoverItems: [
        {
          title: this.$i18n.t('sidemenu.popover_items.myBusiness'),
          action: () => {
            window.open(
              `${process.env.VUE_APP_COMPANY_URL}/${this.profile.merchant.id}`,
              '_blank'
            )
          }
        },
        {
          title: this.$i18n.t('sidemenu.popover_items.myProfile'),
          action: () => {
            window.open(
              `${process.env.VUE_APP_PROFILE_URL}`,
              '_blank',
              'noopener'
            )
          }
        },
        {
          title: this.$i18n.t('sidemenu.popover_items.exit'),
          action: () => {
            this.$store.dispatch('toggleGlobalLoading', true)
            this.$store.dispatch('DELETE_AUTHORIZATION', true)
            window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
          }
        }
      ]
    }
  },
  computed: {
    isEmployee () {
      return this.$store.getters.isEmployee
    },
    firstName () {
      return this.profile.first_name
    },
    lastName () {
      return this.profile.last_name
    },
    urlCompany () {
      return `${process.env.VUE_APP_COMPANY_URL}/${this.merchant.id}`
    },
    profileUrl () {
      return `${process.env.VUE_APP_PROFILE_URL}`
    },
    urlBackoffice () {
      return `${process.env.VUE_APP_BACKOFFICE_URL}`
    },
    urlShop () {
      return `${process.env.VUE_APP_SHOP_URL}/${this.merchant.id}/${this.account.id}`
    },
    urlHub () {
      return `${process.env.VUE_APP_HUB_URL}?merchant_id=${this.merchant.id}&account_id=${this.account.id}`
    },
    shopIsEnabled () {
      return this.$store.getters.shopIsEnabled
    },
    hasShopUnavailable () {
      return this.$store.getters.hasShopUnavailable
    },
    canShowShop () {
      return shopEnabled(
        this.permissionIsEnabled,
        this.componentIsEnabled,
        this.shopIsEnabled
      )
    },
    canShowHub () {
      return (
        this.componentIsEnabled('apps', 'hub') &&
        this.permissionIsEnabled('links.hub:get')
      )
    },
    canShowPix () {
      return (
        this.componentIsEnabled('global', 'pix') &&
        this.permissionIsEnabled('links.pix:get')
      )
    },
    canShowAppsList () {
      return this.canShowShop || this.canShowHub
    },
    canShowMoreList () {
      return this.canShowPix
    },
    canShowDashDropDown () {
      return this.canShowAppsList || this.canShowMoreList
    },
    userRole () {
      return this.$store.getters.userRole
    },
    userType () {
      return this.$store.getters.userType
    },
    permission () {
      const type = this.userType.key.toLowerCase()
      if (this.isEmployee || type === 'owner' || type === 'admin') {
        return this.userType.value
      } else {
        return this.userRole.value
      }
    },
    showOnboarding () {
      return (
        !(this.isEmployee || isSeller(this.userRole.key)) &&
        this.componentIsEnabled('global', 'onboarding')
      )
    },
    urlPix () {
      return process.env.VUE_APP_PIX_URL
    },
    getPopoverItems () {
      const items = [...this.popoverItems]
      if (this.isEmployee) {
        items.unshift(
          ...[
            {
              title: this.$i18n.t('sidemenu.popover_items.accesBackoffice'),
              action: () => {
                window.open(`${process.env.VUE_APP_BACKOFFICE_URL}`, '_blank')
              }
            },
            {
              title: this.$i18n.t('sidemenu.popover_items.updateNotes'),
              action: () => {
                window.open(
                  'https://dashspagarme.canny.io/changelog?labels=dash-pagarme-20',
                  '_blank',
                  'noopener'
                )
              }
            },
            {
              title: this.$i18n.t('sidemenu.popover_items.feedback'),
              action: () => {
                this.getCannyUrl().then(data => window.open(data, '_blank'))
              }
            }
          ]
        )
      }

      return items
    }
  },
  methods: {
    async getCannyUrl () {
      await this.$store.dispatch('GET_CANNY_TOKEN')
      return cannyUrl(this.merchant.id, this.$store.getters.cannyToken.token)
    },
    shopTrack () {
      this.$gtag.event('click', {
        event_category: 'shopClick',
        event_label: 'Link shop header'
      })
    },
    toggleDrawerMenu () {
      this.$emit('drawerMenuClicked')
    },
    openOnboarding () {
      this.profileOpen = false
      this.$emit('openOnboarding')
    },
    triggerLogout () {
      this.$store.dispatch('toggleGlobalLoading', true)
      this.$store.dispatch('DELETE_AUTHORIZATION', true)
      window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  }
}
</script>
<style scoped>
.coreRight-trigger {
  display: none;
}

.coreRight-items {
  height: 40px;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  color: var(--gray-600-birdperson);
  font-weight: bold;
}

.coreRight-content {
  display: none;
}

@media only screen and (max-width: 768px) {
  .coreRight-trigger {
    display: flex !important;
  }

  .coreRight-content__open {
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 64px);
    position: absolute;
    right: 0;
    top: 64px;
    width: 100%;
    z-index: 0;
    padding: 12px 24px;
  }
}
</style>

<i18n>
{
  "pt-BR": {
    "apps-menu": {
      "your-apps": "Seus apps",
      "get-to-know": "Conheça",
      "shop": "Shop",
      "hub": "Hub",
      "pix": "Pix"
    },
    "company": "Company",
    "profile": "Perfil",
    "backoffice": "Backoffice",
    "profile-menu": {
      "onboarding": "Guia de acesso",
      "logout": "Sair"
    },
    "shop": {
      "unavailable": "Indisponível"
    }
  },
  "en-US": {
    "apps-menu": {
      "your-apps": "Your apps",
      "get-to-know": "Get to know",
      "shop": "Shop",
      "hub": "Hub",
      "pix": "Pix"
    },
    "company": "Company",
    "profile": "Profile",
    "backoffice": "Backoffice",
    "profile-menu": {
      "onboarding": "Onboarding",
      "logout": "Logout"
    },
    "shop": {
      "unavailable": "Unavailable"
    }
  }
}
</i18n>
