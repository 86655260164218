<template>
  <div class="el-section eu-fade-in">
    <form
      ref="boxForm"
      action="javascript:void(0)"
      @submit.prevent="submit"
      novalidate="true"
    >
      <div
        class="emd-container _highlight_header _footer_end _footer_spaced_mobile"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">{{ title }}</h2>
          <button
            class="emd-btn _color_primary _size_small"
            type="button"
            v-show="disabled"
            @click="handleClickEditBtn"
            v-if="isEditable"
          >
            <i class="uil uil-pen" /> {{ $t('account.settings.edit') }}
          </button>
          <button
            class="emd-btn _color_primary _size_small"
            type="button"
            v-show="disabled"
            @click="openConfigMfaModal"
            v-if="mfaNeeded"
          >
            <i class="uil uil-pen" /> {{ $t('account.settings.edit') }}
          </button>
        </div>
        <div
          v-if="loading"
          class="eu-pa_6 eu-align_center"
        >
          <emd-loader :type="'_invert'"></emd-loader>
        </div>
        <div
          v-else
          class="emd-container__content"
        >
          <emd-alert
            :variant="formError ? 'danger' : 'success'"
            v-if="showAlert"
            @cancel="showAlert = false"
            :title="
              formError
                ? $t('account.settings.alert.error-title')
                : $t('account.settings.alert.success-title')
            "
          >
            <template slot="content">
              <div v-if="formError">
                <p
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
              <p v-else>{{ $t('account.settings.saved-success') }}</p>
            </template>
          </emd-alert>

          <emd-alert
            v-if="showAlertInfo"
            variant="info"
            :title="$t('account.settings.alert.info-title')"
            :autoclose="false"
            noClose
          >
            <template slot="content">
              {{ $t('account.settings.alert.info-content') }}
            </template>
          </emd-alert>

          <fieldset :disabled="disabled">
            <legend class="eu-display_none">{{ title }}</legend>
            <slot name="content"></slot>
            <div v-if="hasPassword">
              <hr
                class="emd-divider eu-my_4"
                v-show="!disabled"
              />
              <password-confirmation
                v-model="password"
                v-show="!disabled"
              ></password-confirmation>
            </div>
          </fieldset>
        </div>
        <div
          class="emd-container__footer"
          v-show="!disabled"
          v-if="isEditable"
        >
          <button
            class="emd-btn _color_primary _type_text"
            type="button"
            @click="cancelEdit"
            :disabled="isLoading"
          >
            {{ $t('account.settings.cancel') }}
          </button>
          <button
            class="emd-btn _color_primary"
            tabindex="0"
            type="submit"
            :disabled="isLoading || disabledSubmit || formIsInvalid"
          >
            <emd-loader v-if="isLoading" />
            <span v-else>{{ $t('account.settings.save-updates') }}</span>
          </button>
        </div>
      </div>
    </form>
    <emd-confirm
      v-if="hasConfirm"
      ref="confirmModal"
      @confirm="submitConfirm"
      :message="messageConfirm"
    />
  </div>
</template>

<script>
import { EmdLoader, EmdConfirm } from 'emerald-vue/src/components'
import EmdAlert from '@/components/emerald/molecules/EmdAlert.vue'
import PasswordConfirmation from '@/components/project/molecules/PasswordConfirmation.vue'
import { formatErrorMessage } from '@/support/formatErrorMessage'
import { mapActions } from 'vuex'
export default {
  name: 'ConfigBox',
  components: {
    PasswordConfirmation,
    EmdLoader,
    EmdAlert,
    EmdConfirm
  },
  props: {
    callback: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    formIsInvalid: {
      type: Boolean,
      required: false,
      default: false
    },
    hideEditButton: {
      type: Boolean,
      required: false,
      default: false
    },
    hasConfirm: {
      type: Boolean,
      default: false,
      required: false
    },
    messageConfirm: {
      type: String,
      required: false
    },
    hasPassword: {
      type: Boolean,
      required: false,
      default: true
    },
    mfaNeeded: {
      type: Boolean,
      required: false,
      default: false
    },
    alertInfoFixed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    disabledSubmit () {
      if (!this.hasPassword) return false
      return !this.password
    },
    params: function () {
      return {
        merchantId: this.$store.getters.merchantId,
        accountId: this.$store.getters.accountId,
        entityId: this.$store.getters.accountId,
        refresh: true
      }
    },
    account: function () {
      return this.$store.getters.account
    },
    userType () {
      return this.$store.getters.userType.key.toLowerCase()
    },
    isEditable () {
      if (this.hideEditButton || this.mfaNeeded) return false
      return this.permissionIsEnabled('settings.account:edit')
    }
  },
  data () {
    return {
      disabled: true,
      password: '',
      isLoading: false,
      showAlert: false,
      showAlertInfo: false,
      formError: false,
      errors: [],
      label: 'webhook.form.confirm-change'
    }
  },
  methods: {
    ...mapActions({
      setConfigMfaModal: 'SET_CONFIG_MFA_MODAL'
    }),
    openConfigMfaModal () {
      this.setConfigMfaModal(true)
    },
    handleClickEditBtn () {
      this.disabled = !this.disabled
      this.$emit('clickEditButton', this.disabled)
      if (this.alertInfoFixed) this.showAlertInfo = true
    },
    submit (e) {
      if (this.hasConfirm) this.$refs.confirmModal.open()
      else this.submitContent()
    },
    submitConfirm (val) {
      if (val) this.submitContent()
      else this.cancelEdit()
    },
    submitContent () {
      const hashedPassword = window.btoa(this.password)
      this.isLoading = true
      this.showAlert = false
      this.showAlertInfo = false
      this.disabled = true
      const msgErrorInternal = this.$t('account.settings.error-internal')
      this.callback(hashedPassword)
        .then(res => {
          this.isLoading = false
          this.showAlert = true
          this.formError = false
          this.password = ''
          this.$store.dispatch('GET_ACCOUNT', this.params)
          this.$store.dispatch('GET_ATTEMPT_SETTINGS', this.params)
          this.$store.dispatch('GET_LAST_LOG', this.params)
          this.$refs.boxForm.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest'
          })
        })
        .catch(err => {
          this.formError = true
          this.isLoading = false
          this.showAlert = true
          this.showAlertInfo = false
          this.$refs.boxForm.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest'
          })
          this.errors = formatErrorMessage(err, msgErrorInternal)
        })
    },
    cancelEdit () {
      this.isLoading = false
      this.formError = false
      this.showAlert = false
      this.showAlertInfo = false
      this.password = ''
      this.disabled = true
      this.$emit('cancelEdit')
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    },
    toogleInfoFunction () {
      this.showAlertInfo = !this.showAlertInfo
    },
    fixedInfoFunction () {
      this.showAlertInfo = true
    }
  }
}
</script>
