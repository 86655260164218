import { DashInstance, catchError } from '@/store'

const webhook = {
  state: {
    webhooks: { items: [], pagination: {} },
    loadingList: true,
    loadingSettingsList: true,
    webhookSettings: { items: [], pagination: {} },
    events: null,
    filterOptions: [
      {
        name: 'webhook.filter.event',
        type: 'suggest',
        name_query: 'event',
        name_type: 'event',
        select_options: []
      },
      {
        name: 'webhook.filter.origin-type',
        type: 'select',
        name_type: 'event_entity',
        select_options: [
          {
            id: 'anticipation',
            text: 'webhook.filter.anticipation'
          },
          {
            id: 'subscription',
            text: 'webhook.filter.subscription'
          },
          {
            id: 'card',
            text: 'webhook.filter.card'
          },
          {
            id: 'checkout',
            text: 'webhook.filter.checkout'
          },
          {
            id: 'customer',
            text: 'webhook.filter.customer'
          },
          {
            id: 'charge',
            text: 'webhook.filter.charge'
          },
          {
            id: 'bank_account',
            text: 'webhook.filter.bank-account'
          },
          {
            id: 'discount',
            text: 'webhook.filter.discount'
          },
          {
            id: 'address',
            text: 'webhook.filter.address'
          },
          {
            id: 'invoice',
            text: 'webhook.filter.invoice'
          },
          {
            id: 'subscription_item',
            text: 'webhook.filter.subscription-item'
          },
          {
            id: 'order_item',
            text: 'webhook.filter.order-item'
          },
          {
            id: 'plan_item',
            text: 'webhook.filter.plan-item'
          },
          {
            id: 'order',
            text: 'webhook.filter.order'
          },
          {
            id: 'plan',
            text: 'webhook.filter.plan'
          },
          {
            id: 'recipient',
            text: 'webhook.filter.recipient'
          },
          {
            id: 'transfer',
            text: 'webhook.filter.transfer'
          },
          {
            id: 'usage',
            text: 'webhook.filter.usage'
          },
          {
            id: 'seller',
            text: 'webhook.filter.seller'
          }
        ],
        name_query: 'object_type'
      },
      {
        name: 'webhook.filter.origin-id',
        type: 'text',
        name_query: 'object_id'
      },
      {
        name: 'webhook.filter.status',
        type: 'select',
        select_options: [
          {
            id: 'pending',
            text: 'webhook.filter.pending'
          },
          {
            id: 'sent',
            text: 'webhook.filter.sent'
          },
          {
            id: 'failed',
            text: 'webhook.filter.failed'
          }
        ],
        name_query: 'status'
      },
      {
        name: 'webhook.filter.creation-period',
        type: 'select',
        select_options: [
          {
            id: 'specific_date',
            text: 'webhook.filter.period-specific'
          },
          {
            id: 'date_filters',
            text: 'webhook.filter.period-predefined'
          },
          {
            id: 'interval',
            text: 'webhook.filter.period-interval'
          }
        ],
        name_query: 'period_type'
      },
      {
        name: 'webhook.filter.specific-date',
        type: 'date',
        name_query: 'specific_date',
        period_type: 'specific_date'
      },
      {
        name: 'webhook.filter.predefined-date',
        type: 'select',
        select_options: [
          {
            id: 'Today',
            text: 'webhook.filter.predefined-today'
          },
          {
            id: 'Yesterday',
            text: 'webhook.filter.predefined-yesterday'
          },
          {
            id: 'SevenDays',
            text: 'webhook.filter.predefined-7-days'
          },
          {
            id: 'FifteenDays',
            text: 'webhook.filter.predefined-15-days'
          },
          {
            id: 'OneMonth',
            text: 'webhook.filter.predefined-30-days'
          }
        ],
        name_query: 'date_filters',
        period_type: 'date_filters'
      },
      {
        name: 'webhook.filter.initial-date',
        type: 'date',
        name_query: 'since',
        period_type: 'interval'
      },
      {
        name: 'webhook.filter.final-date',
        type: 'date',
        name_query: 'until',
        period_type: 'interval'
      }
    ]
  },
  mutations: {
    toggleLoadingList (state, status) {
      state.loadingList = status
    },
    toggleLoadingSettingsList (state, status) {
      state.loadingSettingsList = status
    },
    SET_WEBHOOKS (state, webhooks) {
      state.webhooks = webhooks
    },
    SET_WEBHOOK_SETTINGS (state, webhookSettings) {
      state.webhookSettings = webhookSettings
    },
    SET_EVENTS (state, events) {
      state.events = events.sort()
    },
    SET_FILTER_EVENT_OPTIONS (state) {
      const option = state.filterOptions.find(
        option => option.name_query === 'event'
      )
      option.select_options = state.events
    }
  },
  getters: {
    webhooks (state) {
      return state.webhooks
    },
    webhookSettings (state) {
      return state.webhookSettings
    },
    webhookFilter (state) {
      return state.filterOptions
    },
    webhookLoading (state) {
      return state.loadingList
    }
  },
  actions: {
    GET_WEBHOOKS (context, params = {}) {
      context.commit('toggleLoadingList', true)
      params.query.fields =
        'id,status,event,event_name,created_at,object{id,type,parent_id,parent_type}'
      return DashInstance.webhook
        .getAll(params.merchantId, params.accountId, params.query)
        .then(res => {
          context.commit('SET_WEBHOOKS', res)
          context.commit('toggleLoadingList', false)
          Promise.resolve(res)
        })
        .catch(err => {
          const obj = {
            items: [],
            pagination: {
              total_items: 0
            }
          }
          context.commit('SET_WEBHOOKS', obj)
          context.commit('toggleLoadingList', false)
          return catchError(err, context)
        })
    },
    GET_WEBHOOK (context, params = {}) {
      return DashInstance.webhook
        .getById(
          params.merchantId,
          params.accountId,
          params.webhookId,
          params.query
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_WEBHOOK_SETTINGS (context, params = {}) {
      context.commit('toggleLoadingSettingsList', true)
      return DashInstance.webhook
        .getSettings(params.merchantId, params.accountId, params.query)
        .then(res => {
          context.commit('SET_WEBHOOK_SETTINGS', res)
          context.commit('toggleLoadingSettingsList', false)
          Promise.resolve(res)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_WEBHOOK_SETTINGS_BY_ID (context, params = {}) {
      return DashInstance.webhook
        .getSettingsById(params.merchantId, params.accountId, params.webhookId)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_EVENTS (context) {
      if (!context.state.events) {
        return DashInstance.webhook
          .getEvents()
          .then(res => {
            context.commit('SET_EVENTS', res)
            context.commit('SET_FILTER_EVENT_OPTIONS')
            Promise.resolve(res)
          })
          .catch(err => {
            return catchError(err, context)
          })
      } else {
        Promise.resolve(context.state.events)
      }
    },
    GET_EVENTS_CATEGORIZED (context) {
      return DashInstance.webhook
        .getEventsCategorized()
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    CREATE_WEBHOOK_SETTINGS (context, params = {}) {
      return DashInstance.webhook
        .createSettings(params.merchantId, params.accountId, params.body)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    UPDATE_WEBHOOK_SETTINGS (context, params = {}) {
      return DashInstance.webhook
        .updateSettings(
          params.merchantId,
          params.accountId,
          params.webhookId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_PASSWORD_AUTHENTICATION (context, params = {}) {
      return DashInstance.webhook
        .getPassword(
          params.merchantId,
          params.accountId,
          params.webhookId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    RETRY_WEBHOOK (context, { params, body }) {
      return DashInstance.webhook
        .retry(params.merchantId, params.accountId, body)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    DELETE_WEBHOOK_SETTINGS (context, params = {}) {
      return DashInstance.webhook
        .deleteSettings(
          params.merchantId,
          params.accountId,
          params.webhookId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    CHANGE_STATUS_WEBHOOK (context, params = {}) {
      return DashInstance.webhook
        .changeStatus(
          params.merchantId,
          params.accountId,
          params.webhookId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    SEND_DISABLED_WEBHOOK (context, params = {}) {
      return DashInstance.webhook
        .disableSettings(
          params.merchantId,
          params.accountId,
          params.webhookId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    }
  }
}

export default webhook
