import Settings from '@/views/Settings/Settings.vue'
import AccountConfig from '@/views/Settings/AccountConfig.vue'
import store from '@/store'
import Keys from '@/views/Account/Keys.vue'

const routeSettings = {
  path: 'settings/',
  beforeEnter: (to, from, next) => {
    if (store.getters.showSettingsSection) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  component: Settings,
  children: [
    {
      path: '',
      name: 'settings',
      redirect: { name: 'account-config' },
      beforeEnter: (to, from, next) => {
        if (store.getters.showSettingsSection) {
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      }
    },
    {
      path: 'account-config',
      name: 'account-config',
      component: AccountConfig,
      beforeEnter: (to, from, next) => {
        if (store.getters.showAccountConfig) {
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      },
      meta: {
        title: 'browser-title.account-config'
      }
    },
    {
      path: 'gateways',
      name: 'gateways',
      component: () =>
        import(
          /* webpackChunkName: "gateways" */ '@/views/Settings/Gateways.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (store.getters.showGatewaysConfig) {
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      },
      meta: {
        title: 'browser-title.gateways'
      }
    },
    {
      path: 'fees',
      name: 'fees',
      component: () =>
        import(/* webpackChunkName: "webhookconfig" */ '@/views/Settings/Fees'),
      beforeEnter: (to, from, next) => {
        if (store.getters.showFees) {
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      },
      meta: {
        title: 'browser-title.fees'
      }
    },
    {
      path: 'functionalities',
      name: 'functionalities',
      component: () =>
        import(
          /* webpackChunkName: "functionalities" */ '@/views/Settings/Functionalities.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (store.getters.showFunctionalitiesConfig) {
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      },
      meta: {
        title: 'browser-title.functionalities'
      }
    },
    {
      path: 'payment-methods',
      name: 'payment-methods',
      component: () =>
        import(
          /* webpackChunkName: "paymentmethods" */ '@/views/Settings/PaymentMethods.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (store.getters.showPaymentsConfig) {
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      },
      meta: {
        title: 'browser-title.payment-methods'
      }
    },
    {
      path: 'recurrence',
      name: 'recurrence',
      component: () =>
        import(
          /* webpackChunkName: "recurrence" */ '@/views/Settings/Recurrence.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (store.getters.showRecurrenceConfig) {
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      },
      meta: {
        title: 'browser-title.recurrence'
      }
    },
    {
      path: 'order-config',
      name: 'order-config',
      component: () =>
        import(
          /* webpackChunkName: "orderconfig" */ '@/views/Settings/OrderConfig.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (store.getters.showOrdersConfig) {
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      },
      meta: {
        title: 'browser-title.order-config'
      }
    },
    {
      path: 'webhook-config',
      name: 'webhook-config',
      component: () =>
        import(
          /* webpackChunkName: "webhookconfig" */ '@/views/Settings/WebhookConfig.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (store.getters.showWebhooksConfig) {
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      },
      meta: {
        title: 'browser-title.webhook-config'
      }
    },
    {
      path: 'webhook-config-form/:id?',
      name: 'webhook-config-form',
      component: () =>
        import(
          /* webpackChunkName: "webhookconfigform" */ '@/views/Settings/WebhookConfigForm.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (store.getters.permissionIsEnabled('settings.webhooks:edit')) {
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      },
      meta: {
        title: 'browser-title.webhook-config'
      }
    },
    {
      path: 'keys',
      name: 'keys',
      component: Keys,
      beforeEnter: (to, from, next) => {
        if (
          store.getters.permissionIsEnabled('developer.key.public:get') ||
          store.getters.permissionIsEnabled('developer.key.secret:get')
        ) {
          next()
        } else {
          store.commit('toggleGlobalError', { status: true, code: 404 })
        }
      },
      meta: {
        title: 'browser-title.keys'
      }
    }
  ]
}
export { routeSettings }
