<template>
  <div class="el-core__header__left header-container">
    <div class="emd-sidemenu__trigger">
      <emd-button-menu
        :btnMenuState="isOpen"
        @btnMenuClicked="toggleAppMenu"
      />
    </div>
    <div
      class="emd-control"
      :class="classList"
      data-onboarding="navigation"
      v-click-outside="closeDropdown"
    >
      <div class="emd-control__wrapper">
        <span class="emd-control__status status">
          {{ status.value }}
        </span>
        <div class="emd-control__link">
          <div class="emd-control__icon header-icon">
            <emerald-icon
              v-if="isJPMTheme()"
              icon="logoJPM"
              class="jpm-icon"
            />
            <emerald-icon
              v-else
              icon="pagarme"
              class="pagarme-icon"
            />
          </div>
          <div class="eu-display_flex eu-column company-container">
            <span
              class="company-name"
              v-if="merchant"
              :title="merchant.account.name"
              >{{ merchant.account.name }}</span
            >
            <span
              class="company-document"
              :title="companyDocument"
              >{{ companyDocument }}</span
            >
          </div>
        </div>
        <emd-tippy
          id="account"
          :type="'ghost'"
          :position="'left'"
          :content="$t('tooltip.account')"
        >
          <button
            class="emd-action-button header-button"
            @click="contextDropdown"
          >
            <emerald-icon icon="direction" />
          </button>
        </emd-tippy>
      </div>
      <div class="header-dropdown emd-control__dropdown jsControlAccDropdown">
        <app-context-select />
      </div>
    </div>
    <div class="eu-ml_4 eu-display_none eu-display_block_md">
      <div class="emd-logo-custom__image"></div>
    </div>
  </div>
</template>

<script>
import EmdButtonMenu from '@/components/emerald/atoms/EmdButtonMenu.vue'
import AppContextSelect from '@/components/project/ui/menu/ContextSelect/AppContextSelect.vue'
import { EmdTippy } from 'emerald-vue/src/components'
import EmeraldIcon from '@/components/emerald/atoms/EmeraldIcon'

export default {
  name: 'AppCoreHeaderLeft',
  components: {
    EmdButtonMenu,
    AppContextSelect,
    EmdTippy,
    EmeraldIcon
  },
  props: {
    merchant: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    },
    isOpen: {
      type: Boolean
    },
    companyDocument: {
      type: String
    }
  },
  data () {
    return {
      contextOpen: false
    }
  },
  computed: {
    status () {
      if (this.account === null) return false
      if (this.account.account_type.value === 'test') {
        return { key: 'test', value: this.$t('status.test') }
      }
      return this.statusSerializer(this.statusAccount)
    },
    summary () {
      return this.$store.getters.merchantSummary
    },
    merchantBlocked () {
      return this.summary?.is_delinquent
    },
    statusAccount () {
      if (this.merchantBlocked) return 'blocked'
      const getterStatusAccount = this.$store.getters.statusAccount

      if (getterStatusAccount.key) return getterStatusAccount.key
      return getterStatusAccount
    },
    urlCompany () {
      return `${process.env.VUE_APP_COMPANY_URL}/${this.merchant.id}`
    },
    classList () {
      const classList = []
      if (this.status.key) classList.push(`_status_${this.status.key}`)
      if (this.contextOpen) classList.push('_open')
      return classList
    }
  },
  methods: {
    contextDropdown () {
      this.contextOpen = !this.contextOpen
    },
    closeDropdown () {
      this.contextOpen = false
    },
    toggleAppMenu () {
      this.$emit('appMenuClicked')
    },
    statusKey (statusName) {
      if (statusName === 'blocked') return 'inactive'
      else if (statusName === 'pending') return 'pending'
      else return statusName
    },
    statusSerializer (statusName) {
      return {
        key: this.statusKey(statusName),
        value: this.$t(`status.${[statusName]}`)
      }
    },
    isJPMTheme () {
      return ['jpmorgan'].includes(process.env.VUE_APP_THEME)
    }
  }
}
</script>

<i18n>
  {
    "pt-BR": {
      "tooltip": {
        "backoffice": "Retornar ao backoffice",
        "account": "Selecionar Conta"
      },
      "status": {
        "test": "Teste",
        "inactive": "Inativo",
        "blocked": "Bloqueado",
        "active": "Ativo",
        "pending": "Pendente"
      }
    },
    "en-US": {
      "tooltip": {
        "backoffice": "Return to Backoffice",
        "account": "Select Account"
      },
      "status": {
        "test": "Test",
        "inactive": "Inactive",
        "blocked": "Blocked",
        "pending": "Pending"
      }
    }
  }
  </i18n>

<style scoped>
.header-icon {
  margin-right: 8px;
  width: 32px;
  height: 32px;
}
.company-container {
  width: calc(100% - 40px);
}
.company-name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.company-document {
  font-size: 12px;
  color: #8b8b92;
}
.status {
  left: 16px;
  padding: 1px 4px;
  height: 12px;
}
.pagarme-icon {
  max-width: 32px;
  height: 32px;
}
.jpm-icon {
  max-width: 32px;
  height: 32px;
  border-radius: 50%;
}
.header-button {
  padding: 0;
}
.header-dropdown {
  top: 28px !important;
}
.header-dropdown:after {
  content: ' ';
  position: absolute;
  top: -14px;
  right: 0px;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 14px solid #fff;
}
.header-container {
  display: block !important;
}
@media only screen and (max-width: 768px) {
  .status {
    left: 8px;
    padding: 1px 4px;
    height: 12px;
  }
  .header-container {
    display: flex !important;
  }
}
</style>
