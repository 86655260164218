<template>
  <component
    :is="dynamicIcon"
    class="emd-icons"
  />
</template>

<script>
export default {
  name: 'EmeraldIcon',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    dynamicIcon () {
      return () =>
        import(
          /* webpackChunkName: "icons/icon-[request]" */ '@/assets/emeraldicons/' +
            this.icon +
            '.svg?inline'
        )
    }
  }
}
</script>

<style scoped>
.emeraldicons {
  cursor: pointer;
}
</style>
