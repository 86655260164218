import { DashInstance } from '@/store'
import { toFixed, toInteger, toFloat } from './number.utils'

class MathManager {
  /**
   * sumByKeys is used to sum the values of the specified keys in a list object,
   * which is assumed to be an instance variable of the class that this function is defined in.
   * The function uses the reduce method to iterate through the list object, adding the values of the specified keys
   *  together and returning a new object with the summed values for those keys.
   *
   * The result is stored back in the list object and the function returns this to allow for method chaining.
   * @param {Array} items list sumed
   * @param {Array} keys list of properties keys to be summed
   * @returns new object with the sum of the last items
   */
  static sumByKeys (items = [], keys = ['quantity']) {
    if (items.length <= 0) return []

    const r = (acc, curr) => {
      return keys.reduce((rem, key) => {
        rem[key] = acc[key] + curr[key]
        return rem
      }, {})
    }

    return items.reduce((acc, curr) => ({
      ...acc,
      ...r(acc, curr)
    }))
  }

  /**
   * convertToMonetary is used to divide by 100 due to the amount of decimal places returned in the bff
   * api for monetary values
   * @param {number} value monetary number returned by bff api
   * @returns the value divide by 100
   */
  static convertToMonetary (value) {
    return value / 100 || 0
  }

  static percentageCalculator (value = 0, total = 100) {
    return DashInstance.commons.percentageCalculator(total, value)
  }

  static formatCurrencyBRL (value = 0) {
    return DashInstance.commons.formatCurrencyBRL(value)
  }

  static formatCurrencySymbol (value, symbol = 'R$') {
    return `${symbol} ${DashInstance.commons.formatCurrencyBRL(value)}`
  }

  /**
   * replace /\s/ (unicode) for space
   * @param {Number} value
   * @param {String} currency BRL
   * @param {String} lang pt-B
   * @returns String currency formated
   */
  static formatCurrency (value = 0, currency = 'BRL', lang = 'pt-BR') {
    return new Intl.NumberFormat(lang, {
      style: 'currency',
      currency
    })
      .format(value)
      .replace(/\s/, ' ')
  }

  static convertCentsToBRL (value) {
    return DashInstance.commons.convertCentsToBRL(value)
  }

  static formatConversionNumber (value) {
    return DashInstance.insights.formatConversionNumber(value)
  }

  /**
   * Convert number in decimal number extesion
   * ex. input: 2000 - output 2.000
   * @param {Number} value
   * @param {String} lang pt-BR
   * @returns Number
   */
  static formatNumberDecimal (value, lang = 'pt-BR') {
    return new Intl.NumberFormat(lang).format(value)
  }

  static numberBase (value) {
    const [coefficient, exponent] = value
      .toExponential()
      .split('e')
      .map(item => Number(item))

    return [coefficient, exponent]
  }

  static largestNumberInArray (array) {
    return array.reduce((a, b) => Math.max(a, b))
  }

  /**
   * @description Converts the value into a fixed float
   * @param valueToConvert the value to be converted into a fixed float
   */
  static getValue (valueToConvert, precisionValue) {
    return toFixed(toFloat(valueToConvert, 0), precisionValue)
  }

  /**
   * @description Validates the max value and defaults to 100 for invalid values 0, nan, -number
   * @param valuetoconvert the value to be converted into a fixed float
   */
  static getMax (valueToConvert) {
    const _max = toFloat(valueToConvert)
    return _max > 0 ? _max : 100
  }

  /**
   * @description Defaults to 0 for invalid values -number, NaN
   * @param precisionValue the value for the precision @default 0
   */
  static getPrecision (precisionValue = 0) {
    return Math.max(toInteger(precisionValue, 0), 0)
  }

  /**
   * @description Calculates the progress value, max value and precision value into the correct processed value
   * @param progressValue the value for the progress
   * @param maxValue the max value of the progress bat
   * @param precisionValue the precision value @default 0
   */
  static getProgress (progressValue, maxValue, precisionValue = 0) {
    const _precision = MathManager.getPrecision(precisionValue)
    const pow = Math.pow(10, _precision)
    return toFixed(
      (100 * pow * MathManager.getValue(progressValue)) /
        MathManager.getMax(maxValue) /
        pow,
      _precision
    )
  }
}

export default MathManager
