import { DashInstance, UtilitiesInstance, catchError } from '@/store'
import ProfileCore from 'profile-js'

const ProfileInstance = new ProfileCore({
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
})

const commons = {
  state: {
    timezones: [],
    gateways: [],
    roles: [],
    pref: ProfileInstance.me.getPreferences(),
    themeMode: '',
    segmentList: [],
    integrationList: [],
    modalConfigMfaIsOpen: false
  },
  mutations: {
    SET_TIMEZONES (state, timezones) {
      state.timezones = timezones
    },
    SET_GATEWAYS (state, gateways) {
      state.gateways = gateways
    },
    SET_ROLES (state, roles) {
      state.roles = roles
    },
    SET_PREF (state, pref) {
      state.pref = Object.assign(state.pref, pref)
    },
    SET_THEME (state, mode) {
      state.themeMode = mode
    },
    setSegmentList (state, value) {
      state.segmentList = value
    },
    setIntegrationList (state, value) {
      state.integrationList = value
    },
    SET_CANNY_TOKEN (state, token) {
      state.cannyToken = token
    },
    SET_CONFIG_MFA_MODAL (state, value) {
      state.modalConfigMfaIsOpen = value
    }
  },
  getters: {
    timezones (state) {
      return state.timezones
    },
    gateways (state) {
      return state.gateways
    },
    roles (state) {
      return state.roles.filter(role => role.key !== 'Controller')
    },
    pref (state) {
      return state.pref
    },
    segmentList (state) {
      return state.segmentList
    },
    integrationList (state) {
      return state.integrationList
    },
    GET_THEME (state) {
      return state.themeMode
    },
    cannyToken (state) {
      return state.cannyToken
    },
    MODAL_CONFIG_MFA_IS_OPEN (state) {
      return state.modalConfigMfaIsOpen
    }
  },
  actions: {
    GET_TIMEZONES (context) {
      if (!context.state.timezones.length) {
        return UtilitiesInstance.commons
          .getTimezones()
          .then(res => {
            context.commit('SET_TIMEZONES', res)
            return Promise.resolve(res)
          })
          .catch(err => catchError(err, context))
      } else {
        return Promise.resolve(context.state.timezones)
      }
    },
    GET_CANNY_TOKEN (context) {
      return DashInstance.commons
        .getCannyToken()
        .then(res => {
          context.commit('SET_CANNY_TOKEN', res)
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    },
    GET_GATEWAYS (context) {
      if (!context.state.gateways.length) {
        return UtilitiesInstance.commons
          .getGateways()
          .then(res => {
            context.commit('SET_GATEWAYS', res)
            return Promise.resolve(res)
          })
          .catch(err => catchError(err, context))
      } else {
        return Promise.resolve(context.state.gateways)
      }
    },
    GET_ROLES (context) {
      if (!context.state.roles.length) {
        return UtilitiesInstance.commons
          .getRoles()
          .then(res => {
            context.dispatch('SET_ROLES', res)
            return Promise.resolve(res)
          })
          .catch(err => catchError(err, context))
      } else {
        return Promise.resolve(context.state.roles)
      }
    },
    SET_ROLES (context, res) {
      if (context.getters.componentIsEnabled('roles', 'readOnly')) {
        res = res.filter(item => {
          if (item.value.toLowerCase() === 'observer') item.value = 'Read Only'
          if (
            ['manager', 'analyst', 'observer'].includes(item.key.toLowerCase())
          )
            return item
        })
      }
      context.commit('SET_ROLES', res)
    },
    GET_PREFERENCES (context) {
      const pref = ProfileInstance.me.getPreferences()
      context.commit('SET_PREF', pref)
      context.commit('SET_THEME', pref.appearance.mode)
      return pref
    },
    SET_LANGUAGE ({ dispatch, state }, lang) {
      const preferences = Object.assign(
        {},
        JSON.parse(JSON.stringify(state.pref))
      )
      preferences.language = lang
      dispatch('SET_PREFERENCES', preferences)
    },
    SET_NEW_THEME_MODE ({ dispatch, state }, mode) {
      const preferences = Object.assign(
        {},
        JSON.parse(JSON.stringify(state.pref))
      )
      preferences.appearance.mode = mode
      dispatch('SET_PREFERENCES', preferences)
    },
    SET_PREFERENCES (context, preferences) {
      const prefs = Object.assign({}, JSON.parse(JSON.stringify(preferences)))
      try {
        prefs.appearance.theme = process.env.VUE_APP_THEME
        ProfileInstance.me.setPreferences(prefs)
        if (context.getters.pref.language !== prefs.language) location.reload()
        context.commit('SET_PREF', prefs)
        context.commit('SET_THEME', prefs.appearance.mode)
        return true
      } catch (err) {
        catchError(err, context)
      }
    },
    GET_ADDRESS_BY_ZIPCODE ({ commit }, params = {}) {
      return UtilitiesInstance.commons
        .getAddressByZipCode(params.zipCode)
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err))
    },
    GET_SEGMENT_LIST ({ commit }) {
      commit('setSegmentList', UtilitiesInstance.commons.getSegmentList())
    },
    GET_INTEGRATION_LIST ({ commit }) {
      commit(
        'setIntegrationList',
        UtilitiesInstance.commons.getIntegrationList()
      )
    },
    FORCE_LIGHT_MODE ({ state, dispatch }) {
      const preferences = Object.assign({}, state.pref)
      preferences.appearance.mode = 'light'
      dispatch('SET_PREFERENCES', preferences)
    },
    SET_ERROR (context, err) {
      catchError(err, context)
    },
    SET_CONFIG_MFA_MODAL ({ commit }, value) {
      commit('SET_CONFIG_MFA_MODAL', value)
    }
  }
}

export default commons
