<template>
  <input
    class="emd-input"
    :id="id"
    type="tel"
    v-mask="'##/##/####'"
    :placeholder="$t('placeholder')"
    v-model="dateModel"
  />
</template>

<i18n>
{
  "pt-BR": {
    "placeholder": "DD/MM/AAAA"
  },
  "en-US": {
    "placeholder": "MM/DD/YYYY"
  }
}
</i18n>

<script>
import { formatDate } from '@/utils'
export default {
  name: 'InputDate',
  props: {
    id: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: null
    }
  },
  computed: {
    dateModel: {
      get () {
        return formatDate(this.locale, this.value)
      },
      set (value) {
        if (value.length <= 10) {
          this.$emit('input', this.genericDate(value))
        }
      }
    }
  },
  watch: {
    value (value) {
      this.dateModel = formatDate(this.locale, value)
    }
  },
  created () {
    this.dateModel = formatDate(this.locale, this.value)
  },
  methods: {
    genericDate (date) {
      if (date && date.length === 10) {
        var [a, b, year] = date.split('/')
        if (this.locale === 'en-US') {
          return `${year}-${a}-${b}`
        }
        return `${year}-${b}-${a}`
      }
      return date
    }
  }
}
</script>
