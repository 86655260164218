var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"emd-table"},[_c('thead',[_c('tr',[_vm._l((_vm.fields),function(field,index){return _c('th',{key:index,staticClass:"emd-text"},[_vm._v(" "+_vm._s(_vm.$t(field.label))+" "),(field.info)?_c('emd-tippy',{staticClass:"eu-ml_1",attrs:{"data-template":field.id,"id":field.id,"type":'html',"position":"bottom"}},[_c('i',{staticClass:"uil uil-info-circle",class:'emd-text _color_subtle'}),_c('emd-tippy-template-HTML',{attrs:{"id":field.id}},[_vm._t("default")],2)],1):_vm._e()],1)}),(_vm.toDetail)?_c('th'):_vm._e()],2)]),_c('tbody',_vm._l((_vm.items),function(item,itemIndex){return _c('tr',{key:itemIndex},[_vm._l((_vm.fields),function(field,index){return _c('td',{key:index,attrs:{"data-label":_vm.$t(field.label)}},[_vm._v(" "+_vm._s(field.styles)+" "),(field.type === 'link')?_c('span',[_c('router-link',{staticClass:"emd-link",attrs:{"data-testid":`link-show-${itemIndex}`,"to":{
                name: field.link.to,
                params: {
                  id: _vm.getField(item, field.link.params.id),
                  tab: field.link.params.tab
                },
                query: _vm.getQuery(item, field.link.query)
              }}},[_vm._v(_vm._s(_vm.getField(item, field.id) || '-'))])],1):_vm._e(),(field.type === 'url')?_c('span',{staticClass:"url-column"},[_c('a',{staticClass:"url-color",attrs:{"target":"_blank","rel":"noopener noreferrer","href":_vm.$sanitize(item.url),"data-testid":`url-show-${itemIndex}`}},[(_vm.copiedValue)?_c('span',{staticClass:"url-copied"},[_vm._v(" "+_vm._s(_vm.$t('table.copied', [_vm.computedValueCopied]))+" ")]):(field.text)?_c('span',[_vm._v(" "+_vm._s(field.text)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getField(item, field.id) || '-')+" ")])]),(field.showCopy)?_c('button',{staticClass:"emd-action-button",on:{"click":function($event){_vm.handleCopy(_vm.getField(item, field.id))}}},[_c('emerald-icon',{attrs:{"icon":"copy","data-testid":"button-copy"}})],1):_vm._e()]):_vm._e(),(field.type === 'text')?_c('span',{attrs:{"data-testid":`text-show-${itemIndex}`}},[_vm._v(" "+_vm._s(_vm.transformText(item, field))+" ")]):_vm._e(),(field.type === 'conditional')?_c('span',{attrs:{"data-testid":`text-show-${itemIndex}`}},[_vm._v(_vm._s(_vm.getConditionalField(item, field.id) || '-'))]):_vm._e(),(field.type === 'status')?_c('span',{staticClass:"emd-badge",class:_vm.statusClass(_vm.getField(item, field.id)),attrs:{"data-testid":`status-badge-${itemIndex}`}},[_vm._v(_vm._s(_vm.transformText(item, field)))]):_vm._e(),(field.type === 'interval')?_c('span',{attrs:{"data-testid":`interval-show-${itemIndex}`}},[_vm._v(_vm._s(_vm.$t('plan.list.each'))+" "+_vm._s(item.interval_count)+" "+_vm._s(_vm.pluralIntervals(item.interval_count, item.interval)))]):_vm._e(),(field.type === 'date')?_c('span',{attrs:{"data-testid":`date-show-${itemIndex}`}},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm.getField(item, field.id),field.format))+" ")]):_vm._e()])}),(_vm.toDetail)?_c('td',{staticClass:"eu-align_right"},[_c('router-link',{staticClass:"emd-btn-ghost",attrs:{"to":{
              name: _vm.toDetail.name,
              params: {
                id: item[_vm.toDetail.params.id],
                tab: _vm.toDetail.params.tab
              },
              query: _vm.getQuery(item, _vm.toDetail.query)
            },"data-testid":`detail-show-${itemIndex}`}},[_c('i',{staticClass:"uil uil-arrow-to-right"})])],1):_vm._e()],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }