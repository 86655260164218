<template>
  <component
    :is="dynamicComponent"
    :start="start"
    :end="end"
    :locale="locale"
    :classList="classList"
    :id="id"
    :date="date"
    :limitDate="limitDate"
    :min-date="minDate"
    @changeDate="emit($event, 'changeDate')"
    @onSubmit="emit($event, 'onSubmit')"
  />
</template>

<script>
export default {
  name: 'EmeraldIcon',
  props: {
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['simple', 'range'].indexOf(value) !== -1
      }
    },
    id: {
      type: String,
      required: false
    },
    date: {
      type: String,
      required: false
    },
    classList: {
      type: Array,
      required: false
    },
    locale: {
      type: String,
      required: false,
      default: 'pt-BR'
    },
    start: {
      type: [Object, String],
      required: false
    },
    end: {
      type: [Object, String],
      required: false
    },
    limitDate: {
      type: String,
      required: false,
      default: null
    },
    minDate: {
      type: Date,
      default: null
    }
  },
  data () {
    return {
      datepicker: {
        simple: 'EmdDatepicker',
        range: 'EmdRangeDatepicker'
      }
    }
  },
  computed: {
    isIE () {
      var ua = navigator.userAgent.toLowerCase()
      /*
        msie used to detect old browsers
        trident used to newer one
        edge to detect edge legacy
      */
      var isIE =
        ua.indexOf('msie ') > -1 ||
        ua.indexOf('trident/') > -1 ||
        ua.indexOf('edge/') > -1
      return isIE
    },
    filename () {
      return this.datepicker[this.type] + (this.isIE ? 'Fallback' : '')
    },
    dynamicComponent () {
      return () =>
        import(
          /* webpackChunkName: "datepicker/[request]" */ '@/components/emerald/atoms/' +
            this.filename +
            '.vue'
        )
    }
  },
  methods: {
    emit (event, name) {
      this.$emit(name, event)
    }
  }
}
</script>
