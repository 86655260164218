<template>
  <emd-table
    :fields="tableFields"
    :items="tableItems"
    :toDetail="toDetail"
  />
</template>

<script>
import EmdTable from '@/components/emerald/molecules/EmdTable.vue'

import { FIELDS } from '../enums/table-fields.enum'

export default {
  name: 'PaymentLinksTable',

  components: {
    EmdTable
  },

  props: {
    items: Array,
    fields: {
      type: Array,
      default: () => FIELDS
    },
    toDetail: Object
  },

  computed: {
    tableFields () {
      return this.fields.map(item => ({
        ...item,
        label: this.$t(item.label),
        text: this.$t(item?.text)
      }))
    },
    tableItems () {
      return this.items.map(item => ({
        ...item,
        type: this.$t(`items.${item.type}`)
      }))
    }
  }
}
</script>

<i18n>
  {
    "pt-BR": {
      "list": {
        "id": "ID",
        "name": "Nome",
        "type": "Tipo",
        "amount": "Valor Total",
        "status": "Status",
        "url": "Url",
        "createdAt": "Criado Em",
        "updatedAt": "Última atualização"
      },
      "items": {
        "Pedido único": "Cobrança única",
        "Assinatura recorrente": "Cobrança recorrente",
        "url": "Acessar URL"
      }
    },
    "en-US": {
      "list": {
        "id": "ID",
        "name": "Name",
        "type": "Type",
        "amount": "Amount",
        "status": "Status",
        "url": "Url",
        "createdAt": "Created at",
        "updatedAt": "Last updated"
      },
      "items": {
        "Pedido único": "Unique charge",
        "Assinatura recorrente": "Recurring charges",
        "url": "Open link"
      }
    }
  }
</i18n>
