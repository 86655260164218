<template>
  <div>
    <emd-filter
      :options="filterOptions"
      :default="queryString"
      v-model="queryString"
      :loadingList="loadingList"
      :action="action"
      v-if="permissionIsEnabled('customer:search')"
    />
    <div class="el-section">
      <div
        class="emd-container _highlight_header _header_spaced _footer_spaced_sm"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">
            {{ $t('customer.list.title') }}
          </h2>
        </div>
        <div
          v-if="loadingList"
          class="eu-pa_6 eu-align_center"
        >
          <emd-loader :type="'_invert'" />
        </div>
        <div
          v-else
          class="emd-table-responsive"
        >
          <emd-table
            :fields="fields"
            :items="customers.items"
            :toDetail="permissionIsEnabled('customer:get') && toDetail"
          ></emd-table>
          <empty-list
            v-if="showEmptyList()"
            entity="customers"
          ></empty-list>
          <empty-list-filter
            v-if="showEmptyListFilter()"
            entity="customers"
          ></empty-list-filter>
        </div>
        <pagination
          v-if="!loadingList"
          :pagination="customers.pagination"
          :totalShowing="totalItems"
          :type="'list'"
          :action="action"
        ></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { EmdLoader } from 'emerald-vue/src/components'
import EmdFilter from '@/components/emerald/organisms/EmdFilter.vue'
import EmptyList from '@/components/project/globals/EmptyList.vue'
import EmptyListFilter from '@/components/project/globals/EmptyListFilter.vue'
import Pagination from '@/components/project/globals/Pagination.vue'
import EmdTable from '@/components/emerald/molecules/EmdTable.vue'
export default {
  components: {
    EmdLoader,
    EmdFilter,
    EmptyList,
    EmptyListFilter,
    Pagination,
    EmdTable
  },
  data () {
    return {
      queryString: {
        size: 30
      },
      action: 'GET_CUSTOMERS',
      fields: [
        {
          id: 'id',
          label: 'customer.list.id',
          type: this.permissionIsEnabled('customer:get') ? 'link' : 'text',
          link: {
            to: 'customersDetail',
            params: { id: 'id', tab: 'info' }
          }
        },
        {
          id: 'code',
          label: 'customer.list.code',
          type: 'text'
        },
        {
          id: 'name',
          label: 'customer.list.name',
          type: 'text'
        },
        {
          id: 'email',
          label: 'customer.list.email',
          type: 'text'
        },
        {
          id: 'document',
          label: 'customer.list.document',
          type: 'text'
        }
      ],
      toDetail: {
        name: 'customersDetail',
        params: {
          id: 'id',
          tab: 'info'
        }
      }
    }
  },
  computed: {
    filterOptions () {
      return this.$store.state.customer.filterOptions
    },
    customers () {
      return this.$store.getters.customers
    },
    loadingList () {
      return this.$store.state.customer.loadingList
    },
    totalItems () {
      return this.customers.items.length
    }
  },
  methods: {
    showEmptyList () {
      return (
        !this.loadingList &&
        this.customers.pagination &&
        this.customers.pagination.total_items === 0 &&
        Object.entries(this.queryString).length <= 2
      )
    },
    showEmptyListFilter () {
      return (
        !this.loadingList &&
        this.customers.pagination &&
        this.customers.pagination.total_items === 0 &&
        Object.entries(this.queryString).length > 2
      )
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  }
}
</script>
