<template>
  <div class="eu-width_100 eu-display_flex eu-align-items_center">
    <copy-clipboard
      fullstory-id="secretKey"
      :copiable="formatted"
      :viewCopy="!passwordConfirmed"
    />
    <div class="eu-display_flex eu-align-items_center">
      <emd-button
        data-testid="button-show"
        class="eu-mx_2 eu-px_0"
        :text="showKey"
        color="primary"
        @click="toggle"
      />
      <emd-button
        data-testid="button-reset"
        v-if="permissionIsEnabled('developer.account.key:reset')"
        :text="$t('account.keys.reset-key')"
        color="primary"
        type="link"
        @click="handleResetKey"
        class="eu-px_2 eu-ws_nowrap"
      />
    </div>
  </div>
</template>
<script>
import { EmdButton } from 'emerald-vue/src/components'

import CopyClipboard from '@/components/emerald/molecules/CopyClipboard'

export default {
  name: 'SecretKey',
  components: {
    EmdButton,
    CopyClipboard
  },
  data () {
    return {
      isCopied: false
    }
  },
  props: {
    copiable: {
      default: '',
      type: String
    },
    passwordConfirmed: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    formatted () {
      return !this.passwordConfirmed
        ? this.copiable?.replace(/./g, '*')
        : this.copiable
    },
    showKey () {
      return !this.passwordConfirmed
        ? this.$t('account.keys.show')
        : this.$t('account.keys.hide')
    },
    copyOrNot () {
      return this.isCopied
        ? this.$t('account.keys.copied')
        : this.$t('account.keys.copy')
    }
  },
  methods: {
    toggle () {
      this.$emit('clicked')
    },
    toggleShowKey () {
      this.showSecret = !this.showSecret
    },
    handleResetKey () {
      this.$emit('onReset')
    },
    handleClicked () {
      this.$copyText(this.formatted).then(() => {
        this.isCopied = true
      })
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  }
}
</script>
<style scoped>
.secret-key {
  background-color: #edf0f4;
  border: 1px solid #a8a8ad;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
  width: 100%;
}
._mode_dark .secret-key {
  background-color: #444648;
  border-color: var(--brand--form--de--border-color);
}
</style>
