import { DashInstance, catchError } from '@/store'

const physicalWorldOrders = {
  state: {
    physicalWorldOrders: { items: [], pagination: {} },
    loadingList: true
  },
  mutations: {
    TOGGLE_LOADING_LIST (state, status) {
      state.loadingList = status
    },
    SET_PHYSICAL_WORLD_ORDERS (state, orders) {
      state.orders = orders
    }
  },
  getters: {
    physicalWorldOrders (state) {
      return state.orders
    }
  },
  actions: {
    GET_PHYSICAL_WORLD_ORDERS (context, params = {}) {
      context.commit('TOGGLE_LOADING_LIST', true)
      return DashInstance.account
        .getPhysicalWorldOrders(
          params.merchantId,
          params.accountId,
          params.query
        )
        .then(res => {
          context.commit('SET_PHYSICAL_WORLD_ORDERS', res)
          context.commit('TOGGLE_LOADING_LIST', false)
          Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    },
    GET_PHYSICAL_WORLD_ORDER (context, params = {}) {
      return DashInstance.acount
        .getPhysicalWorldOrderById(
          params.merchantId,
          params.accountId,
          params.orderId,
          params.query
        )
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    REQUEST_PHYSICAL_WORLD_SWAP_ORDER (context, params) {
      return DashInstance.account
        .createPhysicalWorldOrderSwap(
          params.merchantId,
          params.accountId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    CREATE_PHYSICAL_WORLD_ORDER (context, params = {}) {
      return DashInstance.acount
        .createPhysicalWorldOrder(
          params.merchantId,
          params.accountId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    TOGGLE_LOADING_LIST ({ commit }, status) {
      commit('TOGGLE_LOADING_LIST', status)
    }
  }
}

export default physicalWorldOrders
