<template>
  <div
    class="emd-dropdown _position_right jsDropdown"
    ref="emdPeriodFilter"
    v-click-outside="clickedOutside"
  >
    <div
      class="emd-dropdown__trigger jsDropdownTrigger"
      :class="{ _active: selectOpenned }"
      @click="toggleSelect"
    >
      <p class="emd-text _type_label _size_tiniest">{{ $t('created-at') }}</p>
      <div
        class="emd-text _type_label _size_tiny _color_primary emd-dropdown__highlight eu-display_flex eu-align-items_center"
        style="height: 18px"
      >
        <i class="uil uil-schedule eu-mr_1" />
        <span
          class="eu-mr_1 emd-dropdown__highlight__text"
          v-if="selectedOption.id === 'interval'"
          >{{ sinceDate.text }} - {{ untilDate.text }}</span
        >
        <span
          class="eu-mr_1 emd-dropdown__highlight__text"
          v-else-if="selectedOption.id === 'specific_date'"
          >{{ sinceDate.text }}</span
        >
        <span
          data-testid="date-filter"
          class="eu-mr_1 emd-dropdown__highlight__text"
          v-else
          >{{ $t(selectedOption.text) }}</span
        >
        <i class="uil uil-angle-down eu-ml_auto" />
      </div>
    </div>
    <div
      class="emd-dropdown__content jsDropdownContent eu-pa_3"
      :class="{ _active: selectOpenned }"
    >
      <emd-select-list
        :list="optionsList"
        compact
        @clickedItem="dateFiltersChanged"
      />
      <fieldset
        v-if="
          selectedOption.id === 'interval' ||
          selectedOption.id === 'specific_date'
        "
        :disabled="loadingList"
      >
        <form
          action="javascript:void(0)"
          name="formFilter"
          novalidate="true"
          @submit.prevent="search"
        >
          <hr class="emd-divider _weight_light eu-my_3" />
          <div
            class="emd-form-group eu-no-padding"
            data-children-count="1"
            v-if="selectedOption.id === 'specific_date'"
          >
            <datepicker
              type="simple"
              :limitDate="today"
              :classList="['_size_small']"
              :id="'sinceDate'"
              :date="sinceDate.value"
              :locale="locale"
              :min-date="minDate"
              @changeDate="setSpecificDate($event)"
            />
          </div>
          <template v-if="selectedOption.id === 'interval'">
            <datepicker
              type="range"
              :limitDate="today"
              :start="sinceDate.text"
              :end="untilDate.text"
              :locale="locale"
              :min-date="minDate"
              @changeDate="setIntervalDate($event)"
              @onSubmit="search"
            />
          </template>
        </form>
      </fieldset>
    </div>
  </div>
</template>
<script>
import Datepicker from './Datepicker'
import { formatDate, createSinceDate, createUntilDate } from '@/utils'
import { EmdSelectList } from 'emerald-vue/src/components'

export default {
  name: 'EmdPeriodFilter',
  props: {
    loadingList: Boolean,
    dropdownOptions: Array,
    selectedPeriod: {
      type: Object,
      default: () => ({
        id: 'SevenDays',
        text: 'last-7-days'
      })
    },
    minDate: {
      type: Date,
      default: null
    }
  },
  components: {
    Datepicker,
    EmdSelectList
  },
  data () {
    return {
      selectOpenned: false,
      selectedOption: {},
      sinceDate: { error: false, value: '', text: '' },
      untilDate: { error: false, value: '', text: '' },
      history: {
        selectedOption: 'SevenDays',
        sinceDate: {},
        untilDate: {}
      },
      options: [
        {
          id: 'Today',
          text: 'today'
        },
        {
          id: 'Yesterday',
          text: 'yesterday'
        },
        {
          id: 'SevenDays',
          text: 'last-7-days'
        },
        {
          id: 'FifteenDays',
          text: 'last-15-days'
        },
        {
          id: 'OneMonth',
          text: 'last-30-days'
        },
        {
          id: null,
          text: 'all-time'
        },
        {
          id: 'interval',
          text: 'interval'
        },
        {
          id: 'specific_date',
          text: 'specific'
        }
      ],
      datepicker: {},
      placeholder: this.locale === 'en-US' ? 'MM/DD/YYYY' : 'DD/MM/AAAA'
    }
  },
  computed: {
    today () {
      const now = new Date()
      return now.toString()
    },
    locale () {
      return this.$store.getters.pref.language
    },
    optionsList () {
      return this.options.map(option => {
        const active = option.id === this.selectedOption.id
        const disabled = this.loadingList
        return {
          id: option.id,
          label: this.$t(option.text),
          active: active,
          disabled: disabled
        }
      })
    },
    formIsInvalid: {
      get () {
        return (
          this.sinceDate.hasError ||
          this.untilDate.hasError ||
          ((this.selectedOption.id === 'specific_date' ||
            this.selectedOption.id === 'interval') &&
            this.sinceDate.value.length < 10) ||
          (this.untilDate.value.length < 10 &&
            this.selectedOption.id === 'interval')
        )
      },
      set () {}
    }
  },
  created () {
    if (this.dropdownOptions?.length > 0) {
      this.options = this.dropdownOptions
    }

    this.selectedOption = this.selectedPeriod
    this.history.selectedOption = this.selectedPeriod.id
  },

  mounted () {
    const routeQuery = this.$route.query

    if (routeQuery.period_type) {
      if (routeQuery.period_type === 'date_filters') {
        this.selectedOption = this.options.find(
          opt => opt.id === routeQuery.date_filters
        )
      } else if (
        routeQuery.period_type === 'specific_date' ||
        routeQuery.period_type === 'interval'
      ) {
        this.selectedOption = this.options.find(
          opt => opt.id === routeQuery.period_type
        )
      } else {
        this.selectedOption = {
          id: null,
          text: 'all-time'
        }
      }
    }

    if (routeQuery.created_since) {
      this.sinceDate = {
        error: false,
        text: formatDate(this.locale, routeQuery.created_since.split('T')[0]),
        value: routeQuery.created_since.split('T')[0]
      }
      this.history.sinceDate = Object.assign({}, this.sinceDate)
    }

    if (routeQuery.created_until) {
      this.untilDate = {
        error: false,
        text: formatDate(this.locale, routeQuery.created_until.split('T')[0]),
        value: routeQuery.created_until.split('T')[0]
      }
      this.history.untilDate = Object.assign({}, this.untilDate)
    }

    this.history.selectedOption = this.selectedOption.id
  },

  methods: {
    toggleSelect () {
      this.selectOpenned = !this.selectOpenned
    },
    setSpecificDate (selectedDate) {
      this.sinceDate = Object.assign({}, selectedDate)
      if (!this.sinceDate.hasError) {
        if (
          this.history.sinceDate.value !== this.sinceDate.value ||
          this.history.selectedOption !== this.selectedOption.id
        ) {
          this.search()
        }
      }
    },
    setIntervalDate (date) {
      this[date.name] = Object.assign({}, date)
    },
    dateFiltersChanged (option) {
      if (this.loadingList) return
      if (this.selectedOption.id === option.id) return
      this.sinceDate = { error: false, value: '', text: '' }
      this.untilDate = { error: false, value: '', text: '' }
      this.selectedOption = this.options.find(item => option.id === item.id)
      if (
        this.selectedOption.id !== 'specific_date' &&
        this.selectedOption.id !== 'interval'
      ) {
        this.search()
      }
    },
    clickedOutside (e) {
      if (
        !this.$refs.emdPeriodFilter.contains(e.target) &&
        this.selectOpenned
      ) {
        if (this.selectedOption.id !== this.history.selectedOption) {
          this.selectedOption = this.options.find(
            opt => opt.id === this.history.selectedOption
          )
        }
        if (
          ['interval', 'specific_date'].includes(this.history.selectedOption)
        ) {
          this.sinceDate = Object.assign({}, this.history.sinceDate)
          this.untilDate = Object.assign({}, this.history.untilDate)
        }
        this.selectOpenned = false
      }
    },
    prepareQuery () {
      if (this.sinceDate.value === '') this.sinceDate.error = true
      const query = {}
      if (
        this.selectedOption.id === 'specific_date' &&
        this.sinceDate.value !== ''
      ) {
        query.period_type = this.selectedOption.id
        query.created_since = createSinceDate(this.sinceDate.value)
        query.created_until = createUntilDate(this.sinceDate.value)
        delete query.date_filters
      } else if (
        this.selectedOption.id === 'interval' &&
        this.sinceDate.value !== '' &&
        this.untilDate.value !== ''
      ) {
        query.period_type = this.selectedOption.id
        query.created_since = createSinceDate(this.sinceDate.value)
        query.created_until = createUntilDate(this.untilDate.value)
        delete query.date_filters

        if (query.created_since > query.created_until) {
          this.sinceDate.error = true
          this.untilDate.error = true
        }
      } else {
        delete query.created_since
        delete query.created_until
        if (this.selectedOption.id === null) {
          query.period_type = 'all'
        } else {
          if (['interval', 'specific_date'].includes(this.selectedOption.id)) {
            this.selectedOption = {
              id: 'SevenDays',
              text: 'last-7-days'
            }
          }
          query.date_filters = this.selectedOption.id
          query.period_type = 'date_filters'
        }
      }
      this.history.selectedOption = this.selectedOption.id
      this.history.sinceDate = Object.assign({}, this.sinceDate)
      this.history.untilDate = Object.assign({}, this.untilDate)
      return query
    },
    search () {
      if (this.loadingList || this.formIsInvalid) return
      this.selectOpenned = false
      this.$emit('dateFiltersChanged')
    }
  }
}
</script>
<i18n>
{
  "pt-BR": {
    "today": "Hoje",
    "yesterday": "Ontem",
    "last-7-days": "Últimos 7 dias",
    "last-15-days": "Últimos 15 dias",
    "last-30-days": "Último mês",
    "all-time": "Tudo",
    "specific": "Específico",
    "interval": "Intervalo",
    "since": "De:",
    "until": "Até:",
    "date": "Data:",
    "created-at": "Data de criação"
  },
  "en-US": {
    "today": "Today",
    "yesterday": "Yesterday",
    "last-7-days": "Last 7 days",
    "last-15-days": "Last 15 days",
    "last-30-days": "Last month",
    "all-time": "All",
    "specific": "Specific",
    "interval": "Interval",
    "since": "Since:",
    "until": "Until:",
    "date": "Date:",
    "created-at": "Created at"
  }
}
</i18n>
