import store from '@/store'
import showMfes from '../utils/showMfes'

const commonTransfersBeforeEnter = next => {
  if (showMfes(store)) {
    next()
  } else {
    store.commit('toggleGlobalError', { status: true, code: 404 })
  }
}

const transfersRouters = [
  {
    path: 'transfers/:pg_recipient_id?',
    name: 'transfers',
    component: () =>
      import(/* webpackChunkName: "transfers" */ '@/views/Transfers/index.vue'),
    beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next)
  },
  {
    path: 'transfers/:pg_recipient_id/request',
    name: 'transfersRequest',
    component: () =>
      import(/* webpackChunkName: "transfers" */ '@/views/Transfers/index.vue'),
    beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next)
  },
  {
    path: 'transfers/:pg_recipient_id/:transferID',
    name: 'transfersDetail',
    component: () =>
      import(/* webpackChunkName: "transfers" */ '@/views/Transfers/index.vue'),
    beforeEnter: (to, from, next) => commonTransfersBeforeEnter(next)
  }
]

export { transfersRouters }
