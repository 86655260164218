<template>
  <div>
    <div class="eu-display_flex footer-container eu-align-items_center">
      <div class="eu-justify_center footer-information">
        <span
          class="account-name"
          :title="profile.user.first_name"
        >
          {{ profile.user.first_name }} {{ profile.user.last_name }}</span
        >
        <span
          class="account-email"
          :title="profile.user.email"
          >{{ profile.user.email }}</span
        >
      </div>
      <div
        class="emd-dropdown _position_left jsDropdown"
        data-onboarding="profile"
      >
        <emd-dropdown
          class="popover"
          :isOpen="selectOpenned"
          :fixed="false"
          @toggle="selectOpenned = $event"
          @close="selectOpenned = false"
        >
          <template #trigger>
            <span>
              <emd-tippy
                class="emd-action-button _has-tooltip popover-trigger"
                id="settings"
                :type="'ghost'"
                :position="'left'"
                :content="$t('sidemenu.tooltip.settings')"
              >
                <emerald-icon icon="ellipsis" />
              </emd-tippy>
            </span>
          </template>
          <template>
            <div class="emd-dropdown__content__item popover-items">
              <ul>
                <li
                  v-for="item in getPopoverItems"
                  :key="item.title"
                >
                  <button
                    class="popover-button"
                    @click="item.action"
                  >
                    {{ item.title }}
                  </button>
                </li>
              </ul>
            </div>
          </template>
        </emd-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import EmeraldIcon from '@/components/emerald/atoms/EmeraldIcon'
import { EmdTippy, EmdDropdown } from 'emerald-vue/src/components'
import { cannyUrl, cannyInit } from '@/utils'

export default {
  name: 'AppCoreFooter',
  components: {
    EmdDropdown,
    EmeraldIcon,
    EmdTippy
  },
  props: {},
  created () {
    const Canny = window?.Canny

    if (Canny && this.isEmployee) {
      cannyInit(
        Canny,
        this.profile.user.email,
        this.merchant.id,
        this.profile.merchant.name
      )
    }
  },
  data () {
    return {
      selectOpenned: false,
      popoverItems: [
        {
          title: this.$i18n.t('sidemenu.popover_items.myBusiness'),
          action: () => {
            window.open(
              `${process.env.VUE_APP_COMPANY_URL}/${this.profile.merchant.id}`,
              '_blank',
              'noopener'
            )
          }
        },
        {
          title: this.$i18n.t('sidemenu.popover_items.myProfile'),
          action: () => {
            window.open(
              `${process.env.VUE_APP_PROFILE_URL}`,
              '_blank',
              'noopener'
            )
          }
        },
        {
          title: this.$i18n.t('sidemenu.popover_items.exit'),
          action: () => {
            this.$store.dispatch('toggleGlobalLoading', true)
            this.$store.dispatch('DELETE_AUTHORIZATION', true)
            window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
          }
        }
      ]
    }
  },
  methods: {
    async getCannyUrl () {
      await this.$store.dispatch('GET_CANNY_TOKEN')
      return cannyUrl(this.merchant.id, this.$store.getters.cannyToken.token)
    }
  },
  computed: {
    isEmployee () {
      return this.$store.getters.isEmployee
    },
    profile () {
      return this.$store.getters.profile
    },
    merchant () {
      return this.$store.getters.merchant
    },
    getPopoverItems () {
      const items = [...this.popoverItems]
      if (this.isEmployee) {
        items.unshift(
          ...[
            {
              title: this.$i18n.t('sidemenu.popover_items.accesBackoffice'),
              action: () => {
                window.open(`${process.env.VUE_APP_BACKOFFICE_URL}`, '_blank')
              }
            },
            {
              title: this.$i18n.t('sidemenu.popover_items.updateNotes'),
              action: () => {
                window.open(
                  'https://dashspagarme.canny.io/changelog?labels=dash-pagarme-20',
                  '_blank',
                  'noopener'
                )
              }
            },
            {
              title: this.$i18n.t('sidemenu.popover_items.feedback'),
              action: () => {
                this.getCannyUrl().then(data => window.open(data, '_blank'))
              }
            }
          ]
        )
      }

      return items
    }
  }
}
</script>
<style>
.footer-container {
  height: 64px;
  padding: 0 16px;
  border-top: 1px solid var(--gray-50-squanchy);
}
.account-name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.account-email {
  font-size: 12px;
  color: #8b8b92;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.footer-information {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  width: calc(100% - 32px);
}
.footer-button {
  padding: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.popover .emd-dropdown__content {
  bottom: 0;
  left: 56px;
}

.popover-button {
  height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
  color: var(--gray-600-birdperson);
}

.popover-button:hover {
  background-color: var(--gray-50-squanchy);
  border-radius: 8px;
}

.popover-items {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.popover .emd-dropdown__content:after {
  content: ' ';
  position: absolute;
  bottom: 14px;
  left: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
}
</style>
