<template>
  <nav class="emd-menu menu">
    <template v-for="(section, key) in menu">
      <section
        v-if="section.showSection"
        :key="key"
        class="emd-menu__section menu-section"
        :class="{ _active: activeSection === key, _open: openSection === key }"
        :data-onboarding="key === 'settings' ? key : null"
      >
        <a
          @click="setOpenSection(key)"
          class="menu-link"
        >
          <span
            data-testid="dropdown-menu"
            class="emd-menu__label menu-item"
            >{{ $t(`sidemenu.${key}`) }}</span
          >
          <span
            v-if="section.new"
            class="emd-tag _color_info eu-ml_2 eu-display_none eu-display_inline_md"
            >{{ $t('sidemenu.new') }}</span
          >
          <div
            v-if="section.items"
            class="emd-menu__arrow menu-icon"
          >
            <i class="uil uil-angle-right"></i>
          </div>
          <span
            v-if="section.beta"
            class="emd-tag _color_info eu-ml_2 eu-display_none eu-display_inline_md"
            >{{ $t('sidemenu.beta') }}</span
          >
        </a>
        <div
          v-if="section.items"
          class="emd-menu__subsection menu-subitems"
        >
          <ul class="emd-menu-list">
            <template v-for="(item, id) in section.items">
              <router-link
                :key="id"
                exact
                :to="{ name: id }"
                tag="li"
                data-testid="dropdown-sub-menu"
                class="emd-menu-list__item"
                v-if="item.showItem"
                v-bind:class="{ _active: item.active }"
              >
                <a class="menu-subitem">{{ $t(`sidemenu.${item.label}`) }}</a>
              </router-link>
            </template>
          </ul>
        </div>
      </section>
    </template>

    <AppMenuExternals
      :accountId="accountId"
      :merchantId="merchantId"
    />

  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { paymentsLinksViewEnabled } from '@/actions/payment-links.actions.js'

export default {
  name: 'AppMenu',
  components: {
    AppMenuExternals: () => import(/* webpackPrefetch: true */ './AppMenuExternals.vue')
  },
  data () {
    return {
      openSection: 'payments',
      isOpenned: false
    }
  },
  computed: {
    ...mapGetters([
      'isCheckoutV2Enabled',
      'permissionIsEnabled',
      'shopIsEnabled',
      'merchantId',
      'accountId'
    ]),
    routeName () {
      return this.$route?.name
    },
    menu () {
      /*
        [KEY]: {
          icon: String, // nome ícone Uni icons
          showSection: Boolean, // condicional de permissão para exibir item
          beta: Boolean, // se item leva badge Beta
          items: {
            [KEY]: {
              label: String, // label para tradução
              showItem: Boolean, // condicional de permissão para exibir item
              active: Boolean // se item está selecionado
            }
          }
        }
      */
      return {
        home: {
          showSection:
            this.permissionIsEnabledFn('insights:view') &&
            this.componentIsEnabled('sidemenu', 'home'),
          beta: false
        },
        sales: {
          showSection:
            this.permissionIsEnabledFn('order:search') ||
            this.permissionIsEnabledFn('charge:search') ||
            (this.permissionIsEnabledFn('trf:search') &&
              this.componentIsEnabled('sidemenu', 'trf')),
          items: {
            orders: {
              label: 'orders',
              showItem: this.permissionIsEnabledFn('order:search'),
              active:
                this.routeName === 'orders' || this.routeName === 'ordersDetail'
            },
            charges: {
              label: 'charges',
              showItem: this.permissionIsEnabledFn('charge:search'),
              active:
                this.routeName === 'charges' ||
                this.routeName === 'chargesDetail'
            },
            trf: {
              label: 'trf',
              showItem:
                this.permissionIsEnabledFn('trf:search') &&
                this.componentIsEnabled('sidemenu', 'trf'),
              active: this.routeName === 'trf'
            },
            paymentLinks: {
              label: 'paymentLinks',
              showItem: paymentsLinksViewEnabled(
                this.permissionIsEnabled,
                this.isCheckoutV2Enabled
              ),
              active:
                this.routeName === 'paymentLinks' ||
                this.routeName === 'paymentLinksDetail'
            }
          }
        },
        financial: {
          items: {
            balance: {
              label: 'balance',
              showItem: this.showBalanceSection,
              beta: false,
              active: this.routeName === 'balance'
            },
            transfers: {
              label: 'transfers',
              showItem: this.showBalanceSection,
              beta: false,
              active: [
                'transfers',
                'transfersDetail',
                'transfersRequest'
              ].includes(this.routeName)
            }
          },
          showSection: this.showBalanceSection
        },
        recurrence: {
          showSection:
            (this.permissionIsEnabledFn('plan:search') &&
              this.componentIsEnabled('sidemenu', 'plans')) ||
            (this.permissionIsEnabledFn('subscription:search') &&
              this.componentIsEnabled('sidemenu', 'subscriptions')) ||
            (this.permissionIsEnabledFn('invoice:search') &&
              this.componentIsEnabled('sidemenu', 'invoices')),
          items: {
            plans: {
              label: 'plans',
              showItem:
                this.permissionIsEnabledFn('plan:search') &&
                this.componentIsEnabled('sidemenu', 'plans'),
              active:
                this.routeName === 'plans' || this.routeName === 'plansDetail'
            },
            subscriptions: {
              label: 'subscriptions',
              showItem:
                this.permissionIsEnabledFn('subscription:search') &&
                this.componentIsEnabled('sidemenu', 'subscriptions'),
              active:
                this.routeName === 'subscriptions' ||
                this.routeName === 'subscriptionsDetail'
            },
            invoices: {
              label: 'invoices',
              showItem:
                this.permissionIsEnabledFn('invoice:search') &&
                this.componentIsEnabled('sidemenu', 'invoices'),
              active:
                this.routeName === 'invoices' ||
                this.routeName === 'invoicesDetail'
            }
          }
        },
        recipients: {
          new: false,
          showSection:
            this.permissionIsEnabledFn('recipients:search') &&
            this.componentIsEnabled('sidemenu', 'recipients'),
          active:
            this.routeName === 'recipients' ||
            this.routeName === 'recipientsCreate' ||
            this.route === 'recipientsDetail'
        },
        customers: {
          showSection: this.permissionIsEnabledFn('customer:search')
        },
        webhooks: {
          showSection: this.permissionIsEnabledFn('webhook:search'),
          active:
            this.routeName === 'webhooks' || this.routeName === 'webhooksDetail'
        },
        team: {
          showSection:
            this.permissionIsEnabledFn('account_staff:search') ||
            this.permissionIsEnabledFn('account_staff.invite:search') ||
            this.permissionIsEnabledFn('action_log:search'),
          items: {
            users: {
              label: 'users',
              showItem: this.permissionIsEnabledFn('account_staff:search'),
              active:
                this.routeName === 'users' || this.routeName === 'usersDetail'
            },
            invites: {
              label: 'invites',
              showItem: this.permissionIsEnabledFn(
                'account_staff.invite:search'
              ),
              active:
                this.routeName === 'invites' ||
                this.routeName === 'invitesDetail' ||
                this.routeName === 'invites-create'
            },
            logs: {
              label: 'logs',
              showItem: this.permissionIsEnabledFn('action_log:search'),
              active: this.routeName === 'logs'
            }
          }
        },
        settings: {
          showSection: this.$store.getters.showSettingsSection,
          items: {
            'account-config': {
              label: 'account-config',
              showItem: this.$store.getters.showAccountConfig,
              active: this.routeName === 'account-config'
            },
            keys: {
              label: 'keys',
              showItem:
                this.permissionIsEnabledFn('developer.key.public:get') ||
                this.permissionIsEnabledFn('developer.key.secret:get'),
              active: this.routeName === 'keys'
            },
            gateways: {
              label: 'gateways',
              showItem: this.$store.getters.showGatewaysConfig,
              active: this.routeName === 'gateways'
            },
            fees: {
              label: 'fees',
              showItem: this.showFeesSection,
              active: this.routeName === 'fees'
            },
            'payment-methods': {
              label: 'payment-methods',
              showItem: this.$store.getters.showPaymentsConfig,
              active: this.routeName === 'payment-methods'
            },
            functionalities: {
              label: 'functionalities',
              showItem: this.$store.getters.showFunctionalitiesConfig,
              active: this.routeName === 'functionalities'
            },
            recurrence: {
              label: 'recurrence',
              showItem: this.$store.getters.showRecurrenceConfig,
              active: this.routeName === 'recurrence'
            },
            'order-config': {
              label: 'order-config',
              showItem: this.$store.getters.showOrdersConfig,
              active: this.routeName === 'order-config'
            },
            'webhook-config': {
              label: 'webhook-config',
              showItem: this.$store.getters.showWebhooksConfig,
              active:
                this.routeName === 'webhook-config' ||
                this.routeName === 'webhook-config-form'
            }
          }
        }
      }
    },
    categoryRoutes () {
      return this.$store.getters.categoryRoutes
    },
    activeSection () {
      let actualRoute =
        this.categoryRoutes.find(route =>
          route.children.includes(this.routeName.replace('Detail', ''))
        ) || {}
      if (this.routeName === 'usersDetail') actualRoute = this.categoryRoutes[3]
      if (this.routeName === 'home') return 'home'
      if (this.routeName === 'recipientsCreate') return 'recipients'
      if (this.routeName === 'recipientsDetail') return 'recipients'
      if (this.routeName === 'recipientsBalance') return 'recipients'
      if (this.routeName === 'recipientsSettings') return 'recipients'
      if (this.routeName === 'recipientsData') return 'recipients'
      if (this.routeName === 'recipientsSettlements') return 'recipients'
      if (this.routeName === 'customersDetail') return 'customers'

      return actualRoute.category ?? this.routeName
    },
    isWhiteLabel () {
      return this.$store.getters.whitelabel.enabled
    },
    showBalanceSection () {
      return (
        this.componentIsEnabled('sidemenu', 'balance') &&
        this.permissionIsEnabledFn('balance:view') &&
        ['p_s_p', 'gateway_and_psp'].includes(this.$store.getters.businessModel)
      )
    },
    showFeesSection () {
      return this.$store.getters.showFees
    }
  },
  methods: {
    setOpenSection (section) {
      if (this.openSection === section) {
        this.openSection = ''
      } else {
        this.openSection = section
      }

      if (section === 'home')
        this.$router.push({ name: 'home' }).catch(err => err)
      if (section === 'balance')
        this.$router.push({ name: 'balance' }).catch(err => err)
      if (section === 'recipients')
        this.$router.push({ name: 'recipients' }).catch(err => err)
      if (section === 'customers')
        this.$router.push({ name: 'customers' }).catch(err => err)
      if (section === 'webhooks')
        this.$router.push({ name: 'webhooks' }).catch(err => err)
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    },
    permissionIsEnabledFn (action) {
      return this.permissionIsEnabled(action)
    },
    shopTrack () {
      this.$gtag.event('click', {
        event_category: 'shopClick',
        event_label: 'Link shop menu lateral'
      })
    }
  },
  beforeMount () {
    let actualRoute =
      this.categoryRoutes.find(route =>
        route.children.includes(this.routeName.replace('Detail', ''))
      ) || {}
    if (this.routeName === 'usersDetail') actualRoute = this.categoryRoutes[3]
    if (Object.entries(actualRoute).length) {
      this.openSection = actualRoute.category
    } else {
      this.openSection = this.routeName
    }
  }
}
</script>
<style scoped>
.menu-subitems:before {
  content: none !important;
}

.menu-subitems {
  padding-left: 16px !important;
}

.menu-item {
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 0 !important;
}

.menu-link:hover {
  background-color: var(--gray-50-squanchy);
  border-radius: 8px;
}

.menu-link {
  padding-left: 8px !important;
}

.menu-link:hover .menu-item {
  color: var(--color-black-1) !important;
}

.menu-link:hover .emd-menu__arrow {
  color: var(--color-black-1);
}

._active .menu-link:hover .menu-item {
  color: var(--color-green) !important;
}

.menu {
  padding: 0 8px;
}

.menu-subitem:hover {
  background-color: var(--gray-50-squanchy);
  border-radius: 8px;
  color: var(--color-black-1);
}

.emd-menu-list__item._active > .menu-subitem:hover {
  background-color: var(--gray-50-squanchy);
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .menu-icon {
    display: initial;
  }

  .menu-section._open .menu-icon {
    transform: rotate(90deg);
  }

  .menu-link:hover {
    background-color: transparent;
  }

  .menu-item {
    position: initial;
    height: 40px !important;
    padding: 0 !important;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .menu-subitems {
    position: initial;
    background-color: var(--color-white) !important;
  }

  .menu:after {
    content: none;
  }

  .menu {
    padding: 12px 24px;
    background-color: var(--color-white);
  }

  .menu-section {
    width: 100%;
  }
  .menu-section._active .menu-item {
    color: var(--color-green);
  }

  .link-icon {
    display: initial;
    color: var(--gray-600-birdperson);
  }
}
</style>
