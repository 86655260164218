<template>
  <div>
    <emd-filter
      ref="webhookFilter"
      :options="filterOptions"
      :default="queryString"
      v-model="queryString"
      :loadingList="loadingList"
      :action="action"
      @error="handleError"
      v-if="permissionIsEnabled('webhook:search')"
    />
    <div class="el-section">
      <div
        class="emd-container _highlight_header _header_spaced _footer_spaced_sm"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">{{ $t('webhook.list.title') }}</h2>
          <button
            class="emd-btn _color_primary _size_small jsModalOpen"
            :disabled="selectedWebhooks.length === 0 || this.modalOpenned"
            v-on:click="openModal"
            v-if="permissionIsEnabled('webhook:attempt')"
          >
            <i
              class="uil uil-sync"
              transform="width-16"
            />
            {{ $t('webhook.list.resend-selecteds') }}
          </button>
        </div>
        <div
          class="emd-container__content"
          v-if="showWarning() && !closeWarning"
        >
          <emd-alert
            :variant="'warning'"
            :autoclose="false"
            @cancel="closeWarning = true"
          >
            <template slot="content">
              <p>{{ $t('webhook.list.warning') }}</p>
            </template>
          </emd-alert>
        </div>
        <div
          v-if="showAlert"
          class="emd-container__content"
        >
          <emd-alert
            :variant="formError ? 'danger' : 'success'"
            @cancel="showAlert = false"
            :title="
              formError ? $t('alert.error-title') : $t('alert.success-title')
            "
          >
            <template slot="content">
              <div v-if="formError">
                <p
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
              <p v-else>{{ $t('webhook.list.success-resend') }}</p>
            </template>
          </emd-alert>
        </div>
        <div
          v-if="loadingList"
          class="eu-pa_6 eu-align_center"
        >
          <emd-loader :type="'_invert'" />
        </div>
        <div
          v-else
          class="emd-table-responsive"
        >
          <table class="emd-table">
            <thead>
              <tr>
                <th v-if="totalItems && permissionIsEnabled('webhook:attempt')">
                  <label
                    class="emd-checkbox"
                    for="th"
                  >
                    <input
                      class="emd-checkbox__input"
                      type="checkbox"
                      id="th"
                      v-on:change="toggleAllWebhooks"
                      v-model="selectAllWebhooks"
                      :disabled="toggleAllWebhooksDisabled()"
                    />
                    <div class="emd-checkbox__area"></div>
                  </label>
                </th>
                <th>{{ $t('webhook.list.event') }}</th>
                <th>{{ $t('webhook.list.status') }}</th>
                <th>{{ $t('webhook.list.origin') }}</th>
                <th>{{ $t('webhook.list.created-at') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(webhook, index) in webhooks.items"
                :key="index"
              >
                <td
                  :data-label="$t('webhook.list.select')"
                  v-if="permissionIsEnabled('webhook:attempt')"
                >
                  <label
                    class="emd-checkbox"
                    :for="webhook.id"
                  >
                    <input
                      class="emd-checkbox__input"
                      type="checkbox"
                      :id="webhook.id"
                      :value="webhook.id"
                      v-model="selectedWebhooks"
                      v-on:click="toggleWebhook(webhook)"
                      :disabled="retryDisabled(webhook.status)"
                    />
                    <div class="emd-checkbox__area"></div>
                  </label>
                </td>
                <td :data-label="$t('webhook.list.event')">
                  <router-link
                    v-if="permissionIsEnabled('webhook:get')"
                    :to="{
                      name: 'webhooksDetail',
                      params: { id: webhook.id, tab: 'info' }
                    }"
                    class="emd-link"
                    >{{ webhook.event_name }}
                  </router-link>
                  <span>({{ webhook.event }})</span>
                </td>
                <td :data-label="$t('webhook.list.status')">
                  <span
                    class="emd-badge"
                    v-bind:class="statusClass(webhook.status)"
                    >{{ webhook.status }}</span
                  >
                </td>
                <td :data-label="$t('webhook.list.origin')">
                  <router-link
                    v-if="
                      permissionIsEnabled(`${webhook.object.parent_type}:get`)
                    "
                    :to="{
                      name: getRouteName(webhook.object),
                      params: { id: getRouteId(webhook.object), tab: 'info' }
                    }"
                    class="emd-link"
                    >{{ webhook.object.id }}</router-link
                  >
                </td>
                <td :data-label="$t('webhook.list.created-at')">
                  {{ webhook.created_at | dateFormat('dateAndHour') }}
                </td>
                <td class="eu-align_right">
                  <button
                    class="emd-btn-ghost"
                    :title="$t('webhook.list.resend')"
                    v-if="
                      !retryDisabled(webhook.status) &&
                      permissionIsEnabled('webhook:attempt')
                    "
                    v-on:click="retrySpecificWebhook(webhook)"
                  >
                    <i
                      class="uil uil-sync"
                      transform="width-16"
                    />
                  </button>
                  <router-link
                    v-if="permissionIsEnabled('webhook:get')"
                    :to="{
                      name: 'webhooksDetail',
                      params: { id: webhook.id, tab: 'info' }
                    }"
                    class="emd-btn-ghost"
                    ><i
                      class="uil uil-arrow-to-right"
                      transform="width-14"
                  /></router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <empty-list
            v-if="showEmptyList()"
            entity="webhooks"
          ></empty-list>
          <empty-list-filter
            v-if="showEmptyListFilter()"
            entity="webhooks"
          ></empty-list-filter>
        </div>
        <pagination
          v-if="!loadingList"
          :pagination="webhooks.pagination"
          :totalShowing="totalItems"
          :type="'dynamic'"
          :action="action"
        ></pagination>
      </div>
    </div>

    <modal-password
      v-if="modalOpenned"
      @cancel="closeModal"
      :callback="retryWebhooks"
    ></modal-password>
  </div>
</template>
<script>
import { statusClass } from '@/filters'
import { EmdLoader } from 'emerald-vue/src/components'
import EmdAlert from '@/components/emerald/molecules/EmdAlert.vue'
import EmdFilter from '@/components/emerald/organisms/EmdFilter.vue'
import EmptyList from '@/components/project/globals/EmptyList.vue'
import EmptyListFilter from '@/components/project/globals/EmptyListFilter.vue'
import Pagination from '@/components/project/globals/Pagination.vue'
import ModalPassword from '@/components/project/globals/ModalPassword.vue'
export default {
  components: {
    EmdLoader,
    EmdAlert,
    EmdFilter,
    EmptyList,
    EmptyListFilter,
    Pagination,
    ModalPassword
  },
  data () {
    return {
      statusClass: statusClass,
      queryString: {
        size: 30,
        period_type: 'date_filters',
        date_filters: 'SevenDays'
      },
      action: 'GET_WEBHOOKS',
      selectAllWebhooks: false,
      selectedWebhooks: [],
      modalOpenned: false,
      errors: [],
      showAlert: false,
      formError: false,
      closeWarning: false
    }
  },
  computed: {
    filterOptions () {
      return this.$store.getters.webhookFilter
    },
    webhooks () {
      return this.$store.getters.webhooks
    },
    loadingList () {
      return this.$store.getters.webhookLoading
    },
    totalItems () {
      return this.webhooks.items.length
    },
    params () {
      return {
        merchantId: this.$store.getters.merchantId,
        accountId: this.$store.getters.accountId
      }
    }
  },
  methods: {
    handleError (error) {
      if (error.body && Array.isArray(error.body.errors)) {
        error.body.errors.forEach(error => {
          if (!this.errors.includes(error.message)) {
            this.errors.push(error.message)
          }
        })
        this.showAlert = true
        this.formError = true
      }
    },
    openModal () {
      this.modalOpenned = true
    },
    closeModal () {
      this.modalOpenned = false
      this.selectedWebhooks = []
    },
    getRouteName (object) {
      return object.parent_type
        ? `${object.parent_type}sDetail`
        : `${object.type}sDetail`
    },
    getRouteId (object) {
      return object.parent_id ? object.parent_id : object.id
    },
    retryDisabled (status) {
      status = status.toLowerCase()
      return status === 'pending' || status === 'pendente'
    },
    showEmptyList () {
      return (
        !this.loadingList &&
        this.webhooks.pagination &&
        this.webhooks.pagination.total_items === 0 &&
        Object.entries(this.queryString).length <= 3
      )
    },
    showEmptyListFilter () {
      return (
        !this.loadingList &&
        this.webhooks.pagination &&
        this.webhooks.pagination.total_items === 0 &&
        Object.entries(this.queryString).length > 3
      )
    },
    verifySelectedWebhooks () {
      const enabledWebhooks = this.webhooks.items.filter(
        webhook => !this.retryDisabled(webhook.status)
      )
      this.selectAllWebhooks = false
      if (enabledWebhooks.length === this.selectedWebhooks.length) {
        this.selectAllWebhooks = true
      }
    },
    toggleAllWebhooks () {
      this.selectedWebhooks = []
      if (this.selectAllWebhooks) {
        this.webhooks.items.map(webhook => {
          if (!this.retryDisabled(webhook.status)) {
            this.selectedWebhooks.push(webhook.id)
          }
        })
      }
    },
    toggleAllWebhooksDisabled () {
      const disabledWebhooks = this.webhooks.items.filter(webhook =>
        this.retryDisabled(webhook.status)
      )
      if (disabledWebhooks.length === this.webhooks.items.length) {
        return true
      }
      return false
    },
    toggleWebhook (webhook) {
      const index = this.selectedWebhooks.indexOf(webhook.id)
      if (index !== -1) {
        this.selectedWebhooks.splice(index, 1)
      } else {
        this.selectedWebhooks.push(webhook.id)
      }
      this.verifySelectedWebhooks()
    },
    retrySpecificWebhook (webhook) {
      this.selectedWebhooks = [webhook.id]
      this.openModal()
    },
    retryWebhooks (password) {
      const body = {
        webhook_ids: this.selectedWebhooks,
        password: password
      }
      const msgErrorInternal = this.$t('webhook.list.error-internal')
      return this.$store
        .dispatch('RETRY_WEBHOOK', { params: this.params, body: body })
        .then(res => {
          this.selectAllWebhooks = false
          this.selectedWebhooks = []
          this.showAlert = true
          this.formError = false
          this.$refs.webhookFilter.onFilter()
        })
        .catch(err => {
          this.showAlert = true
          this.formError = true
          if (err.body && Array.isArray(err.body.errors)) {
            err.body.errors.forEach(error => {
              if (!this.errors.includes(error.message)) {
                this.errors.push(error.message)
              }
            })
          } else {
            this.errors.push(msgErrorInternal)
          }
          window.scrollTo(0, 0)
        })
    },
    showWarning () {
      return (
        Object.entries(this.queryString).length <= 3 &&
        !this.loadingList &&
        this.totalItems
      )
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  },
  beforeCreate () {
    this.$store.dispatch('GET_EVENTS')
  }
}
</script>
