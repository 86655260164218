<template>
  <div>
    <modal-header v-if="showModal" />
    <onboarding
      v-if="showOnboarding"
      :account="account.name"
      :currentStep="onboardingStep"
      @close="closeOnboarding"
    />
    <main class="el-core _fixed">
      <app-core-header-communication
        fixed
        v-if="showCommunication"
      />
      <div
        class="el-core__wrapper wrapper"
        :class="{
          _has_communication: showCommunication,
          'wrapper-comunication': showCommunication
        }"
      >
        <div class="el-main">
          <div
            class="el-main__navigation sidemenu-container"
            :class="{ _open: appMenuOpen }"
            data-onboarding="realTime"
          >
            <app-core-header
              :class="{ _has_communication: showCommunication }"
              class="menu-wrapper"
              @appMenuClicked="toggleAppMenu"
              @drawerMenuClicked="toggleDrawerMenu"
              @openOnboarding="openOnboarding"
              :drawerOpen="drawerMenuOpen"
              :isOpen="appMenuOpen"
            ></app-core-header>
            <app-sidemenu
              @appMenuClicked="toggleAppMenu"
              :isOpen="appMenuOpen"
            />
            <div class="sidemenu-footer">
              <app-core-footer />
            </div>
          </div>
          <div class="el-main__content content-wrapper">
            <div
              class="el-section"
              v-if="showGreeting"
            >
              <greeting
                @close="closeGreeting"
                @explore="openOnboarding"
              />
            </div>
            <emd-breadcrumb></emd-breadcrumb>
            <section
              v-if="showNps"
              class="el-section"
            >
              <nps @close="closeNps" />
            </section>
            <transition
              :name="transitionName"
              mode="out-in"
            >
              <router-view />
            </transition>
            <transition
              name="fade"
              mode="out-in"
            >
              <emd-footer />
            </transition>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import EmdBreadcrumb from '@/components/emerald/molecules/EmdBreadcrumb.vue'
import Greeting from '@/components/project/globals/Greeting.vue'
import Onboarding from '@/components/project/globals/Onboarding.vue'
import Nps from '@/components/project/globals/Nps.vue'
import EmdFooter from '@/components/emerald/organisms/EmdFooter.vue'
import AppSidemenu from '@/components/project/ui/menu/AppSidemenu.vue'
import AppCoreHeader from '@/components/project/ui/menu/AppCoreHeader.vue'
import AppCoreHeaderCommunication from '@/components/project/ui/menu/AppCoreHeaderCommunication.vue'
import ModalHeader from '@/components/project/globals/ModalHeader.vue'
import AppCoreFooter from '@/components/project/ui/menu/AppCoreFooter.vue'

export default {
  name: 'Motherboard',
  components: {
    AppCoreHeader,
    EmdBreadcrumb,
    Greeting,
    Nps,
    Onboarding,
    AppSidemenu,
    EmdFooter,
    ModalHeader,
    AppCoreHeaderCommunication,
    AppCoreFooter
  },
  data () {
    return {
      appMenuOpen: false,
      drawerMenuOpen: false,
      transitionName: 'slide-right'
    }
  },
  computed: {
    isEmployee () {
      return this.$store.getters.isEmployee
    },
    account () {
      return this.$store.getters.account
    },
    userType () {
      return this.$store.getters.userType.key
    },
    onboarding () {
      return this.$store.getters.onboarding
    },
    showOnboarding () {
      return this.$store.getters.showOnboarding && !this.isEmployee
    },
    showGreeting () {
      return this.onboarding.show_greeting && !this.isEmployee
    },
    showModal () {
      return (
        (this.onboarding.show_modal_header ||
          !this.onboarding.summary.closed_modal) &&
        this.$store.getters.componentIsEnabled('global', 'modalHeader')
      )
    },
    showPixOnboarding () {
      return (
        this.onboarding.show_pix &&
        this.componentIsEnabled('global', 'onboarding') &&
        !this.isEmployee
      )
    },
    onboardingStep () {
      return this.onboarding.step
    },
    showNps () {
      return this.$store.getters.showNps
    },
    isMundiTheme () {
      return ['mundipagg'].includes(process.env.VUE_APP_THEME)
    },
    showCommunication () {
      return this.isMundiTheme
    }
  },
  methods: {
    toggleAppMenu () {
      this.appMenuOpen = !this.appMenuOpen
      if (this.drawerMenuOpen) this.drawerMenuOpen = false
    },
    toggleDrawerMenu () {
      this.drawerMenuOpen = !this.drawerMenuOpen
      if (this.appMenuOpen) this.appMenuOpen = false
    },
    closeNps () {
      this.$store.dispatch('TOGGLE_NPS')
    },
    closeGreeting () {
      this.$store.dispatch('CLOSE_GREETING')
      this.openOnboardingIndication()
    },
    openOnboardingIndication () {
      this.$store.dispatch('UPDATE_ONBOARDING_STEP', 6)
      this.$store.dispatch('TOGGLE_ONBOARDING', true)
    },
    openOnboarding () {
      if (this.showGreeting) {
        this.$store.dispatch('CLOSE_GREETING')
      }
      this.$store.dispatch('TOGGLE_ONBOARDING', true)
    },
    closeOnboarding () {
      this.$store.dispatch('TOGGLE_ONBOARDING', false)
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  },
  watch: {
    $route (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      // closes appMenu on route change
      if (to !== from && this.appMenuOpen) this.appMenuOpen = false
    }
  }
}
</script>

<style>
#atlwdg-trigger {
  background: var(--brand--le--primary-color--base);
  color: #fff !important;
  border: none;
  box-shadow: none;
  border-radius: 4px 0 0 0;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 11px;
  padding: 6px 12px;
  z-index: 0;
}
._mode_dark #atlwdg-trigger {
  background: var(--brand--de--primary-color--base);
  color: #272829 !important;
}
._mode_light #atlwdg-trigger {
  background: var(--brand--le--primary-color--base);
  color: #fff !important;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 500ms;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translateX(calc(2em - 6px));
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translateX(calc(-2em - 6px));
}

.sidemenu-container {
  z-index: 1000;
  margin-top: 0 !important;
  height: 100vh !important;
  min-width: 248px !important;
  width: 248px !important;
}

.wrapper {
  margin-top: 0 !important;
}

.wrapper.wrapper-comunication {
  margin-top: 39px !important;
}

.content-wrapper {
  padding-left: 272px !important;
}

@media only screen and (min-width: 543px) {
  .menu-wrapper._has_communication {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 543px) {
  .menu-wrapper._has_communication {
    margin-top: 11px !important;
  }
}

@media only screen and (max-width: 768px) {
  .sidemenu-footer {
    display: none;
  }
  .sidemenu-container {
    height: 0 !important;
    width: 100% !important;
  }
  .sidemenu-container._open {
    height: 100vh !important;
  }
  .content-wrapper {
    padding: 0 8px !important;
  }
}
</style>
