<template>
  <div class="eu-py_3">
    <div
      class="emd-filter-dash"
      :class="{ _advanced: advancedOpened }"
    >
      <div class="emd-filter-dash__basic">
        <emd-basic-filter
          ref="basicFilter"
          :advancedIsOpenned="advancedOpened"
          :loadingList="loadingList"
          :origin="origin"
          :sendFilterEvents="sendFilterEvents"
          :placeholder="placeholder"
          :shortcutProp="shortcutProp"
          :showShortcutCustomer="showShortcutCustomer"
          @basicSearch="search"
          @click.native="interactedFilter('filter')"
        />
        <div class="emd-filter-dash__buttons">
          <div>
            <emd-tippy
              id="new-filter"
              :isHidden="!showTooltip.filter || advancedOpened"
              :destroy="!showTooltip.filter"
              :title="$t('tooltip.new-filter.title')"
              :description="$t('tooltip.new-filter.text')"
              :type="'template'"
              position="left"
              @destroy="closeTooltip('filter')"
            ></emd-tippy>
            <span
              class="eu-position_relative emd-tippy-template"
              data-template="tooltip-new-filter"
            >
              <button
                data-testid="dropdown-advanced-filter"
                class="emd-filter-dash__trigger"
                :class="{ _active: advancedOpened }"
                @click="toggleAdvanced"
                :alt="$t('alt-filters')"
              >
                <i class="uil uil-filter" />
                {{ $t('filters') }}
              </button>
              <span
                class="emd-filter-dash__counter"
                v-if="totalQueries > 0"
                >{{ totalQueries }}</span
              >
            </span>
          </div>
          <div class="eu-display_flex eu-align-items_center">
            <emd-period-filter
              ref="periodFilter"
              @dateFiltersChanged="search"
              :loadingList="loadingList"
            />

            <div v-if="report && report.enabled">
              <emd-tippy
                :isHidden="
                  showTooltip.filter || advancedOpened || !showTooltip.report
                "
                :destroy="!showTooltip.report"
                id="filter-report"
                :title="$t('tooltip.filter-report.title')"
                :type="'template'"
                position="left"
                @destroy="closeTooltip('report')"
              ></emd-tippy>
              <emd-report-button
                class="emd-tippy-template"
                :data-template="'tooltip-filter-report'"
                :loadingList="loadingList"
                :report="report"
                :totalListItems="totalListItems"
                @click.native.passive="interactedFilter('report')"
              />
            </div>

            <div class="eu-ml_3">
              <emd-tippy
                class="emd-tippy-action-button _update-list"
                id="update-list"
                :type="'action-button'"
                :content="$t('update-list')"
              >
                <button
                  class="emd-action-button _lvl_2 _square _size_small _has-tooltip"
                  :class="{ _disabled: loadingList }"
                  @click="update"
                >
                  <span
                    data-testid="btn_update_orders"
                    class="emd-text _color_default emd-action-button__icon"
                  >
                    <i class="uil uil-sync" />
                  </span>
                </button>
              </emd-tippy>
            </div>
            <div class="eu-mr_3">
              <slot name="extraActions"></slot>
            </div>
          </div>
        </div>
      </div>
      <hr class="emd-divider" />
      <emd-advanced-filter
        ref="advancedFilter"
        :options="options"
        :loadingList="loadingList"
        :origin="origin"
        :sendFilterEvents="sendFilterEvents"
        @advancedSearch="search"
        @setTabbar="setTabbar"
      />
    </div>
  </div>
</template>
<script>
import EmdReportButton from '@/components/emerald/molecules/EmdReportButton.vue'
import EmdPeriodFilter from '@/components/emerald/organisms/EmdPeriodFilter.vue'
import EmdBasicFilter from '@/components/emerald/organisms/EmdFilter/EmdBasicFilter'
import EmdAdvancedFilter from '@/components/emerald/organisms/EmdFilter/EmdAdvancedFilter'
import { EmdTippy } from 'emerald-vue/src/components'

export default {
  name: 'EmdNewFilter',
  components: {
    EmdReportButton,
    EmdPeriodFilter,
    EmdBasicFilter,
    EmdAdvancedFilter,
    EmdTippy
  },

  props: {
    placeholder: String,
    shortcutProp: Object,
    additionalOptions: {
      type: Object,
      required: false,
      default: null
    },
    action: {
      type: String,
      required: true
    },
    loadingList: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    report: {
      type: Object,
      required: false
    },
    totalListItems: {
      type: Number,
      required: true,
      default: 0
    },
    origin: {
      type: String,
      required: false,
      default: ''
    },
    sendFilterEvents: {
      type: Boolean,
      default: false
    },
    showShortcutCustomer: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      advancedOpened: false,
      query: {},
      totalQueries: 0
    }
  },
  computed: {
    merchant () {
      return this.$store.getters.merchant
    },
    account () {
      return this.$store.getters.account
    },
    showTooltip () {
      const obj = {
        filter: !this.$store.getters.onboarding.closed_tooltip_filter,
        report: !this.$store.getters.onboarding.closed_tooltip_report
      }
      return obj
    }
  },
  methods: {
    toggleAdvanced () {
      this.interactedFilter('filter')
      this.advancedOpened = !this.advancedOpened
    },
    prepareQuery () {
      const periodFilter = this.$refs.periodFilter
      const basicFilter = this.$refs.basicFilter
      const advancedFilter = this.$refs.advancedFilter

      this.query = Object.assign(
        {},
        periodFilter.prepareQuery(),
        basicFilter.prepareQuery(),
        advancedFilter.prepareQuery()
      )
      this.totalQueries = advancedFilter.totalQueries

      if (this.additionalOptions) {
        Object.keys(this.additionalOptions).forEach(item => {
          if (this.additionalOptions[item] !== null) {
            this.query[item] = this.additionalOptions[item]
          }
        })
      }
      if (this.query.start_paid_at) {
        this.query.period_type = 'paid_date_filters'
        delete this.query.date_filters
        delete this.query.created_since
        delete this.query.created_until
      }
    },
    refreshQueryUrl (query) {
      this.$router.replace({ query: query }).catch(err => err)
    },
    closeTooltip (key) {
      this.$store.dispatch('CLOSE_TOOLTIP_FILTER_ONBOARDING', key)
    },
    interactedFilter (key) {
      if (this.showTooltip[key]) this.closeTooltip(key)
    },
    update () {
      if (this.loadingList) return
      this.search()
    },
    sendEvents (params) {
      if (params.query.date_filters) {
        this.$gtag.event('add-filter-simple', {
          event_category: `${this.origin}-list-filter`,
          event_label: `date_filters-${params.query.date_filters}`
        })
      }

      if (params.query.any_ids) {
        this.$gtag.event('add-filter-simple', {
          event_category: `${this.origin}-list-filter`,
          event_label: `any_ids-${params.query.any_ids}`
        })
      }

      if (params.query.customers) {
        this.$gtag.event('add-filter-simple', {
          event_category: `${this.origin}-list-filter`,
          event_label: `customers-${params.query.customers}`
        })
      }

      this.options.forEach(option => {
        if (params.query[option.name_query]) {
          this.$gtag.event('add-filter-advanced', {
            event_category: `${this.origin}-list-filter`,
            event_label: `${option.name_query}-${
              params.query[option.name_query]
            }`
          })
        }
      })
    },
    search () {
      this.prepareQuery()
      const params = {
        merchantId: this.merchant.id,
        accountId: this.account.id,
        query: this.query
      }
      const shouldSendEvents =
        this.origin &&
        this.sendFilterEvents &&
        this.$gtag &&
        !this.$store.getters.isEmployee

      shouldSendEvents && this.sendEvents(params)
      this.$store.dispatch(this.action, params)
      this.refreshQueryUrl(this.query)
    },
    setTabbar (tab) {
      this.$emit('setTabbar', tab)
    }
  },
  mounted () {
    setTimeout(() => {
      if (this.totalQueries > 0) this.advancedOpened = true
    })
  }
}
</script>
<i18n>
{
  "pt-BR": {
    "filters": "Filtros",
    "alt-filters": "Abrir filtro avançado",
    "export-report": "Exportar relatório",
    "update-list": "Atualizar lista",
    "tooltip": {
      "new-filter": {
        "title": "Conheça a nova busca",
        "text": "Realize buscas mais rápidas com identificadores ou dados do cliente, faça buscas avançadas utilizando os filtros e explore as novas opções e funcionalidades."
      },
      "filter-report": {
        "title": "Você pode fazer o download dos seus relatórios clicando neste botão. Experimente!"
      }
    }
  },
  "en-US": {
    "filters": "Filters",
    "alt-filters": "Open advanced filter",
    "export-report": "Export report",
    "update-list": "Update list",
    "tooltip": {
      "new-filter": {
        "title": "Try on the new search",
        "text": "Quickly search for any charge, order or customer identifier, do advanced researches using our filters and explore the new options and functionalities."
      },
      "filter-report": {
        "title": "Here you can download your report. Try it out!"
      }
    }
  }
}
</i18n>
