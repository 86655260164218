import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'
import VueTheMask from 'vue-the-mask'
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'
import money from 'v-money'
import { dateFormat } from './filters'
import VueClipboard from 'vue-clipboard2'
import getGoogleAnalyticsSettings from './utils/googleAnalytics'
import * as FullStory from '@fullstory/browser'
import './styles.global.css'
import { sanitizeUrl } from '@braintree/sanitize-url'
import '@stone-payments/jade-design-tokens/pagarme.css'
import '@stone-payments/jade-design-tokens/fonts/inter.css'

// CONFIG & IMPORTS
// import './plugins/unicon'

// PLUGINS IMPORTS
import './plugins/click-outside'
import 'vanillajs-datepicker/css/datepicker.css'

import 'emerald-workbench/dist/emerald.pagarme.min.css'

if (['pagarme'].includes(process.env.VUE_APP_THEME)) {
  // Vue requires the use of a polyfill to correctly handle the shadow dow,
  // which is required to encapsulate styles in the micro frontend architecture
  const shadow = require('vue-shadow-dom')
  Vue.use(shadow)

  // start listening for route changes in order to render micro-frontend bundles
  const { start } = require('single-spa')
  start()
}

if (['jpmorgan', 'pagarme'].includes(process.env.VUE_APP_THEME)) {
  store.dispatch('loadEmeraldByTheme', process.env.VUE_APP_THEME)
}

Vue.config.productionTip = false

if (['production'].includes(process.env.VUE_APP_CORE_ENV)) {
  FullStory.init({ orgId: 'BKP05' })
  Vue.prototype.$FullStory = FullStory
  const googleAnalyticsSettings = getGoogleAnalyticsSettings()
  Vue.use(VueGtag, googleAnalyticsSettings, router)
}

Vue.use(VueTheMask)
Vue.use(Vuelidate)
Vue.use(VueCookies)
Vue.use(money, { precision: 4 })
Vue.filter('dateFormat', dateFormat)
Vue.use(VueClipboard)
Vue.prototype.$sanitize = sanitizeUrl

var signalR = require('@aspnet/signalr/dist/browser/signalr.min.js')
Vue.prototype.$signalR = signalR

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
