<template>
  <div
    class="emd-filter-dash__input"
    ref="emdBasicFilter"
    :class="{ _focus: shortcutOpenned }"
    v-click-outside="clickedOutside"
  >
    <emd-input-tag
      data-testid="search-order"
      ref="inputTag"
      listType="complex"
      :list="shortcuts"
      :validKeys="validKeys"
      :focus="inputTagFocused"
      :disabled="loadingList"
      :input="input"
      @onClick="openShortcut"
      @inputChanged="handleInputChange"
      @onSubmit="handleSubmit"
      @listUpdated="updateShortcuts"
      @onBlur="handleBlur"
    />
    <div class="emd-filter-dash__options">
      <table>
        <tbody>
          <tr
            @click="selectShortcut('any_ids', 'i')"
            :class="{ _disabled: shortcutAlreadySelected('any_ids') }"
          >
            <td
              data-testid="search-order-by-id"
              class="emd-text _color_default _type_caption"
            >
              <span>{{ computedShortcut.identifier }}</span>
            </td>
            <td class="emd-text _color_default">
              {{ computedShortcut.idText }}<br /><code>{{
                computedShortcut.idCode
              }}</code>
            </td>
          </tr>
          <tr
            v-if="showShortcutCustomer"
            :class="{ _disabled: shortcutAlreadySelected('customers') }"
            @click="selectShortcut('customers', 'c')"
          >
            <td
              data-testid="search-order-by-customer"
              class="emd-text _color_default _type_caption"
            >
              <span>{{ computedShortcut.customer }}</span>
            </td>
            <td class="emd-text _color_default">
              {{ computedShortcut.customerText }}<br /><code>{{
                computedShortcut.customerCode
              }}</code>
            </td>
          </tr>
        </tbody>
      </table>
      <hr class="emd-divider" />
      <div class="emd-filter-dash__options__footer">
        <p class="emd-text _color_subtle">
          {{ computedShortcut.text }}
        </p>
        <p class="emd-text _color_subtle">
          {{ computedShortcut.example }}
        </p>
      </div>
    </div>
    <a
      href="javascript:void(0)"
      class="emd-action-button _square _size_small _lvl_2 eu-ml_1 _color_danger"
      :alt="$t('clear')"
      @click="clearBasicFilter"
      v-if="shortcuts.length"
      :class="{ _disabled: loadingList }"
    >
      <i
        data-testid="orders-simple-filter-remove-btn"
        class="uil uil-times emd-action-button__icon"
      />
    </a>
    <a
      href="javascript:void(0)"
      class="emd-btn _color_primary eu-no-shadow"
      :alt="$t('search')"
      @click="search"
      v-if="!advancedIsOpenned"
      :class="{ _disabled: loadingList }"
      :disabled="loadingList"
    >
      <i
        data-testid="orders-search-simple-btn"
        class="uil uil-search"
      />
    </a>
  </div>
</template>

<script>
import { EmdInputTag } from 'emerald-vue/src/components'

export default {
  name: 'EmdBasicFilter',
  components: {
    EmdInputTag
  },

  props: {
    advancedIsOpenned: Boolean,
    loadingList: Boolean,
    sendFilterEvents: Boolean,
    placeholder: String,
    shortcutProp: Object,
    showShortcutCustomer: {
      type: Boolean,
      default: true
    },
    origin: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      shortcutOpenned: false,
      inputTagFocused: false,
      lastTypeEntered: '',
      shortcuts: [],
      validKeys: [
        {
          query: 'any_ids',
          keys: ['i', 'identificador']
        },
        {
          query: 'customers',
          keys: ['c', 'clientes']
        }
      ],
      input: {
        type: 'text',
        placeholder: this.placeholder ?? this.$t('placeholder'),
        value: ''
      }
    }
  },

  computed: {
    computedShortcut () {
      return {
        identifier: this.$t('shortcut.identifier'),
        idText: this.$t('shortcut.id-text'),
        idCode: this.$t('shortcut.id-code'),
        customer: this.$t('shortcut.customer'),
        customerText: this.$t('shortcut.customer-text'),
        customerCode: this.$t('shortcut.customer-code'),
        text: this.$t('shortcut.text'),
        example: this.$t('shortcut.example'),
        ...this.shortcutProp
      }
    }
  },

  mounted () {
    const routeQuery = this.$route.query
    const routeKeyQuery = Object.keys(this.$route.query)
    if (routeKeyQuery.length) {
      routeKeyQuery.forEach((key, index) => {
        if (key === 'any_ids' || key === 'customers') {
          this.shortcuts.push({
            type: key,
            key: key === 'any_ids' ? 'i' : 'c',
            tags: [].concat(routeQuery[key])
          })
          this.lastTypeEntered = this.shortcuts[this.shortcuts.length - 1].type
          const tagsDecoded = this.shortcuts[
            this.shortcuts.length - 1
          ].tags.map(item => decodeURIComponent(item))
          this.shortcuts[this.shortcuts.length - 1].tags = tagsDecoded
        }
      })
    }
  },

  methods: {
    openShortcut () {
      if (this.loadingList) return
      this.shortcutOpenned = true
      this.inputTagFocused = true
    },
    handleBlur (event) {
      this.inputTagFocused = false
    },
    selectShortcut (type, key) {
      if (this.shortcutAlreadySelected(type)) return
      const shortcut = this.shortcuts.find(shortcut => shortcut.type === type)
      if (!shortcut) {
        this.shortcuts.push({
          type,
          key: `${key}`,
          tags: []
        })
      }
      this.lastTypeEntered = type
      this.shortcutOpenned = false
      this.$refs.inputTag.$refs.tagInput.focus()
    },
    updateShortcuts (value) {
      this.shortcuts = [].concat(value)
    },
    handleInputChange (value) {
      this.$set(this.input, 'value', value)
    },
    handleSubmit (event) {
      if (this.loadingList) return
      this.shortcutOpenned = false
      this.updateShortcuts(event)
      this.$emit('basicSearch')
    },
    shortcutAlreadySelected (type) {
      return this.shortcuts.find(shortcut => shortcut.type === type)
    },
    clearBasicFilter () {
      if (this.loadingList) return
      this.handleSubmit([])
      this.lastTypeEntered = ''
      const shouldSendEvent =
        this.origin &&
        this.sendFilterEvents &&
        this.$gtag &&
        !this.$store.getters.isEmployee
      shouldSendEvent &&
        this.$gtag.event('remove-filter-simple', {
          event_category: `${this.origin}-list-filter`
        })
    },
    clickedOutside (e) {
      if (
        !this.$refs.emdBasicFilter.contains(e.target) &&
        this.shortcutOpenned
      ) {
        this.shortcutOpenned = false
      }
    },
    prepareQuery () {
      const query = {}
      this.shortcuts.forEach(item => {
        if (item.tags.length) {
          query[item.type] = item.tags
        }
      })
      return query
    },
    search () {
      if (this.loadingList) return
      this.$refs.inputTag.handleSubmitEvent()
    }
  }
}
</script>
<i18n>
{
  "pt-BR": {
    "placeholder": "Buscar por identificador ou cliente…",
    "shortcut": {
      "identifier": "Identificador",
      "id-text": "TID, NSU, ID do pedido, ID da cobrança, Código do Pedido, Nosso Número, etc...",
      "id-code": "atalho [ i: ] ou [ identificador: ]",
      "customer": "Clientes",
      "customer-text": "Nome, E-mail, Customer ID, CPF...",
      "customer-code": "atalho [ c: ] ou [ clientes: ]",
      "text": "Atalhos - digite o atalho do filtro (i: ou c:) e adicione os valores separados por vírgula para buscar mais de um resultado.",
      "example": "Ex.: c: Francisca Elisângela, Brunno Felipe, Doranice Nunes; i: 32u31!d"
    },
    "clear": "Limpar",
    "search": "Buscar"
  },
  "en-US": {
    "placeholder": "Search by identifier or customer…",
    "shortcut": {
      "identifier": "Identifier",
      "id-text": "TID, NSU, ID do pedido, ID da cobrança, Código do Pedido, Nosso Número, etc...",
      "id-code": "atalho [ i: ] ou [ identificador: ]",
      "customer": "Customers",
      "customer-text": "Nome, E-mail, Customer ID, CPF...",
      "customer-code": "atalho [ c: ] ou [ clientes: ]",
      "text": "Shortcuts - type the filte shortcut (I: or c:) and use a comma to separate different values and search for more than one result.",
      "example": "Ex: c: Francisca Elisângela, Brunno Felipe, Doranice Nunes; i: 32u31!d"
    },
    "clear": "Limpar",
    "search": "Buscar"
  }
}
</i18n>

<style>
.emd-filter-dash__options {
  left: 0;
}
</style>
