<template>
  <div class="emd-interactive-list eu-pb_3">
    <div
      class="emd-interactive-list__search"
      v-if="showSearch"
    >
      <div class="emd-input-group">
        <input
          class="emd-input"
          type="text"
          :placeholder="$t('account.production.search')"
          v-model="identifier"
          v-on:input="searchTestAccount(identifier)"
        />
        <i class="uil uil-search"></i>
      </div>
    </div>
    <div class="emd-interactive-list__main">
      <p
        class="emd-text eu-pa_1"
        v-if="!loadingList && !testAccounts.items.length"
      >
        {{ $t('account.test.no-results') }}
      </p>
      <div
        class="emd-loader _invert eu-align_center eu-pa_3"
        v-if="loadingList"
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul v-if="!loadingList && testAccounts.items.length">
        <li
          class="emd-select-list__item"
          v-for="(acc, index) in testAccounts.items"
          :key="index"
          v-bind:class="{ _active: acc.id === account.id }"
          v-on:click="goToAccount(acc.id)"
        >
          <span class="emd-interactive-list__label">
            <span
              class="emd-status-tag"
              :class="
                acc.status == 'inactive' ? '_color_danger' : '_color_warning'
              "
              :title="$t(`account.test.${acc.status}`)"
            >
              <emerald-icon
                icon="circle"
                size="xs"
                fixed-width
              />
            </span>
            {{ acc.name }}
          </span>
          <span
            class="emd-interactive-list__select-icon"
            v-if="acc.id === account.id"
          >
            <i
              class="uil uil-check-circle"
              transform="width-16"
            />
          </span>
        </li>
      </ul>
    </div>
    <div
      class="emd-interactive-list__pagination"
      v-if="!loadingList && testAccounts.pagination.total_page > 1"
    >
      <button
        class="emd-btn _type_text _size_small"
        v-bind:class="{ _disabled: testAccounts.pagination.current_page === 1 }"
        v-on:click.stop="goToPage(page - 1)"
        :disabled="testAccounts.pagination.current_page === 1"
      >
        {{ $t('account.test.previous') }}
      </button>
      <span class="emd-text _size_tiny _align_center">
        {{ testAccounts.pagination.current_page }} {{ $t('account.test.of') }}
        {{ testAccounts.pagination.total_page }}
      </span>
      <button
        class="emd-btn _type_text _size_small"
        v-bind:class="{
          _disabled:
            testAccounts.pagination.current_page ===
            testAccounts.pagination.total_page
        }"
        v-on:click.stop="goToPage(page + 1)"
        :disabled="
          testAccounts.pagination.current_page ===
          testAccounts.pagination.total_page
        "
      >
        {{ $t('account.test.next') }}
      </button>
    </div>
  </div>
</template>

<script>
import EmeraldIcon from '@/components/emerald/atoms/EmeraldIcon'

export default {
  name: 'InteractiveListTest',
  components: {
    EmeraldIcon
  },
  props: {
    merchant: {
      type: Object
    },
    account: {
      type: Object
    }
  },
  data () {
    return {
      loadingList: true,
      identifier: '',
      page: 1,
      debounceTimer: undefined
    }
  },
  computed: {
    testAccounts () {
      return this.$store.getters.testAccounts
    },
    showSearch () {
      if (
        !Object.entries(this.testAccounts.items).length &&
        !this.identifier &&
        this.loadingList
      ) {
        return false
      }
      return this.testAccounts.pagination.total_items > 5 || this.identifier
    }
  },
  methods: {
    getTestAccounts: function (merchantId, query = {}) {
      this.loadingList = true
      query.size = 5
      this.$store
        .dispatch('GET_TEST_ACCOUNTS', { merchantId, query })
        .then(res => {
          this.loadingList = false
        })
    },
    goToPage: function (page) {
      this.page = page
      const query = { page: this.page }
      query.size = 5
      if (this.identifier) {
        query.identifier = this.identifier
      }
      this.getTestAccounts(this.merchant.id, query)
    },
    goToAccount: function (accountId) {
      this.$store.dispatch('toggleGlobalLoading', true)
      this.$store.dispatch('RESET_ACCOUNT_ENABLED')
      this.$router
        .push({
          name: 'home',
          params: { merchant_id: this.merchant.id, account_id: accountId }
        })
        .then(() => {
          this.$store.dispatch('GET_DATA_HOME', {
            merchant_id: this.merchant.id,
            account_id: accountId
          })
        })
        .catch(err => err)
      this.$store.dispatch('GET_AUTHORIZATION', {
        merchant_id: this.merchant.id,
        account_id: accountId
      })
    },
    searchTestAccount (identifier) {
      this.$store.dispatch('UPDATE_LOADING_LIST_PROD_ACCOUNT', true)
      this.page = 1
      const query = { identifier: identifier }
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.getTestAccounts(this.merchant.id, query)
      }, 750)
    }
  },
  activated () {
    this.getTestAccounts(this.merchant.id)
  }
}
</script>
