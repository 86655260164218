import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Motherboard from '@/components/project/templates/Motherboard.vue'
import Maintenance from '@/components/project/globals/Maintenance.vue'

import showMfes from '@/utils/showMfes'
import { isSeller } from '@/enums/user-role.enum'
import insightsMerchantEnabled from '@/actions/insights.action'

import Customers from '@/views/Customers/Customers.vue'
import Webhooks from '@/views/Webhooks/Webhooks.vue'

import {
  routeOrders,
  routeOrdersDetail,
  routeCharges,
  routeChargesDetail,
  routeTrf,
  routePaymentsLinks,
  routePaymentsLinksDetail
} from './payments'
import {
  routePlans,
  routePlansDetail,
  routeSubscriptions,
  routeSubscriptionsDetail,
  routeInvoices,
  routeInvoicesDetail
} from './recurrence'
import {
  routeUsers,
  routeUsersDetail,
  routeInvites,
  routeInvitesCreate,
  routeInvitesDetail,
  routeLogs
} from './users'
import {
  routeRecipients,
  routeRecipientsDetails,
  routeRecipientsCreate,
  routeRecipientsData,
  routeRecipientsSettings,
  routeRecipientsSettlements,
  routeRecipientsBalance,
  routeRecipientsTransfers
} from './recipients'
import { routeSettings } from './settings'
import { transfersRouters } from './mfes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkExactActiveClass: '_active',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    {
      path: '/:merchant_id',
      name: 'merchant',
      component: Motherboard,
      beforeEnter: async (to, from, next) => {
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
          return
        }

        const { merchant_id } = to.params
        const params = { merchant_id }

        if (!merchant_id.includes('merch_')) {
          next({ name: 'default' })
          return
        }

        if (!store.getters.merchantSummary) {
          await store.dispatch('GET_MERCHANT_SUMMARY', merchant_id)
        }

        if (!store.getters.profile) {
          const { merchant } = await store.dispatch('GET_AUTHORIZATION', params)

          if (!merchant) {
            store.commit('toggleGlobalError', {
              status: true,
              code: 404
            })
            return
          }

          return next({
            path: `/${params.merchant_id}/${merchant.account.id}/`
          })
        }
      }
    },
    {
      path: '/:merchant_id/:account_id',
      component: Motherboard,
      beforeEnter: async (to, from, next) => {
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
          return
        }

        const { merchant_id, account_id } = to.params
        const params = { merchant_id, account_id }

        if (!merchant_id?.includes('merch_') || !account_id?.includes('acc_')) {
          next({ name: 'default' })
          return
        }

        if (!store.getters.merchantSummary) {
          await store.dispatch('GET_MERCHANT_SUMMARY', merchant_id)
        }

        await store.dispatch('GET_ACCOUNT_SUMMARY', params) // required shop.account:get

        if (!store.getters.profile) {
          await store.dispatch('GET_AUTHORIZATION', params).then(() => {
            next()
          })
          return
        }

        next()
      },
      children: [
        {
          path: '',
          name: 'motherboard',
          redirect: { name: 'home' }
        },
        routeOrders,
        routeOrdersDetail,
        routeCharges,
        routeChargesDetail,
        routeTrf,
        routePaymentsLinks,
        routePaymentsLinksDetail,
        {
          path: 'customers/',
          name: 'customers',
          component: Customers,
          beforeEnter: (to, from, next) => {
            if (store.getters.permissionIsEnabled('customer:search')) {
              next()
            } else {
              store.commit('toggleGlobalError', { status: true, code: 404 })
            }
          },
          meta: {
            title: 'browser-title.customers'
          }
        },
        {
          path: 'customers/:id/:tab',
          name: 'customersDetail',
          component: () =>
            import(
              /* webpackChunkName: "customersdetail" */ '@/views/Customers/CustomersDetail.vue'
            ),
          beforeEnter: (to, from, next) => {
            const permissions = {
              info: 'customer:get',
              orders: 'order:search',
              charges: 'charge:search',
              invoices: 'invoice:search',
              subscriptions: 'subscription:search',
              cards: 'customer.cards:get'
            }
            if (store.getters.permissionIsEnabled(permissions[to.params.tab])) {
              next()
            } else {
              store.commit('toggleGlobalError', { status: true, code: 404 })
            }
          },
          meta: {
            title: 'browser-title.customer-detail'
          }
        },
        routePlans,
        routePlansDetail,
        routeSubscriptions,
        routeSubscriptionsDetail,
        routeInvoices,
        routeInvoicesDetail,
        routeRecipients,
        routeRecipientsCreate,
        routeRecipientsData,
        routeRecipientsDetails,
        routeRecipientsSettings,
        routeRecipientsBalance,
        routeRecipientsTransfers,
        routeRecipientsSettlements,
        routeLogs,
        routeUsers,
        routeUsersDetail,
        routeInvites,
        routeInvitesCreate,
        routeInvitesDetail,
        routeSettings,
        {
          path: 'balance/:pg_recipient_id?',
          alias: [
            'withdraw/:pg_recipient_id?',
            'anticipation/:pg_recipient_id?'
          ],
          name: 'balance',
          component: () =>
            import(
              /* webpackChunkName: "balance" */ '@/views/Balance/index.vue'
            ),
          beforeEnter: (to, from, next) => {
            if (showMfes(store)) {
              next()
            } else {
              store.commit('toggleGlobalError', { status: true, code: 404 })
            }
          }
        },
        ...transfersRouters,
        {
          path: '/',
          name: 'home',
          component: () =>
            import(/* webpackChunkName: "home" */ '@/views/Home/index.vue'),
          beforeEnter: (to, from, next) => {
            const { permissionIsEnabled, componentIsEnabled } = store.getters
            const { merchant_id, account_id } = to.params

            if (isSeller(store.getters.userRole.key)) {
              // eslint-disable-next-line
              next({ path: `/${merchant_id}/${account_id}/payment-links` })
              return
            }

            if (
              !insightsMerchantEnabled(permissionIsEnabled, componentIsEnabled)
            ) {
              // eslint-disable-next-line
              next({ path: `/${merchant_id}/${account_id}/orders` })
              return
            }

            const params = {
              merchant_id,
              account_id,
              query: to?.query ?? {}
            }

            // O next não é feito no then da request, pois aumenta o tempo da primeira interação do usuário.
            store.dispatch('GET_DATA_HOME', params)

            next()
          }
        },
        {
          path: 'webhooks',
          name: 'webhooks',
          component: Webhooks,
          beforeEnter: (to, from, next) => {
            if (store.getters.permissionIsEnabled('webhook:search')) {
              next()
            } else {
              store.commit('toggleGlobalError', { status: true, code: 404 })
            }
          },
          meta: {
            title: 'browser-title.webhooks'
          }
        },
        {
          path: 'webhooks/:id/:tab',
          name: 'webhooksDetail',
          component: () =>
            import(
              /* webpackChunkName: "webhooksdetail" */ '@/views/Webhooks/WebhooksDetail.vue'
            ),
          beforeEnter: (to, from, next) => {
            if (store.getters.permissionIsEnabled('webhook:get')) {
              next()
            } else {
              store.commit('toggleGlobalError', { status: true, code: 404 })
            }
          },
          meta: {
            title: 'browser-title.webhook-detail'
          }
        }
      ]
    },
    {
      path: '/',
      name: 'default',
      component: Motherboard,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
          return
        }

        store.dispatch('GET_AUTHORIZATION', {}).then(({ merchant }) => {
          if (!merchant) {
            store.commit('toggleGlobalError', {
              status: true,
              code: 404
            })
            return
          }

          if (store.getters.isEmployee) {
            window.location.assign(process.env.VUE_APP_BACKOFFICE_URL)
            return
          }

          store.dispatch('GET_MERCHANT_SUMMARY', merchant.id).then(() => {
            store.commit('toggleGlobalLoading', false)
            const { permissionIsEnabled, componentIsEnabled } = store.getters

            if (
              insightsMerchantEnabled(permissionIsEnabled, componentIsEnabled)
            ) {
              next({
                path: `/${merchant.id}/${merchant.account.id}`
              })
              return
            }

            if (permissionIsEnabled('order:search')) {
              next({
                path: `/${merchant.id}/${merchant.account.id}/orders`
              })
              return
            }

            // seller
            if (permissionIsEnabled('shop.checkout:create')) {
              window.location.assign(
                `${process.env.VUE_APP_SHOP_URL}/${merchant.id}/${merchant.account.id}`
              )
              return
            }

            store.commit('toggleGlobalError', {
              status: true,
              code: 404
            })
          })
        })
      }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          store.commit('toggleGlobalLoading', false)
          next()
        } else {
          next({ name: 'default' })
        }
      }
    },
    {
      path: '*',
      redirect: { name: 'home' }
    }
  ]
})

export default router
