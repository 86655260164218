<template>
  <div class="eu-align_center">
    <div class="el-section">
      <div class="el-empty _size_medium">
        <div class="emeraldraws">
          <emerald-icon :icon="drawEntity" />
        </div>
        <p class="emd-text _color_subtle _type_caption eu-mt_3">
          {{ $t(`empty-list.${entity}.no-results`) }}
        </p>
        <p class="emd-text _color_subtle eu-mt_3">
          {{ $t(`empty-list.${entity}.description`) }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import EmeraldIcon from '@/components/emerald/atoms/EmeraldIcon'

export default {
  name: 'EmptyList',
  components: {
    EmeraldIcon
  },
  props: {
    entity: {
      type: String,
      required: false,
      default: 'generic'
    },
    svg: {
      type: String,
      required: false
    }
  },
  computed: {
    drawEntity () {
      if (this.svg) return this.svg

      return this.entity === 'generic' ? 'no-results' : this.entity
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "empty-list": {
      "no-results": "Não encontramos resultados para essa busca",
      "new-filter": "Faça uma nova busca",
      "generic": {
        "no-results": "Sem itens na lista",
        "description": "Ainda não temos informações desse tipo para exibir na listagem."
      },
      "charges": {
        "no-results": "Você não tem cobranças",
        "description": "Administre e acompanhe o status de todas as cobranças, avulsas ou geradas através de pedidos e faturas."
      },
      "customers": {
        "no-results": "Sem clientes na carteira",
        "description": "Encontre aqui todas as informações de cadastro e dados de cobrança dos clientes da sua conta."
      },
      "invites": {
        "no-results": "Sem Convites",
        "description": "Convide usuários para participar da equipe, controle e acompanhe aqui os convites."
      },
      "users": {
        "no-results": "Você ainda não tem equipe",
        "description": "Visualize em lista quem acessa e cuida da sua loja, altere e edite permissões."
      },
      "orders": {
        "no-results": "Sem Pedidos",
        "description": "Gerencie aqui todos os pedidos realizados na sua loja, atualizados em tempo real."
      },
      "logs": {
        "no-results": "Sem registros de ações",
        "description": "Visualize e gerencie aqui todas as ações dos usuários vinculados a sua conta."
      },
      "subscriptions": {
        "no-results": "Sem assinaturas ativas",
        "description": "Gerencie as assinaturas da sua conta e cobre os seus clientes com intervalos pré determinados."
      },
      "invoices": {
        "no-results": "Sem Faturas",
        "description": "Ao conectar a sua loja, você visualiza aqui os documentos criados automaticamente ao final de cada ciclo de uma assinatura."
      },
      "plans": {
        "no-results": "Você ainda não criou planos",
        "description": "Visualize e gerencie aqui todos os templates de planos pré-definidos para as assinaturas da sua loja."
      },
      "webhooks-config": {
        "no-results": "Você ainda não criou webhooks",
        "description": "Configure uma URL para notificar sobre eventos como cobranças, pedidos, assinaturas, entre outros."
      },
      "trf": {
        "no-results": "Você ainda não tem registros no histórico",
        "description": "Para manter o tracking de alterações você terá acesso aos históricos de transação gerados automaticamente ao final de cada dia."
      },
      "webhooks": {
        "no-results": "Sem Webhooks",
        "description": "Visualize aqui todas as notificações enviadas para a URL definida na configuração do webhook."
      },
      "recipients": {
        "no-results": "Sem Recebedores",
        "description": "Visualize aqui todos os recebedores cadastrados na sua conta"
      },
      "locked": {
        "no-results": "Recurso indisponível",
        "description": "Verifique as configurações da sua conta. Contas com status inativo não acessam esta funcionalidade."
      },
      "fees": {
        "no-results": "Você não tem taxas cadastradas",
        "description": "Não foram encontradas taxas para este método de captura"
      },
      "payment-links": {
        "no-results": "Sem links de pagamento",
        "description": "Gerencie aqui todos os links de pagamento da sua loja, atualizados em tempo real."
      }
    }
  },
  "en-US": {
    "empty-list": {
      "no-results": "No results found.",
      "new-filter": "New search",
      "generic": {
        "no-results": "No list items",
        "description": "This kind of information is not yet avaiable to be displayed in this list. "
      },
      "charges": {
        "no-results": "You don`t have Charges",
        "description": "No results found."
      },
      "customers": {
        "no-results": "You don`t have Customers",
        "description": "No results found."
      },
      "invites": {
        "no-results": "You don`t have Invites",
        "description": "No results found."
      },
      "users": {
        "no-results": "You don`t have Users",
        "description": "No results found."
      },
      "orders": {
        "no-results": "You don`t have Orders",
        "description": "No results found."
      },
      "logs": {
        "no-results": "You don`t have Logs",
        "description": "No results found."
      },
      "subscriptions": {
        "no-results": "You don`t have Subscriptions",
        "description": "No results found."
      },
      "invoices": {
        "no-results": "You don`t have Invoices",
        "description": "No results found."
      },
      "plans": {
        "no-results": "You don`t have Plans",
        "description": "No results found."
      },
      "webhooks-config": {
        "no-results": "You don`t have Webhooks",
        "description": "No results found."
      },
      "trf": {
        "no-results": "You don`t have TRF",
        "description": "No results found."
      },
      "webhooks": {
        "no-results": "You don`t have Webhooks",
        "description": "No results found."
      },
      "recipients": {
        "no-results": "No recipients",
        "description": "View all of your account's registered recipients"
      },
      "locked": {
        "no-results": "Unavailable resource",
        "description": "Check your account settings. Accounts with an inactive status do not access this functionality."
      },
      "fees": {
        "no-results": "You have no fees registered",
        "description": "You have no fees for this capture method."
      },
      "payment-links": {
        "no-results": "No payment links",
        "description": "Manage all your store payment links here, updated in real time."
      }
    }
  }
}
</i18n>
