<template>
  <div class="el-section">
    <div
      class="emd-container _highlight_header _header_spaced _footer_spaced_sm"
    >
      <div class="emd-container__header">
        <h2 class="emd-text _size_section">
          {{ $t('subscription.list.title') }}
        </h2>
        <a
          v-if="canShowShop"
          target="_blank"
          rel="noopener noreferrer"
          class="emd-btn _color_primary _size_small"
          id="link-shop-subscriptions"
          :href="$sanitize(urlShop)"
          @click="shopTrack"
        >
          {{ $t('subscription.btn-create') }}
        </a>
      </div>

      <slot></slot>

      <section
        v-if="loadingListSubscriptions"
        class="eu-pa_6 eu-align_center"
      >
        <emd-loader type="_invert" />
      </section>

      <section
        v-else
        class="emd-table-responsive"
      >
        <emd-table
          :fields="fields"
          :items="items"
          :toDetail="detail"
        />

        <empty-list
          v-if="showEmptyList"
          :entity="emptyData.entity"
          :svg="emptyData.svg"
        />

        <empty-list-filter
          v-if="showEmptyListFilter"
          entity="subscriptions"
        />
      </section>

      <pagination
        v-if="!loadingListSubscriptions"
        type="list"
        :action="action"
        :pagination="pagination"
        :totalShowing="totalItems"
      ></pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { EmdLoader } from 'emerald-vue/src/components'

import EmptyList from '@/components/project/globals/EmptyList.vue'
import EmptyListFilter from '@/components/project/globals/EmptyListFilter.vue'
import EmdTable from '@/components/emerald/molecules/EmdTable.vue'
import Pagination from '@/components/project/globals/Pagination.vue'

import buildUrl from '@/utils/buildUrl.utils'

import { FIELDS } from './fields.enum'

export default {
  name: 'SubscriptionsTable',

  components: {
    EmdLoader,
    EmptyList,
    EmptyListFilter,
    EmdTable,
    Pagination
  },

  props: {
    hasLocked: Boolean,
    action: {
      type: String,
      default: 'GET_SUBSCRIPTIONS'
    },
    queryString: {
      type: Object,
      default: () => ({
        size: 30,
        period_type: 'date_filters',
        date_filters: 'SevenDays'
      })
    }
  },

  data () {
    return {
      toDetail: {
        name: 'subscriptionsDetail',
        params: {
          id: 'id',
          tab: 'info'
        },
        query: ['created_at']
      }
    }
  },

  computed: {
    ...mapGetters([
      'subscriptions',
      'loadingListSubscriptions',
      'permissionIsEnabled',
      'componentIsEnabled',
      'subscriptionIsEnabled',
      'merchantId',
      'accountId'
    ]),

    fields () {
      return FIELDS.map(item => ({ ...item, type: this.typeField(item) }))
    },

    pagination () {
      return this.subscriptions?.pagination
    },

    items () {
      return this.subscriptions?.items ?? []
    },

    totalItems () {
      return this.items.length
    },

    detail () {
      if (this.permissionIsEnabled('subscription:get')) return this.toDetail
      return null
    },

    emptyData () {
      return {
        entity: this.hasLocked ? 'locked' : 'subscriptions',
        svg: this.hasLocked ? 'no-results' : ''
      }
    },

    emptyList () {
      return (
        !this.loadingListSubscriptions &&
        this.pagination &&
        this.pagination.total_items === 0
      )
    },

    showEmptyList () {
      return (
        this.hasLocked ||
        (this.emptyList && Object.keys(this.queryString).length <= 2)
      )
    },

    showEmptyListFilter () {
      return this.emptyList && Object.keys(this.queryString).length > 2
    },

    urlShop () {
      return buildUrl({
        url: process.env.VUE_APP_SHOP_URL,
        pathParams: `${this.merchantId}/${this.accountId}/subscription`
      })
    },

    canShowShop () {
      if (
        this.subscriptionIsEnabled !== null &&
        this.permissionIsEnabled('shop.recurrence:create') &&
        this.componentIsEnabled('apps', 'shop')
      ) {
        return this.subscriptionIsEnabled
      }

      return false
    }
  },

  methods: {
    typeField (item) {
      if (!item.permission) return item.type
      return this.permissionIsEnabled(item.permission) ? 'link' : 'text'
    },

    shopTrack () {
      this.$gtag.event('click', {
        event_category: 'shopClick',
        event_label: 'Link shop página de assinaturas'
      })
    }
  }
}
</script>
