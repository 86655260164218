<template>
  <div
    class="emd-filter"
    v-bind:class="{ _open: isOpenned }"
  >
    <div class="emd-filter__placeholder"></div>
    <div class="emd-container _footer_end">
      <div class="emd-filter__input-search">
        <div
          class="emd-input-group _wrapped"
          id="filter"
          v-on:click="toggleFilter()"
        >
          <button class="emd-btn-ghost">
            <i class="uil uil-search" />
          </button>
          <div class="emd-filter__tag-list">
            <input
              type="text"
              class="emd-input"
              readonly=""
              placeholder=""
            />
            <div
              class="emd-tag-list _scrollable jsTabbar"
              v-bind:class="{ _disabled: loadingList }"
            >
              <ul>
                <template v-for="(value, label) in queryString">
                  <li
                    v-if="!(hideOption(value, label) || isEmpty(value))"
                    :key="label"
                  >
                    <span
                      class="emd-tag"
                      :class="{
                        _disabled: loadingList,
                        _color_info: componentIsEnabled('filter', 'tagInfo'),
                        _color_primary: componentIsEnabled(
                          'filter',
                          'tagPrimary'
                        )
                      }"
                    >
                      {{ getBadgeLabel(label, value) }}
                      <i
                        v-on:click="removeBadge(label)"
                        class="removeBadge uil uil-times-circle"
                      />
                    </span>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
        <button
          class="emd-btn _type_text"
          id="closeFilter"
          v-on:click="closeFilter"
        >
          {{ $t('filter.close') }}
        </button>
      </div>
      <div
        class="emd-filter__form"
        id="toggleFilter"
      >
        <fieldset :disabled="loadingList">
          <form
            action="javascript:void(0)"
            name="formFilter"
            novalidate="true"
            @submit.prevent="prepareFilter()"
          >
            <div class="emd-container__content">
              <div class="el-grid-list">
                <template v-for="(option, index) in fields">
                  <div
                    class="emd-form-group"
                    v-if="
                      !hideOption(
                        queryString[option.name_query],
                        option.name_query
                      )
                    "
                    :key="index"
                  >
                    <label class="emd-label">{{ getValue(option.name) }}</label>

                    <!-- text -->
                    <input
                      class="emd-input"
                      :id="option.name_query"
                      type="text"
                      :placeholder="$t(option.name)"
                      v-if="option.type === 'text'"
                      v-model="queryString[option.name_query]"
                    />

                    <!-- email -->
                    <input
                      class="emd-input"
                      :id="option.name_query"
                      type="email"
                      :placeholder="$t(option.name)"
                      v-if="option.type === 'email'"
                      v-model="queryString[option.name_query]"
                    />

                    <!-- number -->
                    <input
                      class="emd-input"
                      :id="option.name_query"
                      type="number"
                      :placeholder="$t(option.name)"
                      v-if="option.type === 'number'"
                      v-model="queryString[option.name_query]"
                    />

                    <!-- currency -->
                    <money
                      class="emd-input"
                      :id="option.name_query"
                      type="text"
                      v-bind="money"
                      :placeholder="$t(option.name)"
                      v-if="option.type === 'currency'"
                      v-model="amount"
                    ></money>

                    <!-- date -->
                    <input-date
                      :id="option.name_query"
                      v-if="option.type === 'date'"
                      v-bind:class="{
                        _state_error:
                          queryString[option.name_query] !== '' &&
                          queryString[option.name_query] !== null &&
                          queryString[option.name_query] !== undefined &&
                          option.error
                      }"
                      :value="queryString[option.name_query]"
                      @input="validateDate($event, index)"
                      :locale="locale"
                    />

                    <!-- select -->
                    <div
                      class="emd-select"
                      v-if="option.type === 'select'"
                      v-on:change="onChange(option)"
                    >
                      <select
                        v-model="queryString[option.name_query]"
                        :id="option.name_query"
                      >
                        <option :value="null"></option>
                        <option
                          v-for="(opt, index) in option.select_options"
                          :value="opt.id"
                          :key="index"
                        >
                          {{ getValue(opt.text) }}
                        </option>
                      </select>
                      <div class="emd-select__arrow"></div>
                    </div>
                    <!-- suggest -->
                    <div
                      class="emd-select"
                      v-if="option.type === 'suggest'"
                      v-on:change="onChange(option)"
                    >
                      <select
                        v-model="queryString[option.name_query]"
                        :id="option.name_query"
                      >
                        <option :value="null"></option>
                        <option
                          v-for="(opt, index) in option.select_options"
                          :value="opt"
                          :key="index"
                        >
                          {{ opt }}
                        </option>
                      </select>
                      <div class="emd-select__arrow"></div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="emd-container__footer _justify_flex-end">
              <button
                class="emd-btn _color_primary _type_text"
                type="button"
                v-on:click="clearFilter()"
                :disabled="queryStringLength() <= 2"
              >
                {{ $t('filter.clear') }}
              </button>
              <button
                class="emd-btn _color_primary"
                type="submit"
                :disabled="invalidForm || periodTypeInvalid"
              >
                {{ $t('filter.search') }}
              </button>
            </div>
          </form>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { Money } from 'v-money'
import InputDate from '@/components/project/atoms/InputDate.vue'
import { formatDate, createSinceDate, createUntilDate } from '@/utils'

export default {
  name: 'EmdFilter',
  components: {
    Money,
    InputDate
  },
  props: ['options', 'default', 'action', 'loadingList'],
  data () {
    return {
      isOpenned: false,
      queryString: {},
      invalidForm: false,
      amount: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$',
        suffix: '',
        precision: 2,
        masked: true
      },
      filterHistory: {}
    }
  },
  computed: {
    locale () {
      return this.$store.getters.pref.language
    },
    merchant () {
      return this.$store.getters.merchant
    },
    account () {
      return this.$store.getters.account
    },
    periodTypeInvalid () {
      // essa validação é necessária para validação do form assim que ocorre a troca de period_type e os campos estão nulos/indefinidos
      let valid = false
      if (
        Object.prototype.hasOwnProperty.call(this.queryString, 'period_type')
      ) {
        switch (this.queryString.period_type) {
          case 'specific_date':
            valid = this.queryString.specific_date === undefined
            break
          case 'interval':
            valid =
              this.queryString.since === undefined ||
              this.queryString.until === undefined
            break
          case 'date_filters':
            valid = this.queryString.date_filters === undefined
            break
        }
      }
      return valid
    },
    fields: {
      get () {
        var obj = []
        this.options.forEach(option => {
          const value = Object.assign({}, option)
          obj.push(value)
        })
        return obj
      },
      set () {}
    }
  },
  methods: {
    isEmpty (value) {
      return [undefined, null, ''].includes(value)
    },
    closeFilter: function () {
      this.isOpenned = false
    },
    toggleFilter: function () {
      if (this.loadingList) return
      if (!this.isOpenned) {
        this.filterHistory = Object.assign({}, this.queryString)
      } else {
        this.queryString = Object.assign({}, this.filterHistory)
      }
      this.isOpenned = !this.isOpenned
    },
    clearFilter: function () {
      this.queryString = {
        page: 1,
        sort_mode: 'DESC',
        size: 30
      }
      this.refreshQueryUrl(this.queryString)
      this.onInput()
      this.closeFilter()
      this.onFilter()
    },
    formIsInvalid () {
      const error = this.fields.filter(option => {
        // pegar a chave apenas null não funcionava, por ela retorna também undefined. foi adicionado ambos os cenários para a condicional de erro.
        return (
          this.queryString[option.name_query] !== null &&
          this.queryString[option.name_query] !== undefined &&
          option.error
        )
      })
      this.invalidForm = error.length > 0
    },
    validateDate (e, index) {
      if (e === null || e === undefined || e === '') {
        this.$set(this.fields[index], 'error', true)
        delete this.queryString[this.fields[index].name_query]
      } else {
        /*
         ** Adiciona valor de data na query apenas de não for nulo ou vazio.
         ** Isso evita com que o ocorra um "falso erro":
         **** cenário: ao trocar o period_type, o objeto reconhece o evento Input
         **** por o valor ter trocado para vazio. chave existe, a option possui
         **** erro = true mas o type foi alterado.
         */
        this.$set(this.queryString, this.fields[index].name_query, e)
        if (e.length === 10) {
          const value = new Date(e)
          if (isNaN(value.getTime()) || value.getTime() < 0) {
            this.$set(this.fields[index], 'error', true)
          } else {
            this.$set(this.fields[index], 'error', false)
          }
        } else {
          this.$set(this.fields[index], 'error', true)
        }
      }
      this.queryString = Object.assign({}, this.queryString)
      this.formIsInvalid()
    },
    onInput: function () {
      this.$emit('input', this.queryString)
    },
    onChange: function (option) {
      if (option.name_query === 'period_type') {
        delete this.queryString.created_since
        delete this.queryString.since
        delete this.queryString.created_until
        delete this.queryString.until
        delete this.queryString.specific_date
        delete this.queryString.date_filters
      }
      this.queryString = Object.assign({}, this.queryString)
    },
    getBadgeLabel: function (label, value) {
      const option = this.fields.find(opt => opt.name_query === label)
      let badgeValue = value
      if (option) {
        if (option.type === 'select') {
          const optionSelected =
            option.select_options.find(opt => opt.id === value) || {}
          badgeValue = optionSelected
            ? this.getValue(optionSelected.text)
            : null
        } else if (option.type === 'currency') {
          badgeValue = this.amount
        } else if (option.type === 'date') {
          /*
           ** formata a data apenas quando ela estiver completa.
           ** isso faz com que a data atualize na badge enquando
           ** o usuário escreve. ao completar, e o input emitir com
           ** o formato YYYY-MM-DD, ocorre a formatação na badge.
           */
          if (badgeValue && badgeValue.length === 10) {
            badgeValue = formatDate(this.locale, badgeValue)
          }
        }
        return `${this.getValue(option.name)}: ${badgeValue}`
      }
    },
    hideOption: function (value, label) {
      if (
        [
          'page',
          'size',
          'created_since',
          'created_until',
          'due_since',
          'due_until',
          'fields',
          'after',
          'before',
          'is_test_account',
          'sort_mode'
        ].includes(label)
      ) {
        return true
      } else if (
        label === 'specific_date' &&
        this.queryString.period_type !== 'specific_date'
      ) {
        return true
      } else if (
        label === 'date_filters' &&
        this.queryString.period_type !== 'date_filters'
      ) {
        return true
      } else if (
        (label === 'since' || label === 'until') &&
        this.queryString.period_type !== 'interval'
      ) {
        return true
      }
      return false
    },
    removeBadge: function (label) {
      if (this.loadingList) return
      delete this.queryString[label]
      if (
        label === 'specific_date' ||
        label === 'since' ||
        label === 'until' ||
        label === 'interval' ||
        label === 'date_filters' ||
        label === 'period_type'
      ) {
        delete this.queryString.created_since
        delete this.queryString.since
        delete this.queryString.created_until
        delete this.queryString.until
        delete this.queryString.period_type
        delete this.queryString.specific_date
        delete this.queryString.date_filters
      } else if (label === 'due_date') {
        delete this.queryString.due_date
        delete this.queryString.due_since
        delete this.queryString.due_until
      } else if (['amount'].includes(label)) {
        this.amount = 'R$0,00'
      }
      this.queryString.page = 1
      this.onInput()
      this.prepareFilter()
      setTimeout(() => {
        this.closeFilter()
      })
    },
    refreshQueryUrl: function (query) {
      if (query.after || query.before) delete query.page
      this.$router.replace({ query: query }).catch(err => err)
    },
    prepareFilter: function () {
      if (this.invalidForm || this.periodTypeInvalid) {
        this.fields = Array.from(this.fields)
      } else {
        if (this.queryString.period_type === 'specific_date') {
          this.queryString.created_since = createSinceDate(
            this.queryString.specific_date
          )
          this.queryString.created_until = createUntilDate(
            this.queryString.specific_date
          )
        } else if (this.queryString.period_type === 'interval') {
          this.queryString.created_since = createSinceDate(
            this.queryString.since
          )
          this.queryString.created_until = createUntilDate(
            this.queryString.until
          )
          /*
           ** valida se a data since é maior que a data until.
           ** caso seja, o back retorna erro e Bad Request.
           ** para evitar esse erro, o front passa a validar o intervalo
           ** e adiciona o feedback de erro ao input since
           */
          if (this.queryString.created_since > this.queryString.created_until) {
            this.fields.find(opt => {
              if (opt.name_query === 'since' || opt.name_query === 'until') {
                opt.error = true
              }
            })
            return
          }
        } else if (this.queryString.due_date) {
          this.queryString.due_since = createSinceDate(
            this.queryString.due_date
          )
          this.queryString.due_until = createUntilDate(
            this.queryString.due_date
          )
        }
        if (this.amount && this.amount !== 'R$0,00') {
          const amountInCents = this.amount.replace(/\D+/g, '')
          this.queryString.amount = amountInCents
        } else {
          delete this.queryString.amount
        }
        for (const query in this.queryString) {
          if (!this.queryString[query]) delete this.queryString[query]
        }
        if (!this.queryString.page) this.queryString.page = 1
        this.queryString.size = 30
        this.onInput()
        this.refreshQueryUrl(this.queryString)
        this.closeFilter()
        this.onFilter()
      }
    },
    queryStringLength: function () {
      return Object.entries(this.queryString).length
    },
    onFilter: function () {
      const params = {
        merchantId: this.merchant.id,
        accountId: this.account.id,
        query: this.queryString
      }
      this.$store.dispatch(this.action, params).catch(error => {
        this.$emit('error', error)
      })
    },
    getValue: function (value) {
      if (value && value.includes('.')) {
        return this.$t(value)
      }
      return value
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  },
  mounted: function () {
    document.addEventListener(
      'mouseup',
      e => {
        const filter = document.getElementById('filter')
        const toggleFilter = document.getElementById('toggleFilter')
        if (
          filter &&
          !filter.contains(e.target) &&
          toggleFilter &&
          !toggleFilter.contains(e.target)
        ) {
          if (this.isOpenned)
            this.queryString = Object.assign({}, this.filterHistory)
          this.isOpenned = false
        }
      },
      { passive: true }
    )
    const $filter = Array.from(document.querySelectorAll('.jsTabbar'))
    let startX
    let scrollLeft
    let isDown = false

    $filter.forEach(function (el, idx) {
      el.addEventListener(
        'mousewheel',
        function (e) {
          el.scrollLeft += e.deltaY
        },
        { passive: true }
      )

      el.addEventListener(
        'mousedown',
        function (e) {
          isDown = true
          startX = e.pageX - el.offsetLeft
          scrollLeft = el.scrollLeft
        },
        { passive: true }
      )

      el.addEventListener(
        'mousemove',
        function (e) {
          if (!isDown) return
          const x = e.pageX - el.offsetLeft
          const walk = (x - startX) * 2
          el.scrollLeft = scrollLeft - walk
        },
        { passive: true }
      )

      el.addEventListener(
        'mouseup',
        function (e) {
          isDown = false
        },
        { passive: true }
      )

      el.addEventListener(
        'touchstart',
        function (e) {
          isDown = true
          if (e.touches.length > 0) {
            startX = e.touches[0].pageX - el.offsetLeft
          } else {
            startX = e.pageX - el.offsetLeft
          }
          scrollLeft = el.scrollLeft
        },
        { passive: true }
      )

      el.addEventListener(
        'touchmove',
        function (e) {
          if (!isDown) return
          var x
          if (e.touches.length > 0) {
            x = e.touches[0].pageX - el.offsetLeft
          } else {
            x = e.pageX - el.offsetLeft
          }
          const walk = (x - startX) * 2
          el.scrollLeft = scrollLeft - walk
        },
        { passive: true }
      )

      el.addEventListener(
        'touchend',
        function (e) {
          isDown = false
        },
        { passive: true }
      )
    })
  },
  created: function () {
    if (Object.entries(this.$route.query).length >= 2) {
      const clonedQuery = Object.assign({}, this.$route.query)
      this.queryString = Object.assign(clonedQuery, this.queryString)
      this.amount = this.$route.query.amount
    } else {
      const clonedDefault = Object.assign({}, this.default)
      this.queryString = Object.assign(clonedDefault, this.queryString)
    }
    this.prepareFilter()
  }
}
</script>
<style>
.emd-tag._disabled {
  cursor: not-allowed;
}
</style>
