import MathManager from '../../../utils/math.manager'

const transform = value => MathManager.formatCurrency(value)

const ID = {
  id: 'id',
  label: 'list.id',
  type: 'link',
  link: {
    to: 'paymentLinksDetail',
    params: { id: 'id' },
    query: ['created_at']
  }
}
const NAME = {
  id: 'name',
  label: 'list.name',
  type: 'text'
}
const TYPE = {
  id: 'type',
  label: 'list.type',
  type: 'text'
}
const AMOUNT = {
  id: 'total_amount.value',
  label: 'list.amount',
  type: 'text',
  transform
}
const AMOUNT_DETAIL = {
  ...AMOUNT,
  id: 'amount'
}
const STATUS = {
  id: 'status',
  label: 'list.status',
  type: 'status'
}
const URL = {
  id: 'url',
  type: 'url',
  label: 'list.url',
  text: 'items.url',
  showCopy: true
}
const CREATED_AT = {
  id: 'created_at',
  label: 'list.createdAt',
  type: 'date',
  format: 'date'
}
const CREATED_AT_DETAIL = {
  ...CREATED_AT,
  id: 'createdAt',
  format: 'dateAndHour'
}
const UPDATED_AT = {
  id: 'updatedAt',
  label: 'list.updatedAt',
  type: 'date',
  format: 'dateAndHour'
}

const FIELDS = [ID, NAME, TYPE, AMOUNT, STATUS, CREATED_AT]

const FIELDS_DETAIL = [
  NAME,
  TYPE,
  AMOUNT_DETAIL,
  STATUS,
  URL,
  CREATED_AT_DETAIL,
  UPDATED_AT
]

export { FIELDS, FIELDS_DETAIL }
