<template>
  <emd-footer-internal
    :switchLanguage="switchLanguage"
    :switchThemeMode="switchThemeMode"
    :privacySecurity="privacySecurity"
    :help="help"
    :termsOfUse="termsOfUse"
    :privacyPolicy="privacyPolicy"
    :secureEnv="secureEnv"
    :documentation="documentation"
    :status="status"
    :listStyle="listStyle"
    @changeLanguage="changeLanguage"
    @changeThemeMode="changeThemeMode"
  />
</template>
<script>
import { EmdFooterInternal } from 'emerald-vue/src/components'
export default {
  name: 'EmdFooter',
  data: () => ({
    darkMode: false
  }),
  components: {
    EmdFooterInternal
  },
  computed: {
    switchLanguage () {
      return {
        show: this.componentIsEnabled('footer', 'switchLanguage'),
        value: this.pref.language
      }
    },
    switchThemeMode () {
      return {
        show: this.componentIsEnabled('footer', 'switchThemeMode'),
        isDark: this.darkMode,
        position: 'relative',
        align: 'center'
      }
    },
    privacySecurity () {
      return {
        show: this.componentIsEnabled('footer', 'privacySecurity'),
        url: process.env.VUE_APP_URL_PRIVACY_SECURITY
      }
    },
    help () {
      return {
        show: this.componentIsEnabled('footer', 'help'),
        url: process.env.VUE_APP_URL_HELP
      }
    },
    secureEnv () {
      return {
        isLink: this.componentIsEnabled('footer', 'urlSite'),
        url: process.env.VUE_APP_URL_SITE,
        iconClass: 'emd-pagarme-icon-outline',
        icon: 'pagarme',
        show: this.componentIsEnabled('footer', 'secureEnv')
      }
    },
    termsOfUse () {
      return {
        show: this.componentIsEnabled('footer', 'termsOfUse'),
        url: this.canShowTermsOfUseModal
          ? ''
          : process.env.VUE_APP_URL_TERMS_OF_USE,
        component: this.canShowTermsOfUseModal ? 'span' : 'a'
      }
    },
    privacyPolicy () {
      return {
        show: this.componentIsEnabled('footer', 'privacyPolicy'),
        url: process.env.VUE_APP_URL_PRIVACY_POLICY
      }
    },
    documentation () {
      return {
        show: this.componentIsEnabled('footer', 'documentation'),
        url: process.env.VUE_APP_URL_DOCUMENTATION
      }
    },
    status () {
      return {
        show: this.componentIsEnabled('footer', 'status'),
        url: process.env.VUE_APP_URL_STATUS
      }
    },
    listStyle () {
      return {
        divide: true,
        footerClassList: ['eu-py_4 eu-mt_auto'],
        space: 'small',
        align: 'center'
      }
    },
    canShowTermsOfUseModal () {
      return this.componentIsEnabled('footer', 'termsOfUseModal')
    },
    isPagarmeTheme () {
      return ['pagarme'].includes(process.env.VUE_APP_THEME)
    },
    pref () {
      return this.$store.getters.pref
    },
    theme () {
      return this.$store.getters.GET_THEME
    }
  },
  methods: {
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    },
    changeLanguage (lang) {
      this.$store.dispatch('toggleGlobalLoading', true)
      this.$store.dispatch('SET_LANGUAGE', lang)
    },
    changeThemeMode (mode) {
      this.$store.dispatch('SET_NEW_THEME_MODE', mode)
    }
  },
  beforeMount () {
    if (this.pref.language) this.$i18n.locale = this.pref.language
    this.darkMode = this.pref.appearance.mode === 'dark'
  }
}
</script>
