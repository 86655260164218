import { setAccount as appcuessSetAccount } from './appcues'

/**
 * Identify User in vendor SDK integrations
 * @param {number} userId user id
 * @param {number} merchanId merchan id
 * @param {accountId} accountId account id
 * @param {string} accountName user name
 * @param {string} businessModel businessModel
 * @param {string} accountType account type
 */

export default ({
  userId,
  merchanId,
  accountId,
  accountName,
  businessModel,
  accountType
}) => {
  appcuessSetAccount({
    userId,
    merchanId,
    accountId,
    accountName,
    businessModel,
    accountType
  })
}
