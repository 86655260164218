<template>
  <div class="el-stripe">
    <status-account
      :title="$t('account.settings.statusAccount.title')"
      :callback="saveStatusAccountConfig"
      :loading="accountLoading"
      :formIsInvalid="$v.statusAccount.$invalid"
      @cancelEdit="resetObject(statusAccount, accountSettings.statusAccount)"
      :hideEditButton="!permissionIsEnabled('settings.account.status:edit')"
      v-if="permissionIsEnabled('settings.account.status:view')"
    >
      <template slot="content">
        <div class="emd-panel">
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <h2 class="emd-text _color_default _type_caption">
                  {{ $t('account.settings.statusAccount.config') }}
                </h2>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- Status da conta -->
              <div class="el-chunk">
                <div class="emd-form-group">
                  <label
                    class="emd-label"
                    for="status"
                    >{{ $t('account.settings.statusAccount.status') }}</label
                  >
                  <label
                    class="emd-switcher__label"
                    for="enabledAccount"
                    data-children-count="1"
                  >
                    <input
                      class="emd-switcher _color_success"
                      type="checkbox"
                      id="enabledAccount"
                      true-value="active"
                      false-value="inactive"
                      v-model="statusAccount.status"
                    />
                    <div class="emd-switcher__area"></div>
                    <span class="emd-switcher__text">{{
                      $t('account.settings.statusAccount.active')
                    }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </status-account>

    <!-- Loja -->
    <shop-config
      @clickEditButton="$v.account.$touch"
      :title="$t('account.settings.title')"
      :callback="saveAccountConfig"
      :loading="accountLoading"
      :formIsInvalid="shopConfigFormIsInvalid"
      @cancelEdit="resetObject(account, accountSettings.account)"
      ref="shopConfig"
      :hideEditButton="!permissionIsEnabled('settings.account.data:edit')"
      v-if="permissionIsEnabled('settings.account.data:view')"
    >
      <template slot="content">
        <div class="emd-panel">
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <h2 class="emd-text _color_default _type_caption">
                  {{ $t('account.settings.data') }}
                </h2>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- Nome da conta -->
              <div class="emd-form-group">
                <label class="emd-label _required">{{
                  $t('account.settings.name')
                }}</label>
                <input
                  class="emd-input"
                  id="accountName"
                  type="text"
                  v-model="account.name"
                  autocomplete="off"
                  v-bind:class="{
                    _state_error:
                      $v.account.name.$invalid && $v.account.name.$dirty
                  }"
                  @input="$v.account.name.$touch"
                  :disabled="!isGateway"
                />
                <p
                  v-if="$v.account.name.$invalid && $v.account.name.$dirty"
                  class="emd-text _size_small _color_danger eu-mt_1"
                >
                  {{ $t('account.settings.hint.length', { length: '64' }) }}
                </p>
              </div>

              <!-- Razão social -->
              <div class="emd-form-group">
                <label
                  class="emd-label"
                  :class="{ _required: !isTestAccount }"
                  >{{ $t('account.settings.corporate-name') }}</label
                >
                <input
                  class="emd-input"
                  id="accountCorporateName"
                  type="text"
                  v-model="account.corporate_name"
                  autocomplete="off"
                  v-bind:class="{
                    _state_error:
                      $v.account.corporate_name.$invalid &&
                      $v.account.corporate_name.$dirty
                  }"
                  @input="$v.account.corporate_name.$touch"
                  :disabled="!isGateway"
                />
                <p
                  v-if="
                    $v.account.corporate_name.$invalid &&
                    $v.account.corporate_name.$dirty
                  "
                  class="emd-text _size_small _color_danger eu-mt_1"
                >
                  {{ $t('account.settings.hint.length', { length: '64' }) }}
                </p>
              </div>

              <div class="emd-form-group">
                <label
                  class="emd-label"
                  :class="{ _required: !isTestAccount }"
                  >{{ $t('account.settings.document') }}</label
                >
                <the-mask
                  :masked="false"
                  class="emd-input"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                  type="tel"
                  v-model="account.document"
                  v-bind:class="{
                    _state_error:
                      $v.account.document.$invalid && $v.account.document.$dirty
                  }"
                  @input="$v.account.document.$touch"
                  :disabled="!isGateway"
                />
                <!-- <p class="emd-input-feedback">digite o documento</p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="emd-panel">
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <h2 class="emd-text _color_default _type_caption">
                  {{ $t('account.settings.allowed-ips') }}
                </h2>
              </div>
              <div class="el-chunk">
                <p class="emd-text _color_nonessential _size_small">
                  {{ $t('account.settings.domains-detail') }}
                </p>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- Domínios -->
              <div
                class="el-chunk"
                v-if="!accountLoading && account"
              >
                <div class="emd-form-group">
                  <label
                    class="emd-label"
                    for="domains"
                    >{{ $t('account.settings.domains') }}</label
                  >
                  <input-tag-domains
                    :list="domains"
                    :disabled="shopConfigDisabled()"
                    @update="updateDomains"
                  />
                  <p class="emd-text _size_small _color_subtle eu-mt_1">
                    {{ $t('account.settings.domains-help.first') }}
                    <strong>{{
                      $t('account.settings.domains-help.second')
                    }}</strong>
                    {{ $t('account.settings.domains-help.third') }}
                    <strong>{{
                      $t('account.settings.domains-help.fourth')
                    }}</strong>
                    {{ $t('account.settings.domains-help.fifth') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <p class="emd-text _color_nonessential _size_small">
                  {{ $t('account.settings.ips-detail') }}
                </p>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- IPS Permitidos -->
              <div
                class="el-chunk"
                v-if="!accountLoading && account"
              >
                <div class="emd-form-group">
                  <label
                    class="emd-label"
                    for="allowedIps"
                    >{{ $t('account.settings.ips') }}</label
                  >
                  <input-tag-ips
                    :list="allowedIps"
                    :disabled="shopConfigDisabled()"
                    @update="updateIps"
                  />
                  <p class="emd-text _size_small _color_subtle eu-mt_1">
                    {{ $t('account.settings.ips-help.first') }}
                    <strong>
                      {{ $t('account.settings.ips-help.second') }}
                    </strong>
                    {{ $t('account.settings.ips-help.third') }}
                    <strong>
                      {{ $t('account.settings.ips-help.fourth') }}
                    </strong>
                    {{ $t('account.settings.ips-help.fifth') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="emd-panel">
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <h2 class="emd-text _color_default _type_caption">
                  {{ $t('account.settings.location') }}
                </h2>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- Fuso horário -->
              <div class="el-chunk">
                <div class="emd-form-group _condensed_70">
                  <label
                    class="emd-label"
                    :class="{ _required: !isTestAccount }"
                    >{{ $t('account.settings.timezone') }}</label
                  >
                  <div
                    class="emd-select"
                    id="timezone"
                    v-bind:class="{
                      _state_error: $v.account.time_zone.$invalid
                    }"
                  >
                    <select v-model="account.time_zone">
                      <option :value="null"></option>
                      <option
                        v-for="(timezone, index) in timezones"
                        :key="index"
                        :value="timezone.key"
                      >
                        {{ timezone.value }}
                      </option>
                    </select>
                    <div class="emd-select__arrow"></div>
                  </div>
                </div>
              </div>
              <!-- Moeda -->
              <div class="el-chunk">
                <div class="emd-form-group _condensed_70">
                  <label
                    class="emd-label"
                    :class="{ _required: !isTestAccount }"
                    >{{ $t('account.settings.currency') }}</label
                  >
                  <div
                    class="emd-select"
                    id="currency"
                    v-bind:class="{
                      _state_error: $v.account.default_currency.$invalid
                    }"
                  >
                    <select v-model="account.default_currency">
                      <option :value="null"></option>
                      <option value="BRL">BRL</option>
                    </select>
                    <div class="emd-select__arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- segmento -->
        <div class="emd-panel">
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <p class="emd-text _type_caption">
                  {{ $t('account.settings.segment-title') }}
                </p>
              </div>
            </div>
            <div class="emd-panel__block">
              <div class="el-chunk">
                <div class="emd-form-group">
                  <label
                    class="emd-label"
                    :class="{ _required: !isTestAccount }"
                    >{{ $t('account.settings.segment-title') }}</label
                  >
                  <div
                    class="emd-select"
                    v-bind:class="{
                      _state_error:
                        $v.account.segment.primary_classification.$invalid &&
                        $v.account.segment.primary_classification.$dirty &&
                        !shopConfigDisabled()
                    }"
                  >
                    <select
                      v-model="account.segment.primary_classification"
                      @change="$v.account.segment.primary_classification.$touch"
                    >
                      <option :value="null"></option>
                      <option
                        v-for="(segment, index) in segmentList"
                        :key="index"
                        :value="segment.name"
                      >
                        {{ segment.name }}
                      </option>
                    </select>
                    <div class="emd-select__arrow"></div>
                  </div>
                </div>
                <div
                  class="emd-form-group"
                  v-if="
                    account.segment.primary_classification &&
                    account.segment.primary_classification !== null
                  "
                >
                  <label
                    class="emd-label"
                    :class="{
                      _required:
                        !isTestAccount ||
                        (isTestAccount &&
                          account.segment.primary_classification)
                    }"
                    >{{ $t('account.settings.subsegment-title') }}</label
                  >
                  <div
                    class="emd-select"
                    v-bind:class="{
                      _state_error:
                        $v.account.segment.secundary_classification.$invalid &&
                        $v.account.segment.secundary_classification.$dirty
                    }"
                  >
                    <select
                      v-model="account.segment.secundary_classification"
                      @change="
                        $v.account.segment.secundary_classification.$touch
                      "
                    >
                      <option :value="null"></option>
                      <option
                        v-for="(segment, index) in segmentSecundaryList"
                        :key="index"
                        :value="segment.name"
                      >
                        {{ segment.name }}
                      </option>
                    </select>
                    <div class="emd-select__arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- integração -->
        <div class="emd-panel">
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <p class="emd-text _type_caption">
                  {{ $t('account.settings.integration-title') }}
                </p>
              </div>
            </div>
            <div class="emd-panel__block">
              <div class="el-chunk">
                <div class="emd-form-group">
                  <label
                    class="emd-label"
                    :class="{ _required: !isTestAccount }"
                    >{{ $t('account.settings.integration-title') }}</label
                  >
                  <div
                    class="emd-select"
                    v-bind:class="{
                      _state_error:
                        $v.account.integration.type.$invalid &&
                        $v.account.integration.type.$dirty &&
                        !shopConfigDisabled()
                    }"
                  >
                    <select
                      v-model="account.integration.type"
                      @change="$v.account.integration.type.$touch"
                    >
                      <option :value="null"></option>
                      <option
                        v-for="(integration, index) in integrationList"
                        :key="index"
                        :value="integration.name"
                      >
                        {{ integration.name }}
                      </option>
                    </select>
                    <div class="emd-select__arrow"></div>
                  </div>
                </div>
                <div
                  class="emd-form-group"
                  v-if="
                    account.integration.type &&
                    account.integration.type !== null
                  "
                >
                  <label
                    class="emd-label"
                    :class="{
                      _required:
                        !isTestAccount ||
                        (isTestAccount && account.integration.type)
                    }"
                    >{{ $t('account.settings.integration-maintainer') }}</label
                  >
                  <div
                    class="emd-select"
                    v-bind:class="{
                      _state_error:
                        $v.account.integration.maintainer.$invalid &&
                        $v.account.integration.maintainer.$dirty
                    }"
                  >
                    <select
                      v-model="account.integration.maintainer"
                      @change="$v.account.integration.maintainer.$touch"
                    >
                      <option :value="null"></option>
                      <option
                        v-for="(maintainer, index) in integrationMaintainerList"
                        :key="index"
                        :value="maintainer.name"
                      >
                        {{ maintainer.name }}
                      </option>
                    </select>
                    <div class="emd-select__arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- endereço -->
        <div class="emd-panel">
          <emd-address-brazilian-form
            v-model="account.address"
            :searchZipCodeIsAllowed="canSearchZipCode"
            :fieldsRequired="!isTestAccount"
            @formIsValid="validateAddressForm"
          />
        </div>
      </template>
    </shop-config>

    <general-config
      :title="$t('account.settings.general.title')"
      :callback="saveGeneralConfig"
      :loading="accountLoading"
      :formIsInvalid="$v.general.$invalid"
      @cancelEdit="resetObject(general, accountSettings.general)"
      :hideEditButton="
        !permissionIsEnabled('settings.account.general_data:edit')
      "
      v-if="permissionIsEnabled('settings.account.general_data:view')"
    >
      <template slot="content">
        <div class="emd-panel">
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <h2 class="emd-text _color_default _type_caption">
                  {{ $t('account.settings.general.data') }}
                </h2>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- Nome exibido nos produtos -->
              <div class="el-chunk">
                <div class="emd-form-group _condensed_70">
                  <label
                    class="emd-label _required"
                    for="nameProducts"
                    >{{ $t('account.settings.general.display-name') }}</label
                  >
                  <input
                    class="emd-input"
                    id="nameProducts"
                    type="text"
                    v-model="general.display_name"
                    v-bind:class="{
                      _state_error: $v.general.display_name.$invalid
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <p class="emd-text _color_nonessential _size_small">
                  {{ $t('account.settings.general.url-logo-detail') }}
                </p>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- URL Logo -->
              <div class="el-chunk">
                <div class="emd-form-group _condensed_70">
                  <label
                    class="emd-label"
                    for="urlLogo"
                    >{{ $t('account.settings.general.url-logo') }}</label
                  >
                  <input
                    class="emd-input"
                    id="urlLogo"
                    type="text"
                    v-model="general.logo"
                    v-bind:class="{ _state_error: $v.general.logo.$invalid }"
                  />
                </div>
              </div>
              <!-- Email -->
              <div class="el-chunk">
                <div class="emd-form-group _condensed_70">
                  <label
                    class="emd-label"
                    for="email"
                    >{{ $t('account.settings.general.email') }}</label
                  >
                  <input
                    class="emd-input"
                    id="email"
                    type="text"
                    v-model="general.email"
                    @input="general.email = general.email.toLowerCase()"
                    v-bind:class="{ _state_error: $v.general.email.$invalid }"
                  />
                </div>
              </div>
              <!-- Site -->
              <div class="el-chunk">
                <div class="emd-form-group _condensed_70">
                  <label
                    class="emd-label"
                    for="site"
                    >{{ $t('account.settings.general.url-site') }}</label
                  >
                  <input
                    class="emd-input"
                    id="site"
                    type="text"
                    v-model="general.website"
                    v-bind:class="{ _state_error: $v.general.website.$invalid }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="emd-panel">
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <h2 class="emd-text _color_default _type_caption">
                  {{ $t('account.settings.general.checkout-colors') }}
                </h2>
              </div>
              <div class="el-chunk">
                <p class="emd-text _color_nonessential _size_small">
                  {{ $t('account.settings.general.checkout-colors-detail') }}
                </p>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- Cores padrão -->
              <div class="el-chunk">
                <div class="emd-form-group">
                  <label
                    class="emd-label"
                    for="enableColors"
                    >{{ $t('account.settings.general.default-colors') }}</label
                  >
                  <label
                    class="emd-switcher__label"
                    for="enableColors"
                  >
                    <input
                      class="emd-switcher _color_success"
                      v-model="general.enableDefaultColors"
                      v-on:change="refreshColors"
                      type="checkbox"
                      id="enableColors"
                    />
                    <div class="emd-switcher__area"></div>
                    <span class="emd-switcher__text">{{
                      $t('account.settings.general.active')
                    }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <p class="emd-text _color_nonessential _size_small">
                  {{ $t('account.settings.general.primary-color-detail') }}
                </p>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- Cor principal -->
              <div class="el-chunk">
                <div class="emd-form-group _condensed_30">
                  <label
                    class="emd-label"
                    for="mainColor"
                    >{{ $t('account.settings.general.primary-color') }}</label
                  >
                  <input
                    :disabled="general.enableDefaultColors"
                    class="emd-input"
                    id="mainColor"
                    type="text"
                    v-model="general.primary_color"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <p class="emd-text _color_nonessential _size_small">
                  {{ $t('account.settings.general.secondary-color-detail') }}
                </p>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- Cor botão pagar -->
              <div class="el-chunk">
                <div class="emd-form-group _condensed_30">
                  <label
                    class="emd-label"
                    for="secondaryColor"
                    >{{ $t('account.settings.general.secondary-color') }}</label
                  >
                  <input
                    :disabled="general.enableDefaultColors"
                    class="emd-input"
                    id="secondaryColor"
                    type="text"
                    v-model="general.secondary_color"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </general-config>

    <wallet-config
      :title="$t('account.settings.wallet.title')"
      :callback="saveWalletConfig"
      :loading="accountLoading"
      :formIsInvalid="$v.wallet.$invalid"
      @cancelEdit="resetObject(wallet, accountSettings.wallet)"
      :hideEditButton="!permissionIsEnabled('settings.account.wallet:edit')"
      v-if="permissionIsEnabled('settings.account.wallet:view')"
    >
      <template slot="content">
        <div class="emd-panel">
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <h2 class="emd-text _color_default _type_caption">
                  {{ $t('account.settings.wallet.config') }}
                </h2>
              </div>
              <div class="el-chunk">
                <p class="emd-text _color_nonessential _size_small">
                  {{ $t('account.settings.wallet.status-detail') }}
                </p>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- Status wallet -->
              <div class="el-chunk">
                <div class="emd-form-group">
                  <label
                    class="emd-label"
                    for="status"
                    >{{ $t('account.settings.wallet.status') }}</label
                  >
                  <label
                    class="emd-switcher__label"
                    for="enabledWallet"
                  >
                    <input
                      class="emd-switcher _color_success"
                      type="checkbox"
                      id="enabledWallet"
                      v-model="wallet.enabled"
                    />
                    <div class="emd-switcher__area"></div>
                    <span class="emd-switcher__text">{{
                      $t('account.settings.wallet.active')
                    }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <p class="emd-text _color_nonessential _size_small">
                  {{ $t('account.settings.wallet.max-cards-detail') }}
                </p>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- Número máximo de cartões wallet -->
              <div class="el-chunk">
                <div class="emd-form-group _condensed_50">
                  <label class="emd-label _required">{{
                    $t('account.settings.wallet.max-cards')
                  }}</label>
                  <input
                    class="emd-input"
                    id="maxCards"
                    type="text"
                    v-model="wallet.max_cards_count"
                    v-bind:class="{
                      _state_error: $v.wallet.max_cards_count.$invalid
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="emd-panel__section">
            <div class="emd-panel__block">
              <div class="el-chunk">
                <h2 class="emd-text _color_default _type_caption">
                  {{ $t('account.settings.wallet.global-wallet') }}
                </h2>
              </div>
              <div class="el-chunk">
                <p class="emd-text _color_nonessential _size_small">
                  {{ $t('account.settings.wallet.global-wallet-detail') }}
                </p>
              </div>
            </div>
            <div class="emd-panel__block">
              <!-- Status wallet global -->
              <div class="el-chunk">
                <div class="emd-form-group">
                  <label
                    class="emd-label"
                    for="status"
                    >{{ $t('account.settings.wallet.status') }}</label
                  >
                  <label
                    class="emd-switcher__label"
                    for="enabledWalletGlobal"
                  >
                    <input
                      class="emd-switcher _color_success"
                      type="checkbox"
                      id="enabledWalletGlobal"
                      v-model="wallet.shared"
                    />
                    <div class="emd-switcher__area"></div>
                    <span class="emd-switcher__text">{{
                      $t('account.settings.wallet.active')
                    }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </wallet-config>
  </div>
</template>
<script>
import ConfigBox from '@/components/project/molecules/ConfigBox.vue'
import EmdInputTag from '@/components/emerald/molecules/EmdInputTag.vue'
import { EmdAddressBrazilianForm } from 'emerald-vue/src/components'
import {
  required,
  requiredIf,
  url,
  email,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'

export default {
  name: 'AccountConfig',
  components: {
    StatusAccount: ConfigBox,
    ShopConfig: ConfigBox,
    GeneralConfig: ConfigBox,
    WalletConfig: ConfigBox,
    InputTagDomains: EmdInputTag,
    InputTagIps: EmdInputTag,
    EmdAddressBrazilianForm
  },
  computed: {
    accountSettings: {
      get () {
        return this.$store.getters.accountSettings
      }
    },
    segmentList () {
      return this.$store.getters.segmentList
    },
    integrationList () {
      return this.$store.getters.integrationList
    },
    accountLoading () {
      return this.$store.getters.accountLoading
    },
    params: function () {
      return {
        merchantId: this.$store.getters.merchant.id,
        accountId: this.$store.getters.account.id
      }
    },
    activeAccount: function () {
      return this.$store.getters.account
    },
    isTestAccount: function () {
      return this.activeAccount.account_type.value === 'test'
    },
    formZipCode () {
      return this.account.address.zip_code
    },
    segmentSecundaryList () {
      if (
        this.segmentList.filter(
          item => item.name === this.account.segment.primary_classification
        ).length
      ) {
        return this.segmentList.filter(
          item => item.name === this.account.segment.primary_classification
        )[0].subsegment
      } else {
        return [{ name: 'Outros', id: 1 }]
      }
    },
    integrationMaintainerList () {
      if (
        this.integrationList.filter(
          item => item.name === this.account.integration.type
        ).length
      ) {
        return this.integrationList.filter(
          item => item.name === this.account.integration.type
        )[0].maintainer
      } else {
        return [{ name: 'Outros', id: 1 }]
      }
    },
    shopConfigFormIsInvalid () {
      return this.$v.account.$invalid || this.formAddressIsInvalid
    },
    isGateway () {
      return this.$store.getters.businessModel === 'gateway'
    }
  },
  validations: {
    statusAccount: {
      status: {
        required: false
      }
    },
    account: {
      name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(64)
      },
      corporate_name: {
        required: requiredIf(function () {
          return !this.isTestAccount
        }),
        minLength: minLength(1),
        maxLength: maxLength(64)
      },
      document: {
        required: requiredIf(function () {
          return !this.isTestAccount
        }),
        minLength: minLength(11),
        maxLength: maxLength(14)
      },
      segment: {
        primary_classification: {
          required: requiredIf(function () {
            return !this.isTestAccount
          }),
          minLength: minLength(1)
        },
        secundary_classification: {
          required: requiredIf(function () {
            return (
              !this.isTestAccount ||
              (this.isTestAccount &&
                this.account.segment.primary_classification)
            )
          }),
          minLength: minLength(1)
        }
      },
      integration: {
        type: {
          required: requiredIf(function () {
            return !this.isTestAccount
          })
        },
        maintainer: {
          required: requiredIf(function () {
            return (
              !this.isTestAccount ||
              (this.isTestAccount && this.account.integration.type)
            )
          })
        }
      },
      domains: {
        required: false
      },
      allowed_ips: {
        required: false
      },
      time_zone: {
        required: requiredIf(function () {
          return !this.isTestAccount
        })
      },
      default_currency: {
        required: requiredIf(function () {
          return !this.isTestAccount
        })
      }
    },
    general: {
      display_name: {
        required
      },
      logo: {
        required: false,
        url
      },
      email: {
        required: false,
        email
      },
      website: {
        required: false,
        url
      },
      primary_color: {
        required: false
      },
      secondary_color: {
        required: false
      }
    },
    wallet: {
      enabled: {
        required
      },
      max_cards_count: {
        required: requiredIf(function () {
          return this.wallet.enabled
        })
      },
      shared: {
        required
      }
    }
  },
  data () {
    return {
      domains: [],
      domainsCleanedList: [],
      allowedIps: [],
      timezones: null,
      statusAccount: {
        status: null
      },
      account: {
        name: null,
        corporate_name: null,
        document: null,
        allowed_ips: null,
        time_zone: null,
        domains: [],
        default_currency: null,
        segment: {
          primary_classification: null,
          secundary_classification: null,
          support_type: 'Support'
        },
        integration: {
          maintainer: null,
          type: null
        },
        address: {
          city: null,
          complement: null,
          country: 'BR',
          neighborhood: null,
          number: null,
          state: null,
          street: null,
          zip_code: null
        }
      },
      general: {
        display_name: null,
        logo: null,
        email: null,
        website: null,
        enableDefaultColors: null,
        primary_color: null,
        secondary_color: null
      },
      wallet: {
        enabled: null,
        max_cards_count: null,
        shared: null
      },
      canSearchZipCode: false,
      formAddressIsInvalid: false
    }
  },
  methods: {
    saveStatusAccountConfig (password) {
      this.statusAccount.password = password
      return this.$store.dispatch(
        'UPDATE_STATUS_ACCOUNT_SETTINGS',
        this.statusAccount
      )
    },
    prepareTestAccountBody () {
      const testAccountBody = Object.assign({}, this.account)
      if (!this.account.default_currency)
        testAccountBody.default_currency = 'BRL'
      if (!this.account.time_zone)
        testAccountBody.time_zone = 'E. South America Standard Time'

      if (!this.account.corporate_name) delete testAccountBody.corporate_name
      if (!this.account.document) delete testAccountBody.document
      if (!this.account.segment.primary_classification)
        delete testAccountBody.segment
      if (!this.account.integration.type) delete testAccountBody.integration
      if (!this.account.address.zip_code) delete testAccountBody.address
      return testAccountBody
    },
    saveAccountConfig (password) {
      this.account.domains = this.domainsCleanedList
      this.account.allowed_ips = this.allowedIps
      this.account.password = password
      this.canSearchZipCode = false

      if (this.isTestAccount) {
        const newAccountBody = this.prepareTestAccountBody()
        return this.$store.dispatch('UPDATE_ACCOUNT_SETTINGS', newAccountBody)
      }
      return this.$store.dispatch('UPDATE_ACCOUNT_SETTINGS', this.account)
    },
    saveGeneralConfig (password) {
      this.general.password = password
      this.general.email = this.general.email || null
      this.general.logo = this.general.logo || null
      this.general.website = this.general.website || null
      if (this.general.enableDefaultColors) {
        delete this.general.primary_color
        delete this.general.secondary_color
      }
      return this.$store.dispatch('UPDATE_GENERAL_SETTINGS', this.general)
    },
    saveWalletConfig (password) {
      this.wallet.password = password
      return this.$store.dispatch('UPDATE_WALLET_SETTINGS', this.wallet)
    },
    cleanUrls (arr) {
      arr = arr.map(item => item.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ''))
      return [].concat(arr)
    },
    updateDomains (newValue) {
      this.domainsCleanedList = this.cleanUrls(newValue)
      this.domains = newValue
    },
    updateIps (newValue) {
      this.allowedIps = newValue
    },
    shopConfigDisabled () {
      if (!this.accountLoading) {
        return (
          this.$refs && this.$refs.shopConfig && this.$refs.shopConfig.disabled
        )
      }
      return true
    },
    prepareAddressObj (obj, objFromApi) {
      if (objFromApi.address) {
        if (this.hasKeymissing(obj.address, objFromApi.address)) {
          obj.address = this.convertObjectToModel(
            obj.address,
            objFromApi.address
          )
        } else {
          obj.address = Object.assign({}, objFromApi.address)
        }
      } else {
        obj.address = {
          city: null,
          complement: null,
          country: 'BR',
          neighborhood: null,
          number: null,
          state: null,
          street: null,
          zip_code: null
        }
      }
      return obj.address
    },
    prepareSegmentObj (obj, objFromApi) {
      if (
        objFromApi.segment &&
        (objFromApi.segment.primary_classification ||
          objFromApi.segment.secundary_classification ||
          objFromApi.segment.support_type)
      ) {
        obj.segment = Object.assign(
          {
            primary_classification: null,
            secundary_classification: null,
            support_type: 'Support'
          },
          objFromApi.segment
        )
      } else {
        obj.segment = {
          primary_classification: null,
          secundary_classification: null,
          support_type: 'Support'
        }
      }
      return obj.segment
    },
    prepareIntegrationObj (obj, objFromApi) {
      if (
        objFromApi.integration &&
        (objFromApi.integration.maintainer || objFromApi.integration.type)
      ) {
        obj.integration = Object.assign(
          {
            maintainer: null,
            type: null
          },
          objFromApi.integration
        )
      } else {
        obj.integration = {
          maintainer: null,
          type: null
        }
      }
      return obj.integration
    },
    resetObject (obj, objFromApi) {
      this.canSearchZipCode = false
      if (Object.prototype.hasOwnProperty.call(obj, 'address'))
        obj.address = this.prepareAddressObj(obj, objFromApi)
      if (Object.prototype.hasOwnProperty.call(obj, 'segment'))
        obj.segment = this.prepareSegmentObj(obj, objFromApi)
      if (Object.prototype.hasOwnProperty.call(obj, 'integration'))
        obj.integration = this.prepareIntegrationObj(obj, objFromApi)

      obj = Object.assign(obj, JSON.parse(JSON.stringify(objFromApi)))

      if (Object.prototype.hasOwnProperty.call(obj, 'domains'))
        this.domains = [].concat(this.account.domains)
      if (Object.prototype.hasOwnProperty.call(obj, 'allowed_ips'))
        this.allowedIps = [].concat(this.account.allowed_ips)
      if (Object.prototype.hasOwnProperty.call(obj, 'email'))
        this.general.enableDefaultColors =
          !this.general.primary_color && !this.general.secondary_color
    },
    refreshColors () {
      if (this.general.enableDefaultColors) {
        this.general.primary_color = null
        this.general.secondary_color = null
      }
    },
    hasKeymissing (modelObj, incompleteObj) {
      const modelKeys = Object.keys(modelObj)
      const missingKeys = []

      modelKeys.forEach(el => {
        if (!(el in incompleteObj)) missingKeys.push(el)
      })

      return missingKeys.length > 0
    },
    convertObjectToModel (modelObj, incompleteObj) {
      const newIncompleteObj = Object.assign({}, incompleteObj)
      const modelKeys = Object.keys(modelObj)

      modelKeys.forEach(el => {
        if (!(el in incompleteObj)) newIncompleteObj[el] = null
      })
      return newIncompleteObj
    },
    validateAddressForm (val) {
      this.formAddressIsInvalid = !val
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  },
  created () {
    this.$store.dispatch('GET_ACCOUNT', this.params).then(res => {
      this.statusAccount = Object.assign(
        this.statusAccount,
        this.accountSettings.statusAccount
      )
      this.account = Object.assign(this.account, this.accountSettings.account)
      this.account.address = this.prepareAddressObj(this.account, res)
      this.account.segment = this.prepareSegmentObj(this.account, res)
      this.account.integration = this.prepareIntegrationObj(this.account, res)
      this.general = Object.assign(this.general, this.accountSettings.general)
      this.wallet = Object.assign(this.wallet, this.accountSettings.wallet)
      this.domains = this.domains.concat(this.account.domains)
      this.allowedIps = this.allowedIps.concat(this.account.allowed_ips)
      this.general.enableDefaultColors =
        !this.general.primary_color && !this.general.secondary_color
    })
  },
  beforeCreate () {
    this.$store.dispatch('GET_TIMEZONES').then(res => {
      this.timezones = res
    })
    this.$store.dispatch('GET_SEGMENT_LIST')
    this.$store.dispatch('GET_INTEGRATION_LIST')
  },
  watch: {
    formZipCode (value) {
      if (this.accountSettings.account.address) {
        if (
          value !== this.accountSettings.account.address.zip_code &&
          value.length >= 7
        )
          this.canSearchZipCode = true
      } else {
        if (value && value.length >= 7) this.canSearchZipCode = true
      }
    }
  }
}
</script>
