export const BOLETO_STONE_MERCHANTS = [
  '76933D93-379D-4FCB-96AC-17FE51456A42', // boleto stone 1
  'CD3048CC-BF28-47DE-BD59-FCAB8D35D4A1', // boleto stone 2
  '0D31B221-01A4-49C6-B3E1-72F7C7F3507C', // boleto stone 3
  'CFC402E9-0BF5-4FAF-BAA7-CA21589C26F3',
  'C7B6A459-740D-4C24-83BA-54012198B268',
  'CF82C459-4293-476C-AC57-554870546E32',
  'A3577C28-3A2F-495A-A200-BB2B993A7B92',
  '161EC250-07E2-4135-8ABF-1EDD6F835ADE',
  'C4B49DAD-E661-4408-9A2B-77C2FDBAFA66',
  '7208BEB4-5755-4C13-B333-466E57F4303C',
  '57DE5220-A8F1-49DE-B6D4-4B3B8B12BED8',
  'EA2BA5D2-7D7D-4BE0-865E-9066C1AB97C7',
  'FDFCEB98-9684-4274-B2E7-0FD8C6707FF2',
  'BE417831-7BA9-4EAE-9E99-4A1B8CB2681C',
  'B6319824-F4E8-4F16-9FBE-2DF7D5DA6CE3',
  '506214D5-6187-490E-96B9-0284F885C4BD',
  '22CA074C-9457-4225-BAAE-B9F44E22A18C',
  '5611F0E0-AFE0-46E4-B9E6-F59F4B996913',
  '27359E84-1BBC-47B4-9DB1-0359AEF31548',
  '88914E93-063D-45FC-AD49-B4CAF5954BB8',
  '71A2ADA3-832C-4551-8788-D69100F58E84',
  'FAA38B5A-77FF-463E-A365-5CB9DD42BBFE',
  'BE988666-F921-4C05-8B44-0E216FE94B26',
  'C57CAB6B-491A-4A17-A877-97B6BE744335',
  '453962BD-3133-4BA3-BCEB-D7D59073B896',
  '8CD81E2D-A727-42E5-8DE6-0BCA26477B46',
  '5ACF7C37-E267-44B6-8899-43B3763FF84B',
  '975F05CB-4A82-4629-B85E-23850C908FB7',
  'AC753758-7B56-4F32-B0A0-9A5897224D20',
  'A17284DD-AC7C-45C6-B6D8-A42CE272BAF1',
  '07DAF4CB-D52F-4D02-913C-8E51FC9D3E83',
  'C9AA00C1-4CD2-4F00-BE2A-6CC42D8B596C',
  '5CF87290-D03C-4A0C-95FE-FFF05D174D44',
  '846B7892-56C6-4E92-9607-D6E907FDF113',
  '2B52001A-0F7E-4B9A-88B5-B7F724F7390F',
  'A759B70A-3C58-4806-93AD-5F27F57DE428',
  'AAB8ACAA-6BE5-489B-9A4E-C4949B9D456D',
  'C556DE1D-0051-4380-8348-76A52268CA78',
  'D2B88EDE-CD61-4412-92E7-971A74AE40A7',
  'DD70D4CE-B522-47DE-9499-731E0E09CCC2',
  '1B25C3D9-1D54-4979-886F-7D8C074013EA',
  '013B0522-9F37-4298-9F83-246CC39CE7D7',
  'C02BFCAC-4AD1-429B-8114-C61BFA318A21',
  'F4FB699C-0412-4247-9F07-168C31BF31E2',
  '4EAB4F56-5EAB-43AA-BF31-2CEB94404B0B',
  '0ACA868C-E92D-4271-AE78-B3CEA6AC27EB',
  '010A66BF-DA73-4C99-B41D-728287E328D5',
  '570650DC-298E-4EDE-A17D-7BCD2A8E60C7', // TS HOST SERVIÇOS DE INTERNET LTDA
  '8BEA26C3-7F4A-4A0E-812F-B78804BED166', // Pitagoras Ampli
  '3117DC44-FEA3-48EF-BF82-1F4E2E15626F', // Teste Consentimento Boleto 2023
  'DFDAE141-E929-4CBA-B22D-342AD6F440B9'  // STG - Account test BFF
]

export const CHARGEBACK_COMPANIES = [
  '627bea38da6662ea342b0443', // STG - Account test BFF
  '6331fa388d0a4b0afe2ba0b8', // PROD - ADG Ferreira Negócios LTDA
  '632caba281b4660828117b12', // PROD -	ESSENTIAL PARFUMS
  '63a1bb9ea0128c547e28a4cb', // PROD -	A potiguar - site
  '627c117a30c4e4002afd253d', // PROD -	Use Shop Ju
  '61b8da33630b8d00135972ed', // PROD -	Conxa
  '62826fe80f1b880029944a2e', // PROD -	reconhece.vc
  '61a92fa8d5a41e001d2b4928', // PROD -	GRAMMD LOJA
  '621699e798e95e00134dcb4c', // PROD -	ROCHA SOLUÇÕES TECNOLOGICAS
  '61b0ac46148f2000198dbd36', // PROD -	Agua de praia
  '617c416cde75aa0012eb5a04', // PROD -	CDB Design
  '629ff91bc750a7001d4ecab9', // PROD -	Lucca customs
  '622a49c3bf1932001980bbae', // PROD -	People Fit
  '5f6a244f36075642cfb01762', // PROD -	mhkcloset
  '61143a234a460200185bb5e8', // PROD -	AM LUX LTDA
  '632bac1e59afb0e1a2bde57e', // PROD -	Panigale Jeans
  '62852bc34cb2f5001dd4c079', // PROD -	Tchica Shopping Guararapas
  '61ae08281009080018232a5e', // PROD -	NICHOOS TECNOLOGIA LTDA
  '6036b7a6fc5fa000191ebee7', // PROD -	VFARM COSMÉTICOS PRODUTOS NATURAIS EIRELI
  '6268484db5bede0011844792', // PROD -	MMC INDUSTRIA DE PRODUTOS NUTRACEUTICOS LTDA
  '624715941c5b620019c7bac0', // PROD -	DOCILE SUDESTE - COMERCIO DE PRODUTOS ALIMENTICIOS LTDA
  '62e41d803ce3f400292f787e', // PROD -	PATIO SAO PAULO INTERMEDIACAO DE NEGOCIOS ELETRONICOS LTDA
  '628d2076837a88001df430bf', // PROD -	LAYLLA LORRANA CRUZ DE CARVALHO 01240752156
  '6220f552eb2b6b0018e62642', // PROD -	Platinum indústria de confecções LTDA
  '6182747774fd4f001978f8fe', // PROD -	Galpao oriental eireli
  '62f6bf897241ff001d4eade8', // PROD -	S.A.F.BOTAFOGO
  '6228b072829a5000171bdcea', // PROD -	DEISE GONCALES DE LIMA 22331737835
  '609e8d3723a6df0012433ead', // PROD -	DECOR COLORS TINTAS LTDA
  '5890ea90896bf69a61592137', // PROD -	DTC TREINAMENTOS & CONSULTORIA LTDA - EPP
  '5f74f61e9a966c4829a21676', // PROD -	VALEMOBI CONSULTORIA EMPRESARIAL SA
  '628bcdde183a45001dcc80de', // PROD -	JEAN LUCAS SANTOS XAVIER
  '621fc0e242aa9a0019d3ac95', // PROD -	MARIA DAS BLUSINHAS LTDA
  '628be3cd946b40001d9de239', // PROD -	W A DISTRIBUIDORA LTDA
  '634055b623b5841a4635c401', // PROD -	DR PACK EMBALAGENS EIRELI
  '62d5c5a917c7a7001dadf2d1', // PROD -	RITA NARIKAWA
  '614206916b80080018aad5e6', // PROD -	ANDREA ALMEIDA DA SILVA SANTOS 37567521865
  '6290e7cbf1200f001d36c4b9', // PROD -	FELIPE PINHEIRO DE SOUZA ME
  '6324e73fe4c6cd16233559d7', // PROD -	NAYARA PEREIRA DA SILVA
  '6155d662a17f000012d8fde3', // PROD -	EBENEZER COMERCIO ATACADISTA E VAREJISTA LTDA
  '622f96734484f50011253cba', // PROD -	KAPEH COSMETICOS LTDA
  '63207ed9f910df2c39e2b21d', // PROD -	STERILY BRASIL INTERMEDIACAO DE NEGOCIOS ELETRONICOS LTDA
  '6352dd348615ec788865b7e0', // PROD -	LETICIA MATEUS LEMES
  '626fda82ac6fcf001954f3df', // PROD -	PICO SHOP LTDA
  '61ae696aba936800194ee2ef', // PROD -	WEV EMPREENDIMENTOS COMERCIAIS EIRELI
  '6227c351347b7b00133175f2', // PROD -	PATIO SAO PAULO INTERMEDIACAO DE NEGOCIOS ELETRONICOS LTDA
  '62bb5a2ac67995001e9ec970', // PROD -	JONATHAS SCARPIONI DO LAGO LTDA
  '61a7b29310e33200196ea45b', // PROD -	DFP SOLUCOES EM TI LTDA
  '63038b14cdd3b5001eb623f9', // PROD -	CASA & VIDEO RIO DE JANEIRO SA
  '604fa47a2681610018d0c367', // PROD -	MOTOROLA MOBILITY COMERCIO DE PRODUTOS ELETRONICOS LTDA
  '62433dd330a03300198afd57', // PROD -	AFEET FRANQUIAS LTDA
  '614c897cadfa35001c96691d', // PROD -	MUNDO INFANTIL STORE
]