<template>
  <div class="emd-filter-dash__input">
    <div>
      <!-- TO DO: component emd-input-dropdown -->
      <div
        class="emd-input-dropdown"
        :class="{ _focus: opened.key }"
        @click="openDropdown('key')"
        v-click-outside="keyClickOutside"
      >
        <input
          ref="keyInput"
          data-testid="dropdown-advanced-filter-select"
          class="emd-input"
          :class="{ 'emd-tippy-template': index === 0 }"
          :data-template="index === 0 ? 'tooltip-key-filter' : null"
          type="text"
          :placeholder="$t('placeholder.key')"
          v-model="keyModel"
          @keyup.enter="handleKeySubmit"
        />
        <i class="uil uil-angle-down emd-input-dropdown__arrow" />
        <div
          data-testid="dropdown-advanced-filter-select-option"
          class="emd-input-dropdown__main"
        >
          <emd-select-list
            v-if="filteredKeyOptions.length > 0"
            :list="filteredKeyOptions"
            compact
            @clickedItem="selectKey"
          />
          <p
            class="emd-text _align_center _size_small"
            v-else
          >
            {{ $t('no-item') }}
          </p>
        </div>
      </div>
      <emd-tippy
        v-if="index === 0"
        :isHidden="!isParentOpen || !showTooltip.key"
        :destroy="!showTooltip.key"
        id="key-filter"
        :title="$t('tooltip.key-title')"
        type="template"
      ></emd-tippy>
    </div>
    <template v-if="query.key.type === 'select'">
      <div>
        <emd-tippy
          v-if="index === 0"
          :isHidden="!isParentOpen || !showTooltip.value"
          :destroy="!showTooltip.value"
          id="value-filter"
          :title="$t('tooltip.value-title')"
          type="template"
          :position="'bottom'"
        ></emd-tippy>
        <div
          data-testid="dropdown-advanced-filter-select-option-select-value-input"
          class="emd-input-dropdown"
          :class="{ _focus: opened.value }"
          @click="openDropdown('value')"
          v-click-outside="valueClickOutside"
        >
          <emd-input-tag
            ref="valueInputTag"
            :data-template="index === 0 ? 'tooltip-value-filter' : null"
            :class="{ 'emd-tippy-template': index === 0 }"
            :list="tags"
            :focus="opened.value"
            :input="inputTagValue"
            @inputChanged="inputValueChanged"
            @onSubmit="handleValueSubmit"
            @listUpdated="updateValueList"
          />
          <i class="uil uil-angle-down emd-input-dropdown__arrow" />
          <div
            data-testid="dropdown-advanced-filter-select-option-select-value"
            class="emd-input-dropdown__main"
          >
            <emd-select-list
              data-testid="dropdown-advanced-filter-select-option-select-value-input-list"
              v-if="filteredValueOptions.length > 0"
              :list="filteredValueOptions"
              compact
              @clickedItem="selectValue"
            />
            <p
              class="emd-text _align_center _size_small"
              v-else
            >
              {{ $t('no-item') }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="query.key.type === 'currency'">
      <money
        data-testid="dropdown-advanced-filter-input-order-amount"
        class="emd-input"
        :id="query.key.name_query"
        type="text"
        v-bind="money"
        :placeholder="$t(query.key.name)"
        v-model="valueModel"
        @input="currencyInputChanged"
        @keyup.native.enter="handleValueSubmit"
      />
    </template>
    <template v-else-if="query.key.type === 'date'">
      <div>
        <datepicker
          type="simple"
          :limitDate="today"
          :id="'paid-date'"
          :date="query.value.date"
          :locale="locale"
          @changeDate="setDate"
          @keydown.enter.native="handleValueSubmit"
        />
        <p
          class="emd-input-feedback"
          v-if="query.key.name_query === 'start_paid_at'"
        >
          {{ $t('warning-start-paid-at') }}
        </p>
      </div>
    </template>
    <template v-else>
      <input
        class="emd-input _disabled"
        disabled
        type="text"
      />
    </template>

    <span>
      <button
        v-if="query.key.name_query !== '' || selectedKeys.length > 1"
        class="emd-action-button _lvl_2 _square _color_danger _size_small"
        @click="removeItem"
      >
        <i class="emd-action-button__icon uil uil-trash" />
      </button>
    </span>
  </div>
</template>

<script>
import {
  EmdSelectList,
  EmdInputTag,
  EmdTippy
} from 'emerald-vue/src/components'
import { Money } from 'v-money'
import Datepicker from '@/components/emerald/organisms/Datepicker.vue'

export default {
  components: {
    Money,
    EmdSelectList,
    Datepicker,
    EmdInputTag,
    EmdTippy
  },
  name: 'EmdFilterAdvancedInput',
  props: {
    query: {
      type: [Array, Object],
      default: () => [],
      required: false
    },
    options: {
      type: [Array, Object],
      default: () => [],
      required: false
    },
    selectedKeys: {
      type: Array,
      default: () => [],
      required: false
    },
    index: {
      type: Number,
      default: null,
      required: false
    }
  },
  data () {
    return {
      opened: {
        key: false,
        value: false
      },
      keyModel: this.$t(this.query.key.name),
      inputTagValue: {
        value: ''
      },
      valueModel: this.query.value.amount,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$',
        suffix: '',
        precision: 2,
        masked: false
      },
      invalidDate: false
    }
  },
  computed: {
    today () {
      const now = new Date()
      return now.toString()
    },
    isParentOpen () {
      // repensar
      return this.$parent.$parent.advancedOpened
    },
    showTooltip: {
      get () {
        const obj = {
          key: !this.$store.getters.onboarding.closed_tooltip_key,
          value: !this.$store.getters.onboarding.closed_tooltip_value
        }
        return obj
      },
      set () {}
    },
    locale () {
      return this.$store.getters.pref.language
    },
    filteredKeyOptions () {
      return this.options
        .filter(item => {
          return (
            this.isEmpty(this.keyModel) ||
            this.$t(this.query.key.name).toLowerCase() ===
              this.keyModel.toLowerCase() ||
            this.$t(item.name)
              .toLowerCase()
              .includes(this.keyModel.toLowerCase())
          )
        })
        .map(item => {
          const active = this.selectedKeys.includes(item.name_query)
          const disabled =
            active &&
            (!this.query.key.name_query ||
              this.query.key.name_query !== item.name_query)
          return {
            id: item.name_query,
            label: this.$t(item.name),
            active: active,
            disabled: disabled
          }
        })
    },
    tags () {
      return this.query.value.tags.map(item => {
        return this.$t(item.text)
      })
    },
    filteredValueOptions () {
      return this.query.key.select_options
        .filter(item => {
          return (
            this.isEmpty(this.inputTagValue.value) ||
            this.$t(item.text)
              .toLowerCase()
              .includes(this.inputTagValue.value.toLowerCase())
          )
        })
        .map(item => {
          const active = this.tags.includes(this.$t(item.text))
          return {
            id: item.id,
            label: this.$t(item.text),
            active: active
          }
        })
    }
  },
  watch: {
    query (query) {
      this.keyModel = this.$t(query.key.name)
      this.valueModel = query.value.amount
    }
  },
  methods: {
    removeItem () {
      this.$emit('removeItem', this.index)
    },
    inputValueChanged (inputValue) {
      this.inputTagValue = Object.assign({}, { value: inputValue })
    },
    currencyInputChanged (event) {
      this.$emit('updateAmount', this.valueModel, this.index)
    },
    setDate (date) {
      if (date.hasError || date.value.length < 10) {
        this.invalidDate = true
      } else {
        this.invalidDate = false
        this.$emit('updateDate', date.value, this.index)
      }
    },
    handleKeySubmit (value, event) {
      this.opened.key = false
      this.$refs.keyInput.blur()
      if (this.filteredKeyOptions.length === 1) {
        this.selectKey(this.filteredKeyOptions[0])
      }
    },
    handleValueSubmit (event) {
      this.opened.key = false
      if (this.$refs.valueInputTag && this.$refs.valueInputTag.isFocus) {
        this.opened.value = false
        if (this.filteredValueOptions.length === 1) {
          this.$emit('selectValue', this.filteredValueOptions[0].id, this.index)
        }
      }
      if (!(this.query.key.type === 'date' && this.invalidDate)) {
        this.$emit('submitValue')
      }
    },
    updateValueList (list) {
      const items = []
      list.forEach(item => {
        var obj = this.query.key.select_options.find(
          element =>
            this.$t(element.text).toLowerCase() === item.toLowerCase() &&
            !items.some(e => e.id === element.id)
        )
        if (obj) {
          items.push(obj)
        }
      })
      this.$emit('updateList', items, this.index)
    },
    selectKey (item, event) {
      if (event) event.stopPropagation()
      if (item.disabled) return
      this.keyModel = item.label
      if (!item.active) {
        this.opened.key = false
        this.$emit('selectKey', item.id, this.index)
      }
    },
    selectValue (item) {
      this.inputTagValue = Object.assign({}, { value: '' })
      this.$emit('selectValue', item.id, this.index)
    },
    openDropdown (key) {
      if (this.showTooltip[key]) this.closeTooltip(key)
      if (!this.opened[key]) {
        this.opened[key] = true
      }
    },
    keyClickOutside () {
      this.opened.key = false
      if (this.keyModel !== this.$t(this.query.key.name)) {
        this.keyModel = this.$t(this.query.key.name)
      }
    },
    valueClickOutside () {
      this.opened.value = false
    },
    isEmpty (value) {
      return /^\s*$/.test(value) || [undefined, null, ''].includes(value) // testa se é espaço vazio
    },
    closeTooltip (type) {
      this.$store.dispatch('CLOSE_TOOLTIP_FILTER_ONBOARDING', type)
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "placeholder": {
      "key": "Digite ou selecione",
      "value": ""
    },
    "tooltip": {
      "key-title": "Clique aqui para adicionar um filtro selecionando as opções.",
      "value-title": "Experimente selecionar mais de um parâmetro ao filtro."
    },
    "no-item": "Nenhum ite para exibir",
    "warning-start-paid-at": "A data de criação será ignorada"
  },
  "en-US": {
    "placeholder": {
      "key": "Type or select",
      "value": ""
    },
    "tooltip": {
      "key-title": "Click here to select an option and add a new filter",
      "value-title": "Try to add more than one parameter to the filter"
    },
    "no-item": "No item to display",
    "warning-start-paid-at": "The creation date will be ignored"
  }
}
</i18n>
