function actionsPermissionIsEnabled (
  actions = [],
  permissionIsEnabledFn = undefined
) {
  if (!permissionIsEnabledFn) {
    throw new Error('Missing function permissionIsEnabled')
  }

  return actions.every(action => permissionIsEnabledFn(action))
}

export default actionsPermissionIsEnabled
