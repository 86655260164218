<template>
  <div class="eu-align_center">
    <div class="el-section">
      <div class="el-empty _size_medium">
        <div class="emeraldraws">
          <emerald-icon :icon="entity" />
        </div>
        <h2 class="emd-text _color_subtle _size_section eu-mt_3">
          {{ $t('no-results') }}
        </h2>
        <h3
          class="emd-text _color_subtle eu-mt_3"
          v-if="showDescription"
        >
          {{ $t('description') }}
        </h3>
        <h3
          v-else
          class="emd-text _color_subtle eu-mt_3"
        >
          {{ $t('description-period-p1') }}
          <span
            class="emd-text _type_label _color_primary _size_small eu-bg_300"
          >
            [{{ getPeriod() }}]
          </span>
          {{ $t('description-period-p2') }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import EmeraldIcon from '@/components/emerald/atoms/EmeraldIcon'
import { formatDate } from '@/utils'

export default {
  name: 'EmptyListFilter',
  components: {
    EmeraldIcon
  },
  props: {
    entity: {
      type: String,
      required: false,
      default: () => 'no-results'
    }
  },
  computed: {
    locale () {
      return this.$store.getters.pref.language
    },
    showDescription () {
      if (this.entity === 'customers') return true

      const { period_type: periodType } = this.$route.query

      return (
        periodType &&
        (periodType === 'all' || periodType === 'paid_date_filters')
      )
    }
  },
  methods: {
    getPeriod () {
      const query = this.$route.query
      const periods = {
        Today: 'today',
        Yesterday: 'yesterday',
        SevenDays: 'last-7-days',
        FifteenDays: 'last-15-days',
        OneMonth: 'last-30-days',
        interval: 'interval',
        specific_date: 'specific'
      }

      if (query.period_type && query.period_type === 'interval') {
        const since = formatDate(this.locale, query.created_since.split('T')[0])
        const until = formatDate(this.locale, query.created_until.split('T')[0])
        return `${since} - ${until}`
      }

      if (query.period_type && query.period_type === 'specific_date') {
        return formatDate(this.locale, query.created_since.split('T')[0])
      }

      return this.$t(periods[query.date_filters])
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "no-results": "Não encontramos resultados para essa busca",
    "description-period-p1": "Experimente expandir o período selecionado",
    "description-period-p2": "ou verifique a combinação dos filtros aplicados.",
    "description": "Verifique a combinação dos filtros aplicados ou realize uma nova busca.",
    "today": "Hoje",
    "yesterday": "Ontem",
    "last-7-days": "Últimos 7 dias",
    "last-15-days": "Últimos 15 dias",
    "last-30-days": "Últimos 30 dias",
    "specific": "Específico",
    "interval": "Intervalo"
  },
  "en-US": {
    "no-results": "We couldn’t find matches to this filter",
    "description-period-p1": "Try expanding the creation date",
    "description-period-p2": "or check on the applied filters combination.",
    "description": "Check on the applied filters combination or do a new research.",
    "today": "Today",
    "yesterday": "Yesterday",
    "last-7-days": "Last 7 days",
    "last-15-days": "Last 15 days",
    "last-30-days": "Last 30 days",
    "specific": "Specific",
    "interval": "Interval"
  }
}
</i18n>
