import store from '@/store'
import PaymentLinks from '@/views/PaymentLinks/index.vue'

import {
  paymentsLinksViewEnabled,
  paymentsLinksDetailEnabled
} from '@/actions/payment-links.actions.js'

const routePaymentsLinks = {
  path: 'payment-links',
  name: 'paymentLinks',
  component: PaymentLinks,
  beforeEnter: (to, from, next) => {
    if (
      !paymentsLinksViewEnabled(
        store.getters.permissionIsEnabled,
        store.getters.isCheckoutV2Enabled
      )
    ) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }
    next()
  },
  meta: {
    title: 'browser-title.payment-links.title'
  }
}

const routePaymentsLinksDetail = {
  path: 'payment-links/:id',
  name: 'paymentLinksDetail',
  component: () =>
    import(
      /* webpackChunkName: "usersdetail" */ '@/views/PaymentLinks/Details/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      !paymentsLinksDetailEnabled(
        store.getters.permissionIsEnabled,
        store.getters.isCheckoutV2Enabled
      )
    ) {
      return store.commit('toggleGlobalError', { status: true, code: 404 })
    }

    store.dispatch('GET_PAYMENTS_LINKS_DETAIL', to.params.id)
    next()
  },
  meta: {
    title: 'browser-title.payment-links.detail'
  }
}

export { routePaymentsLinks, routePaymentsLinksDetail }
