import { CHARGES_FILTER_CHARGEDBACK } from './charges_filter_chargedback'

const filters = [
  {
    name: 'charge.filter.acquirer',
    name_query: 'acquirers',
    type: 'select',
    select_options: [
      {
        id: 'Aura',
        text: 'Aura'
      },
      {
        id: 'Bin',
        text: 'Bin'
      },
      {
        id: 'Cetelem',
        text: 'Cetelem'
      },
      {
        id: 'Cielo',
        text: 'Cielo'
      },
      {
        id: 'Conductor',
        text: 'Conductor'
      },
      {
        id: 'CredSystem',
        text: 'CredSystem'
      },
      {
        id: 'Dacasa',
        text: 'Dacasa'
      },
      {
        id: 'DM Card',
        text: 'DM Card'
      },
      {
        id: 'EBANX',
        text: 'EBANX'
      },
      {
        id: 'GetNet',
        text: 'GetNet'
      },
      {
        id: 'Global Payments',
        text: 'Global Payments'
      },
      {
        id: 'Havan',
        text: 'Havan'
      },
      {
        id: 'PagarMe',
        text: 'PagarMe'
      },
      {
        id: 'PagSeguro',
        text: 'PagSeguro'
      },
      {
        id: 'Redecard',
        text: 'Redecard'
      },
      {
        id: 'SafraPay',
        text: 'SafraPay'
      },
      {
        id: 'Senff',
        text: 'Senff'
      },
      {
        id: 'Sodexo',
        text: 'Sodexo'
      },
      {
        id: 'Stone',
        text: 'Stone'
      },
      {
        id: 'VR',
        text: 'VR'
      }
    ]
  },
  {
    name: 'charge.filter.bank',
    name_query: 'banks',
    type: 'select',
    select_options: [
      {
        id: '001',
        text: 'Banco do Brasil'
      },
      {
        id: '237',
        text: 'Bradesco'
      },
      {
        id: '104',
        text: 'Caixa Econômica'
      },
      {
        id: '745',
        text: 'Citibank'
      },
      {
        id: '341',
        text: 'Itaú'
      },
      {
        id: '033',
        text: 'Santander'
      },
      {
        id: '174',
        text: 'Pefisa'
      },
      {
        id: '376',
        text: 'JP Morgan'
      },
      {
        id: '198',
        text: 'Pagar.me'
      }
    ]
  },
  {
    name: 'charge.filter.brand',
    name_query: 'brands',
    type: 'select',
    select_options: [
      {
        id: 'visa',
        text: 'Visa'
      },
      {
        id: 'mastercard',
        text: 'Mastercard'
      },
      {
        id: 'hipercard',
        text: 'Hipercard'
      },
      {
        id: 'discover',
        text: 'Discover'
      },
      {
        id: 'diners',
        text: 'Diners'
      },
      {
        id: 'amex',
        text: 'Amex'
      },
      {
        id: 'aura',
        text: 'Aura'
      },
      {
        id: 'elo',
        text: 'Elo'
      },
      {
        id: 'alelo',
        text: 'Alelo'
      },
      {
        id: 'sodexo',
        text: 'Sodexo'
      },
      {
        id: 'vr',
        text: 'VR'
      },
      {
        id: 'jcb',
        text: 'Jcb'
      },
      {
        id: 'casaEVideo',
        text: 'CasaEVideo'
      },
      {
        id: 'casaShow',
        text: 'CasaShow'
      },
      {
        id: 'havan',
        text: 'Havan'
      },
      {
        id: 'hugCard',
        text: 'HugCard'
      },
      {
        id: 'andarAki',
        text: 'AndarAki'
      },
      {
        id: 'leaderCard',
        text: 'LeaderCard'
      },
      {
        id: 'submarino',
        text: 'Submarino'
      },
      {
        id: 'credz',
        text: 'Credz'
      },
      {
        id: 'pernambucanas',
        text: 'Pernambucanas'
      },
      {
        id: 'paqueta',
        text: 'Paqueta'
      },
      {
        id: 'mais',
        text: 'Mais'
      },
      {
        id: 'carnet',
        text: 'Carnet'
      },
      {
        id: 'cassol',
        text: 'Cassol'
      }
    ]
  },
  {
    name: 'charge.filter.paid-at',
    type: 'date',
    name_query: 'start_paid_at'
  },
  {
    name: 'charge.filter.funding-source.title',
    name_query: 'funding_source',
    type: 'select',
    select_options: [
      {
        id: 'credit',
        text: 'charge.filter.funding-source.credit'
      },
      {
        id: 'debit',
        text: 'charge.filter.funding-source.debit'
      },
      {
        id: 'prepaid',
        text: 'charge.filter.funding-source.prepaid'
      }
    ]
  },
  {
    name: 'charge.filter.platforms',
    name_query: 'platforms',
    type: 'select',
    select_options: [
      {
        id: 'Vtex',
        text: 'charge.filter.vtex'
      },
      {
        id: 'Shopify',
        text: 'charge.filter.shopify'
      },
      {
        id: 'NuvemShop',
        text: 'charge.filter.nuvemshop'
      },
      {
        id: 'Oracle',
        text: 'charge.filter.oracle'
      },
      {
        id: 'Magento2',
        text: 'charge.filter.magento2'
      },
      {
        id: 'Magento',
        text: 'charge.filter.magento'
      },
      {
        id: 'Shop',
        text: 'charge.filter.venda-digitada'
      }
    ]
  },
  {
    name: 'charge.filter.status',
    name_query: 'status',
    type: 'select',
    select_options: [
      {
        id: 'canceled',
        text: 'charge.filter.canceled'
      },
      {
        id: 'failed',
        text: 'charge.filter.failed'
      },
      {
        id: 'paid',
        text: 'charge.filter.paid'
      },
      {
        id: 'pending',
        text: 'charge.filter.pending'
      },
      {
        id: 'processing',
        text: 'charge.filter.processing'
      },
      {
        id: 'overpaid',
        text: 'charge.filter.overpaid'
      },
      {
        id: 'underpaid',
        text: 'charge.filter.underpaid'
      },
      CHARGES_FILTER_CHARGEDBACK
    ]
  },
  {
    name: 'charge.filter.status-anti-fraud',
    name_query: 'antifraud_status',
    type: 'select',
    select_options: [
      {
        id: 'approved',
        text: 'charge.filter.approved'
      },
      {
        id: 'pending_analysis',
        text: 'charge.filter.pending-analysis'
      },
      {
        id: 'reproved',
        text: 'charge.filter.reproved'
      }
    ]
  },
  {
    name: 'charge.filter.transaction-status',
    name_query: 'transaction_status',
    type: 'select',
    select_options: [
      {
        id: 'waiting_cancellation',
        text: 'charge.filter.waiting-cancellation'
      },
      {
        id: 'waiting_capture',
        text: 'charge.filter.waiting-capture'
      },
      {
        id: 'pending_refund',
        text: 'charge.filter.pending-refund'
      },
      {
        id: 'waiting_payment',
        text: 'charge.filter.waiting-payment'
      },
      {
        id: 'authorized_pending_capture',
        text: 'charge.filter.authorized-pending-capture'
      },
      {
        id: 'generated',
        text: 'charge.filter.boleto-generated'
      },
      {
        id: 'viewed',
        text: 'charge.filter.boleto-viewed'
      },
      {
        id: 'created',
        text: 'charge.filter.created'
      },
      {
        id: 'captured',
        text: 'charge.filter.captured'
      },
      {
        id: 'voided',
        text: 'charge.filter.canceled'
      },
      {
        id: 'partial_capture',
        text: 'charge.filter.partial-capture'
      },
      {
        id: 'pending_credit_card',
        text: 'charge.filter.pending-credit-card'
      },
      {
        id: 'with_error',
        text: 'charge.filter.with-error'
      },
      {
        id: 'error_on_voiding',
        text: 'charge.filter.error-on-voiding'
      },
      {
        id: 'error_on_refunding',
        text: 'charge.filter.error-on-refunding'
      },
      {
        id: 'refunded',
        text: 'charge.filter.refunded'
      },
      {
        id: 'failed',
        text: 'charge.filter.failed'
      },
      {
        id: 'not_authorized',
        text: 'charge.filter.not-authorized'
      },
      {
        id: 'not_paid',
        text: 'charge.filter.not-paid'
      },
      {
        id: 'paid',
        text: 'charge.filter.paid'
      },
      {
        id: 'overpaid',
        text: 'charge.filter.overpaid'
      },
      {
        id: 'underpaid',
        text: 'charge.filter.underpaid'
      },
      {
        id: 'partial_refunded',
        text: 'charge.filter.partial-refunded'
      },
      {
        id: 'partial_void',
        text: 'charge.filter.partial-void'
      },
      {
        id: 'pending',
        text: 'charge.filter.pending'
      },
      {
        id: 'pending_authentication',
        text: 'charge.filter.pending-authentication'
      }
    ]
  },
  {
    name: 'charge.filter.amount',
    type: 'currency',
    name_query: 'min_amount'
  }
]
export default filters
