import endpoints from '../endpoints'

export const isNull = element => element === null

export const formatDate = (locale, date) => {
  if (date && date.length === 10) {
    const [year, month, day] = date.split('-')
    if (locale === 'en-US') {
      return `${month}/${day}/${year}`
    }
    return `${day}/${month}/${year}`
  }
  return date
}

export const formatToInputHours = unFormateDate => {
  const newUnFormateDate = new Date(unFormateDate)

  return `${
    newUnFormateDate.getHours() <= 9 ? '0' : ''
  }${newUnFormateDate.getHours()}:00`
}

export const formatToInputDate = (locale, unFormateDate) => {
  const newUnFormateDate = new Date(unFormateDate)

  if (locale === 'en-US') {
    return `${
      newUnFormateDate.getMonth() >= 9
        ? newUnFormateDate.getMonth() + 1
        : '0' + (newUnFormateDate.getMonth() + 1)
    }/${
      newUnFormateDate.getDate() > 9
        ? newUnFormateDate.getDate()
        : '0' + newUnFormateDate.getDate()
    }`
  } else {
    return `${
      newUnFormateDate.getDate() > 9
        ? newUnFormateDate.getDate()
        : '0' + newUnFormateDate.getDate()
    }/${
      newUnFormateDate.getMonth() >= 9
        ? newUnFormateDate.getMonth() + 1
        : '0' + (newUnFormateDate.getMonth() + 1)
    }`
  }
}

export const formatToDateTime = (locale, unFormateDate) => {
  return `${formatToInputDate(locale, unFormateDate)} ${formatToInputHours(
    unFormateDate
  )}`
}

export const createSinceDate = date => {
  const start = new Date(`${date}T00:00:00Z`)
  return start.toISOString()
}

export const createUntilDate = date => {
  const end = new Date(`${date}T23:59:59Z`)
  return end.toISOString()
}

export const cannyUrl = (merchantId, token) => {
  return endpoints.CANNY_URL.url({ merchantId, token })
}

export const cannyInit = (Canny, email, id, name) => {
  Canny('identify', {
    appID: '624730202f3c47093881d9c6',
    labelIDs: ['624752f209c12a5280e7dac6'],
    user: {
      email: email,
      id: id,
      name: name
    }
  })
}
