import { DashInstance, ProfileInstance, catchError } from '@/store'
import i18n from '@/i18n'
import { removeAllUnderscores } from '../../utils/removeAllUnderscores'
import { BOLETO_STONE_MERCHANTS, CHARGEBACK_COMPANIES } from '../../enums/features-flag.data'
import { isOwnerOrAdmin } from '@/enums/merchant-type.enum'
import { isPsP } from '@/enums/business-model.enum'

// import { Store } from 'vuex'
const curryUpdate = merchantId => accountId => handler => body =>
  handler(merchantId, accountId, body)

const account = {
  state: {
    prodAccounts: { items: [], pagination: [] },
    loadingListProdAccount: true,
    testAccounts: { items: [], pagination: [] },
    statusAccount: null,
    passwordConfirmed: false,
    settings: {
      antifraud_settings: {},
      statusAccount: {},
      account: {},
      mundipagg_settings: {},
      pagarme_settings: {},
      general_settings: {},
      domains: [],
      notification_settings: {},
      guaranteed_cancellation_settings: {},
      checkout_settings: {},
      split_settings: {},
      wallet_settings: {},
      order_settings: {},
      address: {},
      segment: {},
      integration: {},
      status: ''
    },
    unlockedBoletoMerchants: BOLETO_STONE_MERCHANTS,
    unlockedChargebackCompanies: CHARGEBACK_COMPANIES,
    attemptSettings: {},
    accountLoading: false,
    attemptLoading: false,
    businessModel: '',
    onboardingStatus: false,
    affiliations: {},
    userMfa: ''
  },
  mutations: {
    setAntifraud (state, payload) {
      state.settings.antifraud_settings = payload
    },
    setUserMfa (state, payload) {
      state.userMfa = payload
    },
    setPasswordConfirmed (state, payload) {
      state.passwordConfirmed = payload
    },
    SET_ACCOUNT (state, settings) {
      state.settings = settings
    },
    SET_STATUS_ACCOUNT (state, status) {
      state.statusAccount = status
    },
    SET_PROD_ACCOUNTS (state, accounts) {
      state.prodAccounts = accounts
    },
    SET_LOADING_LIST_PROD_ACCOUNT (state, payload) {
      state.loadingListProdAccount = payload
    },
    SET_TEST_ACCOUNTS (state, accounts) {
      state.testAccounts = accounts
    },
    SET_ACCOUNT_LOADING (state, isLoading) {
      state.accountLoading = isLoading
    },
    SET_ATTEMPT_SETTINGS (state, attempt) {
      state.attemptSettings = attempt
    },
    SET_ATTEMPT_LOADING (state, isLoading) {
      state.attemptLoading = isLoading
    },
    SET_BUSINESS_MODEL (state, businessModel) {
      state.businessModel = businessModel
    },
    SET_ONBOARDING_STATUS (state, onboardingStatus) {
      state.onboardingStatus = onboardingStatus
    },
    SET_AFFILIATIONS (state, affiliations) {
      state.affiliations = affiliations
    }
  },
  getters: {
    antifraudSettings (state) {
      return state.settings.antifraud_settings
    },
    settings (state) {
      return state.settings
    },
    statusAccount (state) {
      return state.statusAccount
    },
    unlockedBoleto (state) {
      return state.unlockedBoletoMerchants
    },
    unlockedChargeback (state) {
      return state.unlockedChargebackCompanies
    },
    prodAccounts (state) {
      return state.prodAccounts
    },
    loadingListProdAccount (state) {
      return state.loadingListProdAccount
    },
    testAccounts (state) {
      return state.testAccounts
    },
    hasGatewayPagarme (state) {
      return !!(
        state.settings &&
        state.settings.pagarme_settings &&
        state.settings.pagarme_settings.api_key
      )
    },
    gatewaySettings (state) {
      return {
        mundipagg: state.settings.mundipagg_settings,
        pagarme: state.settings.pagarme_settings
      }
    },
    paymentMethods (state) {
      return {
        pix: state.settings.pix_settings,
        creditCard: state.settings.credit_card_settings,
        debitCard: state.settings.debit_card_settings,
        bankTransfer: state.settings.bank_transfer_settings,
        boleto: state.settings.boleto_settings,
        voucher: state.settings.voucher_settings,
        cash: state.settings.cash_settings,
        safetyPay: state.settings.safetypay_settings
      }
    },
    accountSettings (state) {
      return {
        statusAccount: {
          status: state.settings.status.toLowerCase()
        },
        account: {
          allowed_ips: state.settings.allowed_ips,
          time_zone: state.settings.time_zone,
          name: state.settings.name,
          domains: state.settings.domains,
          default_currency: state.settings.default_currency,
          document: state.settings.document,
          corporate_name: state.settings.corporate_name,
          address: state.settings.address,
          segment: state.settings.segment,
          integration: state.settings.integration
        },
        general: state.settings.general_settings,
        wallet: state.settings.wallet_settings
      }
    },
    functionalitiesSettings (state) {
      return {
        notifications: state.settings.notification_settings,
        cancellation: state.settings.guaranteed_cancellation_settings,
        checkout: state.settings.checkout_settings,
        split: state.settings.split_settings,
        manualFlow: state.settings.antifraud_settings
      }
    },
    recurrenceSettings (state) {
      return {
        cardUpdater: state.settings.renew_card_settings || {
          manual_renew_enabled: false,
          renew_on_payment_enabled: false
        },
        subscriptions: state.settings.subscription_settings,
        forceCvv: state.settings.force_cvv_settings
      }
    },
    orderSettings (state) {
      return {
        order: state.settings.order_settings
      }
    },
    attemptSettings (state) {
      return state.attemptSettings.items
    },
    attemptLoading (state) {
      return state.attemptLoading
    },
    accountLoading (state) {
      return state.accountLoading
    },
    partialUpdate (state, getters) {
      return curryUpdate(getters.merchantId)(getters.accountId)
    },
    businessModel (state) {
      return state.businessModel?.toLowerCase()
    },
    showAccountConfig (state, getters) {
      return (
        getters.permissionIsEnabled('settings.account.data:view') ||
        getters.permissionIsEnabled('settings.account.general_data:view') ||
        getters.permissionIsEnabled('settings.account.wallet:view')
      )
    },
    showGatewaysConfig (state, getters) {
      return (
        getters.permissionIsEnabled('settings.gateways.mundipagg:view') ||
        getters.permissionIsEnabled('settings.gateways.pagarme:view')
      )
    },
    showPaymentsConfig (state, getters) {
      return (
        getters.permissionIsEnabled('settings.payments.pix:view') ||
        getters.permissionIsEnabled('settings.payments.credit_card:view') ||
        getters.permissionIsEnabled('settings.payments.debit_card:view') ||
        getters.permissionIsEnabled('settings.payments.boleto:view') ||
        getters.permissionIsEnabled('settings.payments.bank_transfer:view') ||
        getters.permissionIsEnabled('settings.payments.voucher:view') ||
        getters.permissionIsEnabled('settings.payments.cash:view') ||
        getters.permissionIsEnabled('settings.payments.safety_pay:view')
      )
    },
    showFunctionalitiesConfig (state, getters) {
      return (
        getters.permissionIsEnabled('settings.notifications:view') ||
        getters.permissionIsEnabled('settings.guaranteed_cancelation:view') ||
        getters.permissionIsEnabled('settings.checkout:view') ||
        getters.permissionIsEnabled('settings.split:view')
      )
    },
    showRecurrenceConfig (state, getters) {
      return (
        getters.permissionIsEnabled('settings.subscriptions:view') ||
        getters.permissionIsEnabled('settings.attempts:search') ||
        getters.permissionIsEnabled('settings.card_updater:view')
      )
    },
    showOrdersConfig (state, getters) {
      return (
        getters.permissionIsEnabled('settings.charge_attempt:view') ||
        getters.permissionIsEnabled('settings.orders:view')
      )
    },
    showWebhooksConfig (state, getters) {
      return (
        getters.permissionIsEnabled('settings.webhooks:view') &&
        getters.componentIsEnabled('sidemenu', 'webhookConfig')
      )
    },
    showFees (state, getters) {
      return (
        getters.permissionIsEnabled('settings.account.fees:view') &&
        ['p_s_p', 'gateway_and_psp'].includes(getters.businessModel) &&
        getters.account.account_type.value === 'production'
      )
    },
    showSettingsSection (state, getters) {
      return (
        ((getters.showAccountConfig ||
          getters.showGatewaysConfig ||
          getters.showPaymentsConfig ||
          getters.showFunctionalitiesConfig ||
          getters.showRecurrenceConfig ||
          getters.showOrdersConfig) &&
          getters.componentIsEnabled('sidemenu', 'settings') &&
          getters.permissionIsEnabled('settings.account:get')) ||
        (getters.showWebhooksConfig &&
          getters.componentIsEnabled('sidemenu', 'settings'))
      )
    },
    onboardingStatus (state) {
      return state.onboardingStatus
    },
    affiliations (state) {
      return state.affiliations
    },
    passwordConfirmed (state) {
      return state.passwordConfirmed
    }
  },
  actions: {
    CREATE_TEST_ACCOUNT (context, params = {}) {
      return DashInstance.account
        .create(params.merchantId, null, params.body)
        .then(res => {
          context.commit('SET_TEST_ACCOUNTS', { items: [], pagination: [] })
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    },
    CREATE_PIX_KEY (context, params = {}) {
      return DashInstance.account
        .createPixKey(params.merchantId, params.accountId, params.body)
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    DELETE_PIX_KEY (context, params = {}) {
      return DashInstance.account
        .deletePixKey(params.merchantId, params.accountId, params.body)
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    GET_PROD_ACCOUNTS (context, params = {}) {
      params.query.size = 5
      params.query.page = params.query.page || 1
      return ProfileInstance.account
        .getAll(params.merchantId, params.query)
        .then(res => {
          context.commit('SET_PROD_ACCOUNTS', res.data)
          context.commit('SET_LOADING_LIST_PROD_ACCOUNT', false)
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    },
    GET_TEST_ACCOUNTS (context, params = {}) {
      params.query.type = 'test'
      params.query.size = 5
      params.query.page = params.query.page || 1
      return ProfileInstance.account
        .getAll(params.merchantId, params.query)
        .then(res => {
          context.commit('toggleLocalLoading', false)
          context.commit('SET_TEST_ACCOUNTS', res.data)
          return Promise.resolve(res)
        })
        .catch(err => {
          context.commit('toggleLocalLoading', false)
          catchError(err, context)
        })
    },
    GET_ACCOUNT (context, params = {}) {
      if (context.state.settings.id !== params.accountId || params.refresh) {
        context.commit('SET_ACCOUNT_LOADING', true)
        return DashInstance.account
          .getById(params.merchantId, params.accountId)
          .then(res => {
            context.commit('SET_ACCOUNT', res)
            context.commit('SET_ACCOUNT_LOADING', false)
            return Promise.resolve(res)
          })
          .catch(err => {
            context.commit('SET_ACCOUNT_LOADING', false)
            return catchError(err, context)
          })
      } else {
        return Promise.resolve(context.state.settings)
      }
    },
    GET_ATTEMPT_SETTINGS (context, params = {}) {
      if (
        context.state.settings.id !== params.accountId ||
        !Object.entries(context.state.attemptSettings).length
      ) {
        context.commit('SET_ATTEMPT_LOADING', true)
        return DashInstance.account
          .getAttemptSettings(params.merchantId, params.accountId)
          .then(res => {
            context.commit('SET_ATTEMPT_SETTINGS', res)
            context.commit('SET_ATTEMPT_LOADING', false)
            return Promise.resolve(res)
          })
          .catch(err => {
            context.commit('SET_ATTEMPT_LOADING', false)
            return catchError(err, context)
          })
      } else {
        return Promise.resolve(context.state.attemptSettings)
      }
    },
    GET_AFFILIATIONS (context, params = {}) {
      return DashInstance.account
        .getAffiliations(params.merchantId, params.accountId, params.isStone)
        .catch(err => catchError(err, context))
    },
    GET_BOLETO_STONE_CONSENTS (context, params = {}) {
      return DashInstance.account.getBoletoStoneConsents(
        params.merchantId,
        params.accountId
      )
    },
    GET_BOLETO_STONE_CONSENTS_STATUS (context, params = {}) {
      return DashInstance.account
        .getBoletoStoneConsentStatus(params.merchantId, params.accountId)
        .catch(err => catchError(err, context))
    },
    GET_FEES (context, params = {}) {
      return DashInstance.account
        .getFees(params.merchantId, params.accountId)
        .catch(err => catchError(err, context))
    },
    GET_FEES_INSTALLMENTS (context, { params, body }) {
      return DashInstance.account
        .getFeesInstallments(params.merchantId, params.accountId, body)
        .catch(err => catchError(err, context))
    },
    VERIFY_SECRET_KEY (context, { params, body }) {
      return DashInstance.account
        .verifySecretKey(params.merchantId, params.accountId, body)
        .then(res => {
          context.commit('setPasswordConfirmed', true)
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    },
    CREATE_ATTEMPT_SETTINGS (context, params = {}) {
      return DashInstance.account
        .createAttemptSettings(params.merchantId, params.accountId, params.body)
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    SET_ONBOARDING_STATUS (context, merchant) {
      const accountStatusIsPending = merchant.account.status.key === 'pending'
      const businessModelIsPSP = isPsP(merchant.account.business_model)
      const onBoardingIsPending = merchant.account.onboarding
      const onboardingURL = process.env.VUE_APP_URL_ONBOARDING
      if (
        isOwnerOrAdmin(merchant.type.key) &&
        businessModelIsPSP &&
        onBoardingIsPending &&
        !accountStatusIsPending
      ) {
        window.location.href = `${onboardingURL}/#/${merchant.id}/${merchant.account.id}`
      } else if (
        businessModelIsPSP &&
        (accountStatusIsPending || onBoardingIsPending)
      ) {
        const params = { merchantId: merchant.id, query: {} }
        context.commit('toggleLocalLoading', true)
        context.dispatch('GET_TEST_ACCOUNTS', params)
        context.commit('SET_ONBOARDING_STATUS', merchant.account.onboarding)
      }
    },
    UPDATE_STATUS_ACCOUNT_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(DashInstance.account.updateStatus)(body)
      return response
        .then(res => {
          context.commit('SET_STATUS_ACCOUNT', body.status.toLowerCase())
          context.dispatch('GET_PROD_ACCOUNTS', {
            merchantId: context.getters.merchant.id,
            query: {}
          })
          Promise.resolve(res)
        })
        .catch(err => {
          context.dispatch('GET_PROD_ACCOUNTS', {
            merchantId: context.getters.merchant.id,
            query: {}
          })
          return catchError(err, context)
        })
    },
    UPDATE_ACCOUNT_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(DashInstance.account.updateSettings)(body)
      return response
        .then(res => {
          context.dispatch('GET_AUTHORIZATION', {
            merchant_id: context.getters.merchantId,
            account_id: context.getters.accountId
          })
          Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    },
    UPDATE_GENERAL_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateGeneralSettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_WALLET_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateWalletSettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_MUNDIPAGG_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateMundipaggSettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_PAGARME_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updatePagarmeSettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_NOTIFICATIONS_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateNotificationsSettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_CANCELLATION_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateGuaranteedCancellationSettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_CHECKOUT_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateCheckoutSettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_SPLIT_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(DashInstance.account.updateSplitSettings)(
        body
      )
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_RENEW_CARD_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateRenewCardSettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_PIX_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(DashInstance.account.updatePixSettings)(
        body
      )
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_CREDIT_CARD_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateCreditCardSettings
      )(body)
      return response
        .then(res => {
          context.dispatch('RESET_ACCOUNT_ENABLED')
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    },
    UPDATE_FORCE_CVV_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      const response = updateSettings(DashInstance.account.updateForceCvv)(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_SUBSCRIPTIONS_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateSubscriptionSettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_DEBIT_CARD_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateDebitCardSettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_BOLETO_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateBoletoSettings
      )(body)
      return response
        .then(res => {
          context.dispatch('RESET_ACCOUNT_ENABLED')
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    },
    UPDATE_BANK_TRANSFER_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateBankTransferSettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_VOUCHER_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateVoucherSettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_CASH_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(DashInstance.account.updateCashSettings)(
        body
      )
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_SAFETY_PAY_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(
        DashInstance.account.updateSafetypaySettings
      )(body)
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_ORDER_SETTINGS (context, body) {
      const updateSettings = context.getters.partialUpdate
      body.is_test_account =
        context.rootGetters.account.account_type.value === 'test'
      const response = updateSettings(DashInstance.account.updateOrderSettings)(
        body
      )
      return response
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_ATTEMPT_SETTINGS (context, params = {}) {
      return DashInstance.account
        .updateAttemptSettings(
          params.merchantId,
          params.accountId,
          params.attemptId,
          params.body
        )
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_LOADING_LIST_PROD_ACCOUNT (context, payload) {
      context.commit('SET_LOADING_LIST_PROD_ACCOUNT', payload)
    },
    UPDATE_AFFILIATIONS (context, params = {}) {
      return DashInstance.account
        .updateAffliations(params.merchantId, params.accountId, params.body)
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_BUSINESS_MODEL (context, { business_model, password }) {
      return DashInstance.account
        .updateBusinessModel(
          context.getters.merchantId,
          context.getters.accountId,
          {
            business_model: removeAllUnderscores(business_model),
            password
          }
        )
        .then(res => {
          context.commit('SET_BUSINESS_MODEL', business_model)
          Promise.resolve(res)
        })
        .catch(err => {
          err.body = { message: [i18n.t('account.settings.gateways.error')] }
          return catchError(err, context)
        })
    },
    FETCH_USER_MFA (context) {
      return DashInstance.user
        .getUserMfa()
        .then(res => {
          context.commit('setUserMfa', res.data.type)
          return Promise.resolve(res)
        })
        .catch(err => {
          err.body = { message: [i18n.t('invite.create.error-internal')] }
          return catchError(err, context)
        })
    },
    CREATE_AUTHORIZATION (context, params) {
      if (params) {
        return DashInstance.user
          .createAuthorization(context.profile.user.email, params.body)
          .then(res => {
            Promise.resolve(res)
          })
          .catch(err => {
            err.body = { message: [i18n.t('invite.create.error-internal')] }
            return catchError(err, context)
          })
      } else {
        return DashInstance.user
          .sendAuthorization(context.profile.user.email)
          .then(res => {
            Promise.resolve(res)
          })
          .catch(err => {
            err.body = { message: [i18n.t('invite.create.error-internal')] }
            return catchError(err, context)
          })
      }
    },
    RESET_SECRET_KEY (context, { params, body }) {
      return DashInstance.account
        .resetSecretKey(params.merchantId, params.accountId, body)
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    UPDATE_ANTIFRAUD_SETTINGS (context, { manualFlow, password }) {
      const body = {
        manual_Flow: manualFlow.manual_flow,
        password,
        is_test_account:
          context.rootGetters.account.account_type.value === 'test'
      }
      return DashInstance.account
        .updateManualFlow(
          context.getters.merchantId,
          context.getters.accountId,
          body
        )
        .then(res => {
          context.commit('setAntifraud', manualFlow)
          Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    }
  }
}

export default account
