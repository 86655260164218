<template>
  <table class="emd-table">
    <thead>
      <tr>
        <th
          class="emd-text"
          v-for="(field, index) in fields"
          :key="index"
        >
          {{ $t(field.label) }}
          <emd-tippy
            v-if="field.info"
            :data-template="field.id"
            :id="field.id"
            class="eu-ml_1"
            :type="'html'"
            position="bottom"
          >
            <i
              :class="'emd-text _color_subtle'"
              class="uil uil-info-circle"
            />
            <emd-tippy-template-HTML :id="field.id">
              <slot />
            </emd-tippy-template-HTML>
          </emd-tippy>
        </th>
        <th v-if="toDetail"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, itemIndex) in items"
        :key="itemIndex"
      >
        <td
          v-for="(field, index) in fields"
          :key="index"
          :data-label="$t(field.label)"
        >
          {{ field.styles }}
          <span v-if="field.type === 'link'">
            <router-link
              :data-testid="`link-show-${itemIndex}`"
              :to="{
                name: field.link.to,
                params: {
                  id: getField(item, field.link.params.id),
                  tab: field.link.params.tab
                },
                query: getQuery(item, field.link.query)
              }"
              class="emd-link"
              >{{ getField(item, field.id) || '-' }}</router-link
            >
          </span>

          <span
            v-if="field.type === 'url'"
            class="url-column"
          >
            <a
              class="url-color"
              target="_blank"
              rel="noopener noreferrer"
              :href="$sanitize(item.url)"
              :data-testid="`url-show-${itemIndex}`"
            >
              <span
                v-if="copiedValue"
                class="url-copied"
              >
                {{ $t('table.copied', [computedValueCopied]) }}
              </span>
              <span v-else-if="field.text"> {{ field.text }} </span>
              <span v-else> {{ getField(item, field.id) || '-' }} </span>
            </a>
            <button
              v-if="field.showCopy"
              class="emd-action-button"
              @click="handleCopy(getField(item, field.id))"
            >
              <emerald-icon
                icon="copy"
                data-testid="button-copy"
              />
            </button>
          </span>

          <span
            v-if="field.type === 'text'"
            :data-testid="`text-show-${itemIndex}`"
          >
            {{ transformText(item, field) }}
          </span>

          <span
            v-if="field.type === 'conditional'"
            :data-testid="`text-show-${itemIndex}`"
            >{{ getConditionalField(item, field.id) || '-' }}</span
          >

          <span
            v-if="field.type === 'status'"
            class="emd-badge"
            :data-testid="`status-badge-${itemIndex}`"
            v-bind:class="statusClass(getField(item, field.id))"
            >{{ transformText(item, field) }}</span
          >

          <span
            v-if="field.type === 'interval'"
            :data-testid="`interval-show-${itemIndex}`"
            >{{ $t('plan.list.each') }} {{ item.interval_count }}
            {{ pluralIntervals(item.interval_count, item.interval) }}</span
          >

          <span
            v-if="field.type === 'date'"
            :data-testid="`date-show-${itemIndex}`"
          >
            {{ getField(item, field.id) | dateFormat(field.format) }}
          </span>
        </td>
        <td
          class="eu-align_right"
          v-if="toDetail"
        >
          <router-link
            :to="{
              name: toDetail.name,
              params: {
                id: item[toDetail.params.id],
                tab: toDetail.params.tab
              },
              query: getQuery(item, toDetail.query)
            }"
            :data-testid="`detail-show-${itemIndex}`"
            class="emd-btn-ghost"
          >
            <i class="uil uil-arrow-to-right" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { EmdTippy, EmdTippyTemplateHTML } from 'emerald-vue/src/components'
import EmeraldIcon from '@/components/emerald/atoms/EmeraldIcon'
import { statusClass, pluralIntervals } from '@/filters'

export default {
  name: 'EmdTable',
  components: {
    EmdTippy,
    EmdTippyTemplateHTML,
    EmeraldIcon
  },

  props: ['fields', 'items', 'toDetail'],

  data () {
    return {
      statusClass: statusClass,
      pluralIntervals: pluralIntervals,
      copiedValue: undefined
    }
  },

  computed: {
    computedValueCopied () {
      if (!this.copiedValue) return undefined
      const pos = this.copiedValue.search('pl_')
      return this.copiedValue.slice(pos, pos + 12)
    }
  },

  methods: {
    getField (item, field) {
      if (field.includes('.')) {
        const fieldArray = field.split('.')
        let value
        for (let i = 0; i < fieldArray.length; i++) {
          if (value) {
            value = value[fieldArray[i]]
          } else {
            value = item[fieldArray[i]]
          }
        }
        return value
      }
      return item[field]
    },
    getConditionalField (item, field) {
      const options = field.split(';')
      return this.getField(item, options[0]) || this.getField(item, options[1])
    },
    getQuery (item, fields) {
      if (!fields) return {}
      const obj = {}
      fields.forEach(field => {
        obj[field] = this.getField(item, field)
      })
      return obj
    },
    transformText (item, field) {
      return field.transform
        ? field.transform(this.getField(item, field.id), item)
        : this.getField(item, field.id) || '-'
    },
    handleCopy (value) {
      this.$copyText(value)
        .then(() => {
          this.copiedValue = value
        })
        .finally(() => {
          setTimeout(() => (this.copiedValue = undefined), 3000)
        })
    }
  }
}
</script>

<i18n>
  {
    "pt-BR": {
      "table": {
        "copied": "Copiado: {0}"
      }
    },
    "en-US": {
      "table": {
        "copied": "Copied: {0}"
      }
    }
  }
</i18n>

<style scoped>
.url-column {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.url-copied {
  color: var(--brand--le--primary-color--base, #65a300);
}

.url-color {
  color: var(--primrias-purple-100, #6045af);
}
</style>
