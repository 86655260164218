import Users from '@/views/Users/Users.vue'
import store from '@/store'
import Logs from '@/views/Account/Logs.vue'

const routeUsers = {
  path: 'users',
  name: 'users',
  component: Users,
  beforeEnter: (to, from, next) => {
    if (store.getters.permissionIsEnabled('account_staff:search')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.users'
  }
}
const routeUsersDetail = {
  path: 'users/:id',
  name: 'usersDetail',
  component: () =>
    import(
      /* webpackChunkName: "usersdetail" */ '@/views/Users/UsersDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (store.getters.permissionIsEnabled('account_staff:get')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.user-detail'
  }
}
const routeInvites = {
  path: 'invites',
  name: 'invites',
  component: () =>
    import(
      /* webpackChunkName: "invites" */ '@/views/Users/Invites/Invites.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (store.getters.permissionIsEnabled('account_staff.invite:search')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.invites'
  }
}
const routeInvitesCreate = {
  path: 'invites/create',
  name: 'invites-create',
  component: () =>
    import(
      /* webpackChunkName: "invitescreate" */ '@/views/Users/Invites/InvitesCreate.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (store.getters.permissionIsEnabled('account_staff.invite:create')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.invite-create'
  }
}
const routeInvitesDetail = {
  path: 'invites/:id',
  name: 'invitesDetail',
  component: () =>
    import(
      /* webpackChunkName: "invitesdetail" */ '@/views/Users/Invites/InvitesDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (store.getters.permissionIsEnabled('account_staff.invite:get')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.invite-detail'
  }
}
const routeLogs = {
  path: 'logs',
  name: 'logs',
  component: Logs,
  beforeEnter: (to, from, next) => {
    if (store.getters.permissionIsEnabled('action_log:search')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.logs'
  }
}
export {
  routeUsers,
  routeUsersDetail,
  routeInvites,
  routeInvitesCreate,
  routeInvitesDetail,
  routeLogs
}
