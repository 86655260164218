import Plans from '@/views/Recurrences/Plans/Plans.vue'
import Subscriptions from '@/views/Recurrences/Subscriptions/Subscriptions.vue'
import Invoices from '@/views/Recurrences/Invoices/Invoices.vue'
import store from '@/store'

const routePlans = {
  path: 'plans/',
  name: 'plans',
  component: Plans,
  beforeEnter: (to, from, next) => {
    if (
      store.getters.permissionIsEnabled('plan:search') &&
      store.getters.componentIsEnabled('sidemenu', 'plans')
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.plans'
  }
}
const routePlansDetail = {
  path: 'plans/:id/:tab',
  name: 'plansDetail',
  component: () =>
    import(
      /* webpackChunkName: "plansdetail" */ '@/views/Recurrences/Plans/PlansDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      store.getters.permissionIsEnabled('plan:get') &&
      store.getters.componentIsEnabled('sidemenu', 'plans')
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.plan-detail'
  }
}
const routeSubscriptions = {
  path: 'subscriptions/',
  name: 'subscriptions',
  component: Subscriptions,
  beforeEnter: (to, from, next) => {
    if (
      store.getters.permissionIsEnabled('subscription:search') &&
      store.getters.componentIsEnabled('sidemenu', 'subscriptions')
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.subscriptions'
  }
}
const routeSubscriptionsDetail = {
  path: 'subscriptions/:id/:tab',
  name: 'subscriptionsDetail',
  component: () =>
    import(
      /* webpackChunkName: "subscriptionsdetail" */ '@/views/Recurrences/Subscriptions/SubscriptionsDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      store.getters.permissionIsEnabled('subscription:get') &&
      store.getters.componentIsEnabled('sidemenu', 'subscriptions')
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.subscription-detail'
  }
}
const routeInvoices = {
  path: 'invoices/',
  name: 'invoices',
  component: Invoices,
  beforeEnter: (to, from, next) => {
    if (
      store.getters.permissionIsEnabled('invoice:search') &&
      store.getters.componentIsEnabled('sidemenu', 'invoices')
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.invoices'
  }
}
const routeInvoicesDetail = {
  path: 'invoices/:id',
  name: 'invoicesDetail',
  component: () =>
    import(
      /* webpackChunkName: "invoicesdetail" */ '@/views/Recurrences/Invoices/InvoicesDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      store.getters.permissionIsEnabled('invoice:get') &&
      store.getters.componentIsEnabled('sidemenu', 'invoices')
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.invoice-detail'
  }
}

export {
  routePlans,
  routePlansDetail,
  routeSubscriptions,
  routeSubscriptionsDetail,
  routeInvoices,
  routeInvoicesDetail
}
