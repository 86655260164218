export const SHOP_ACTIONS_ENUM = {
  recurrence: 'shop.recurrence:create',
  checkout: 'shop.checkout:create',
  account: 'shop.account:get'
}

const SHOP_ACTIONS = [
  SHOP_ACTIONS_ENUM.recurrence,
  SHOP_ACTIONS_ENUM.checkout,
  SHOP_ACTIONS_ENUM.account
]

const shopEnabled = (
  permissionIsEnabledFn,
  componentIsEnabledFn,
  shopIsEnabled
) => {
  return (
    componentIsEnabledFn('apps', 'shop') &&
    SHOP_ACTIONS.some(permissionIsEnabledFn) &&
    shopIsEnabled
  )
}

export default shopEnabled
