<template>
  <div
    class="eu-ml_3"
    v-if="report && report.enabled"
  >
    <emd-tippy
      id="report"
      :type="'action-button'"
      :content="$t('export-report')"
    >
      <button
        class="emd-action-button _lvl_2 _square _size_small _has-tooltip"
        :class="{
          _disabled: totalListItems === 0 || report.loading || loadingList
        }"
        @click="sendReportFile"
      >
        <span
          data-testid="btn_export_orders"
          class="emd-text _color_default emd-action-button__icon"
        >
          <emd-loader
            :type="'_invert'"
            v-if="report.loading"
          ></emd-loader>
          <i
            class="uil uil-arrow-to-bottom"
            v-else
          />
        </span>
      </button>
    </emd-tippy>
  </div>
</template>
<script>
import { EmdLoader, EmdTippy } from 'emerald-vue/src/components'
export default {
  name: 'EmdReportButton',
  components: {
    EmdLoader,
    EmdTippy
  },
  props: {
    loadingList: {
      type: Boolean,
      default: false
    },
    report: {
      type: Object,
      required: false
    },
    totalListItems: {
      type: Number,
      required: true
    }
  },
  computed: {
    merchantId () {
      return this.$store.getters.merchantId
    },
    accountId () {
      return this.$store.getters.accountId
    }
  },
  methods: {
    formatQueryToReport () {
      const queryObject = Object.assign({}, this.$route.query)
      this.report.queryArray.map(value => {
        if (value in queryObject) {
          queryObject[value] = Array.isArray(queryObject[value])
            ? queryObject[value]
            : [queryObject[value]]
        }
      })
      return queryObject
    },
    sendReportFile () {
      if (this.totalListItems === 0 || this.report.loading || this.loadingList)
        return
      this.report.limitExceeded = false
      this.report.loading = true
      if (this.totalListItems > 150000) {
        this.report.loading = false
        this.report.sent = false
        this.report.showAlert = true
        this.report.limitExceeded = true
        return
      }
      const body = this.formatQueryToReport()
      this.$store
        .dispatch(this.report.action, {
          merchantId: this.merchantId,
          accountId: this.accountId,
          body: body
        })
        .then(() => {
          this.report.loading = false
          this.report.sent = true
          this.report.showAlert = true
        })
        .catch(() => {
          this.report.loading = false
          this.report.sent = false
          this.report.showAlert = true
        })
    }
  }
}
</script>
<i18n>
{
  "pt-BR": {
    "export-report": "Exportar relatório"
  },
  "en-US": {
    "export-report": "Export report"
  }
}
</i18n>
