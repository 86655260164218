<template>
  <div
    class="emd-modal _size_large _active"
    style="z-index: 10000"
  >
    <div class="emd-modal__content">
      <div class="emd-container _header_end _header_auto _footer_end">
        <div class="emd-container__header eu-justify_end_mobile">
          <button
            class="emd-btn _type_link"
            @click="closeModal"
          >
            <i class="uil uil-times" />
          </button>
        </div>
        <div class="emd-container__content eu-pa_4">
          <div
            id="onboarding-step-1"
            v-if="this.steps.header.show && this.steps.header.isActive"
          >
            <div class="eu-flex-item eu-align_center">
              <div class="eu-display_none eu-display_block_md">
                <img
                  class="eu-mb_4"
                  alt="header dash"
                  :src="new_header_dash"
                />
              </div>
              <h2 class="emd-text _size_section _type_hint _color_contrast">
                {{ $t('header.title') }}
              </h2>
              <div class="emd-text eu-mt_5">
                {{ $t('header.text') }}
              </div>
            </div>
          </div>

          <div
            id="onboarding-step-2"
            v-if="this.steps.home.show && this.steps.home.isActive"
          >
            <div class="eu-flex eu-column eu-row_md">
              <div class="eu-flex-item">
                <h2 class="emd-text _size_section _type_hint _color_contrast">
                  {{ $t('home.title') }}
                </h2>

                <p class="emd-text _color_default eu-my_4">
                  {{ $t('home.paragraph-1') }}
                </p>
                <p class="emd-text _color_default eu-my_4">
                  {{ $t('home.paragraph-2') }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="emd-container__footer">
          <button
            v-if="this.steps.header.show && this.steps.home.show"
            class="emd-btn _color_primary _type_text"
            :class="{ _disabled: showHeaderStep }"
            @click="previousStep"
          >
            {{ $t('previous') }}
          </button>

          <button
            v-if="showHeaderStep"
            class="emd-btn _color_primary"
            @click="nextStep"
          >
            {{ $t('next') }}
          </button>
          <button
            v-else
            class="emd-btn _color_primary"
            @click="closeModal"
          >
            ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalNewFilter',
  data: function () {
    return {
      new_header_dash: `${process.env.VUE_APP_CDN_URL}/assets/images/onboarding/new-header-dash.svg`,
      steps: {
        header: {
          isActive: true,
          show: false
        },
        home: {
          isActive: false,
          show: false
        }
      }
    }
  },
  computed: {
    onboarding () {
      return this.$store.getters.onboarding
    },
    isNewUser () {
      return this.$store.getters.isNewUser
    },
    summary () {
      return this.$store.getters.onboarding.summary
    },
    showHeaderStep () {
      return (
        (this.steps.header.show && this.steps.home.show) &
        this.steps.header.isActive
      )
    },
    isEmployee () {
      return this.$store.getters.isEmployee
    }
  },
  methods: {
    closeModal () {
      if (this.steps.home.show) {
        this.$store.dispatch('CLOSE_SUMMARY', {
          closed_modal: true,
          closed_tooltip_filter_date: this.summary.closed_tooltip_filter_date
        })
      }
      this.$store.dispatch('CLOSE_MODAL_HEADER')
    },
    nextStep () {
      this.steps.header.isActive = false
      this.steps.home.isActive = true
    },
    previousStep () {
      this.steps.header.isActive = true
      this.steps.home.isActive = false
    }
  },
  created () {
    this.steps.header.show =
      this.onboarding.show_modal_header &&
      this.$store.getters.componentIsEnabled('global', 'modalHeader')
    this.steps.home = {
      show: !this.onboarding.summary.closed_modal && !this.isEmployee,
      isActive: !this.onboarding.show_modal_header
    }
    if (this.isNewUser && !this.steps.header.show)
      this.$store.dispatch('CLOSE_MODAL_HEADER')
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "header": {
      "title": "<strong>O seu menu mudou!</strong>",
      "text": "<p class='eu-my_4'>Uma nova e mais simples versão está disponível</p><p class='eu-my_4'>Agora alguns itens do seu antigo menu também estão organizados na sessão superior do seu dashboard.</p><p class='eu-mt_4'>Comece a experimentar!<p>"
    },
    "home": {
      "title": "<strong>Acompanhe as estatísticas de vendas da sua conta!</strong>",
      "paragraph-1": "Agora no seu Dash você poderá acompanhar os principais dados do seu negócio.",
      "paragraph-2": "Visualize rapidamente a taxa de conversão dos pedidos, o TPV capturado e ticket médio. Veja os meios de pagamento mais utilizados. Compare a conversão por parcela e muito mais.",
      "try-on-now": "Experimente agora"
    },
    "next": "Próximo",
    "previous": "Anterior"
  },
  "en-US": {
    "header": {
      "title": "<strong>You menu has changed</strong>",
      "text": "<p class='eu-my_4'>A new and simpler version is now available</p><p class='eu-my_4'>Some items from your menu are now organized in the top section of your dashboard</p><p class='eu-mt_4'>Get started!<p>"
    },
    "home": {
      "title": "<strong>Start keeping track of your account’s sales statistics!</strong>",
      "paragraph-1": "Now through your dashboard you can monitor your sales data.",
      "paragraph-2": "Quickly view the conversion rate for your charges, captured TPV and average ticket. See which ones are your top payment methods, compare conversion by number of installments and more.",
      "try-on-now": "Try it now"
    },
    "next": "Next",
    "previous": "Previous"
  }
}
</i18n>
