import { DashInstance, catchError } from '@/store'

const recipients = {
  state: {
    recipients: { items: [], pagination: {} },
    loadingList: true,
    recipientDetail: '',
    hasLocked: false
  },
  getters: {
    recipients (state) {
      return state.recipients
    },
    hasLocked (state) {
      return state.hasLocked
    }
  },
  mutations: {
    toggleLoadingList (state, status) {
      state.loadingList = status
    },
    SET_RECIPIENTS (state, recipients) {
      state.recipients = recipients
    },
    SET_RECIPIENT_DETAIL (state, detail) {
      state.recipientDetail = detail
    },
    SET_LOCKED (state, locked) {
      state.hasLocked = locked
    }
  },
  actions: {
    UPDATE_RECIPIENT_GENERAL_SETTINGS (context, params) {
      return DashInstance.recipients
        .updateGeneralSettings(
          params.merchantId,
          params.accountId,
          params.recipientId,
          params.body
        )
        .then(res => {
          return Promise.resolve(res)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    UPDATE_RECIPIENT_DEFAULT_BANK_ACCOUNT (context, params) {
      return DashInstance.recipients
        .updateDefaultBankAccount(
          params.merchantId,
          params.accountId,
          params.recipientId,
          params.body
        )
        .then(res => {
          return Promise.resolve(res)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    UPDATE_RECIPIENT_ANTICIPATION_SETTINGS (context, params) {
      return DashInstance.recipients
        .updateAutomaticAnticipationSettings(
          params.merchantId,
          params.accountId,
          params.recipientId,
          params.body
        )
        .then(res => {
          return Promise.resolve(res)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    UPDATE_RECIPIENT_TRANSFER_SETTINGS (context, params) {
      return DashInstance.recipients
        .updateTransferSettings(
          params.merchantId,
          params.accountId,
          params.recipientId,
          params.body
        )
        .then(res => {
          return Promise.resolve(res)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_RECIPIENTS (context, params = {}) {
      context.commit('toggleLoadingList', true)
      return DashInstance.recipients
        .getAll(params.merchantId, params.accountId, params.query)
        .then(res => {
          context.commit('toggleLoadingList', false)
          context.commit('SET_RECIPIENTS', res)
          return Promise.resolve(res)
        })
        .catch(err => {
          if (err.status === 423) {
            context.commit('toggleLoadingList', false)
            context.commit('SET_LOCKED', true)
          } else {
            return catchError(err, context)
          }
        })
    },
    GET_RECIPIENT_DETAIL (context, data = {}) {
      return DashInstance.recipients
        .getById(data.merchantId, data.accountId, data.recipientId)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    CREATE_RECIPIENT (context, params = {}) {
      return DashInstance.recipients
        .create(params.query.merchant_id, params.query.account_id, params.body)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    }
  }
}

export default recipients
