import actionsPermissionIsEnabled from '.'

export const INSIGHTS_ACTIONS_ENUM = {
  view: 'insights:view'
}

const INSIGHTS_ACTIONS = [INSIGHTS_ACTIONS_ENUM.view]

const insightsMerchantEnabled = (
  permissionIsEnabledFn,
  componentIsEnabledFn
) => {
  return (
    componentIsEnabledFn('sidemenu', 'home') &&
    actionsPermissionIsEnabled(INSIGHTS_ACTIONS, permissionIsEnabledFn)
  )
}

export default insightsMerchantEnabled
