import { render, staticRenderFns } from "./EmptyListFilter.vue?vue&type=template&id=665ce948&"
import script from "./EmptyListFilter.vue?vue&type=script&lang=js&"
export * from "./EmptyListFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./EmptyListFilter.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports