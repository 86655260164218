import { catchError, CompanyInstance } from '@/store'

const merchant = {
  state: {
    merchantName: null,
    summary: null,
    isDelinquent: null
  },
  mutations: {
    SET_MERCHANT_NAME (state, merchantName) {
      state.merchantName = merchantName
    },
    SET_MERCHANT_SUMMARY (state, payload) {
      state.summary = payload
    },
    SET_MERCHANT_DELINQUENT (state, payload) {
      state.isDelinquent = payload
    }
  },
  getters: {
    merchantName (state) {
      return state.merchantName
    },
    merchantSummary (state) {
      return state.summary
    },
    merchantIsDelinquent (state) {
      return state.isDelinquent
    }
  },
  actions: {
    SET_MERCHANT_SUMMARY (context, value) {
      context.commit('SET_MERCHANT_SUMMARY', value)
    },
    GET_MERCHANT_SUMMARY ({ commit, dispatch }, merchantId) {
      return CompanyInstance.merchant
        .getSummary(merchantId, 'dash', process.env)
        .then(res => {
          commit('SET_MERCHANT_SUMMARY', res)
          commit('SET_MERCHANT_DELINQUENT', res.is_delinquent)
          dispatch('updateWhitelabel', res.whitelabel)
          if (res.whitelabel.enabled)
            dispatch('loadEmeraldByTheme', res.whitelabel.theme)
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, { commit, dispatch }))
    }
  }
}

export default merchant
