var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
        _vm.entity.logs_url &&
        Object.entries(_vm.entity.logs_url) &&
        _vm.isEmployee &&
        !_vm.isSection
      )?_c('hr',{staticClass:"emd-divider eu-bg_300 eu-my_4"}):_vm._e(),(
        _vm.entity.logs_url && Object.entries(_vm.entity.logs_url).length && _vm.isEmployee
      )?_c('div',{class:{ 'el-section': _vm.isSection, 'eu-mb_4': !_vm.isSection }},[_c('div',{class:[_vm.classList, { 'emd-container _highlight_header': _vm.isSection }]},[(_vm.isSection)?_c('div',{staticClass:"emd-container__header"},[_c('h2',{staticClass:"emd-text _size_default _type_caption"},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_c('div',{class:{ 'emd-container__content': _vm.isSection }},[(!_vm.isSection)?_c('p',{staticClass:"emd-text _color_default _type_caption eu-mb_1"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('a',{staticClass:"emd-btn _color_secondary _type_link eu-pr_3",attrs:{"href":_vm.$sanitize(_vm.entity.logs_url.seq),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.$t('log-links.seq'))+" "),_c('i',{staticClass:"eu-ml_2 uil uil-external-link-alt"})]),_c('a',{staticClass:"emd-btn _color_secondary _type_link eu-pl_3",attrs:{"href":_vm.$sanitize(_vm.entity.logs_url.splunk),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.$t('log-links.splunk'))+" "),_c('i',{staticClass:"eu-ml_2 uil uil-external-link-alt"})])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }