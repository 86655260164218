<template>
  <div class="emd-filter-dash__advanced">
    <emd-filter-advanced-input
      v-for="(query, index) in advancedQuery"
      :query="query"
      :key="index"
      :index="index"
      :options="options"
      :selectedKeys="selectedKeys"
      @selectKey="selectKey"
      @selectValue="selectValue"
      @submitValue="search"
      @updateList="updateList"
      @removeItem="deleteQueryItem"
      @updateAmount="updateAmount"
      @updateDate="setDate"
    />
    <div
      class="eu-my_4"
      v-if="advancedQuery.length < options.length"
    >
      <button
        data-testid="dropdown-advanced-filter-add-filter"
        type="button"
        class="emd-btn _type_text _color_secondary _size_small"
        @click="addQueryItem"
        :class="{ _disabled: loadingList }"
      >
        <i class="uil uil-plus-square" />
        {{ $t('add-filter') }}
      </button>
    </div>

    <div class="emd-filter-dash__buttons">
      <div
        class="emd-text _color_danger eu-shrink_1 eu-py_2"
        v-if="totalQueries === 0 && searchClicked"
      >
        <span>{{ $t('warning-no-value') }}</span>
      </div>
      <span>
        <button
          type="button"
          data-testid="dropdown-advanced-filter-remove-filter"
          class="emd-btn _type_text _size_small eu-mr_2"
          :alt="$t('clean-filters')"
          @click="cleanAdvancedQuery"
          :class="{ _disabled: loadingList }"
        >
          <i class="uil uil-trash" />
          {{ $t('clean-filters') }}
        </button>
        <button
          type="submit"
          data-testid="advanced-filter-btn"
          class="emd-btn _color_primary _size_small"
          :alt="$t('search')"
          @click="search"
          :disabled="formIsInvalid"
          :class="{ _disabled: loadingList }"
        >
          <i class="uil uil-search" />
          {{ $t('search') }}
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import EmdFilterAdvancedInput from './EmdFilterAdvancedInput.vue'
import { createSinceDate, createUntilDate } from '@/utils'
import { formatMoneyToCents } from '@/utils/formatMoney'

export default {
  name: 'EmdAdvancedFilter',
  props: {
    options: {
      type: Array,
      required: true
    },
    loadingList: {
      type: Boolean
    },
    origin: {
      type: String,
      required: false,
      default: ''
    },
    sendFilterEvents: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EmdFilterAdvancedInput
  },
  data () {
    return {
      formIsInvalid: false,
      query: {},
      advancedQuery: [
        {
          key: { name: '', name_query: '' },
          value: { tags: [] }
        }
      ],
      totalQueries: 0,
      searchClicked: false
    }
  },
  computed: {
    selectedKeys () {
      return this.advancedQuery.map(item => {
        return item.key.name_query
      })
    }
  },
  methods: {
    selectKey (key, i) {
      if (this.keyAlreadySelected(key)) return
      this.searchClicked = false
      const option = this.options.find(item => item.name_query === key)
      Object.assign(this.advancedQuery[i].key, option)
      if (this.formIsInvalid) {
        this.formIsInvalid = this.keyAlreadySelected({
          name_query: 'start_paid_at'
        })
      }
      this.advancedQuery[i].value = { tags: [] }
      if (key.type === 'currency') this.advancedQuery[i].value.amount = ''
      if (key.type === 'date') {
        this.advancedQuery[i].value.date = ''
        this.advancedQuery[i].value.error = false
      }
      if (key === 'start_paid_at') this.$emit('setTabbar', 'boleto')
    },
    updateList (list, i) {
      this.$set(this.advancedQuery[i].value, 'tags', list)
    },
    selectValue (valueId, i) {
      this.searchClicked = false
      if (this.valueAlreadySelected(i, valueId)) {
        const tagId = this.advancedQuery[i].value.tags.findIndex(
          value => value.id === valueId
        )
        this.removeTag(tagId, i)
        return
      }
      const item = this.advancedQuery[i].key.select_options.find(
        item => item.id === valueId
      )

      if (this.options[0]?.singleSelect) {
        this.advancedQuery[i].value.tags = [item]
      } else {
        this.advancedQuery[i].value.tags.push(item)
      }
    },
    removeTag (tagId, i) {
      if (this.loadingList) return
      this.advancedQuery[i].value.tags.splice(tagId, 1)
    },
    updateAmount (value, i) {
      if (this.loadingList) return
      this.advancedQuery[i].value.amount = value
    },
    addQueryItem () {
      if (this.loadingList) return
      this.advancedQuery.push({
        key: { name: '', name_query: '' },
        value: { tags: [] }
      })
    },
    deleteQueryItem (id) {
      if (this.loadingList) return
      if (this.advancedQuery.length === 1) {
        this.cleanAdvancedQuery()
        return
      }
      if (this.advancedQuery[id].key.type === 'date') this.formIsInvalid = false
      this.advancedQuery.splice(id, 1)
    },
    cleanAdvancedQuery () {
      if (this.loadingList) return
      this.advancedQuery = [
        {
          key: { name: '', name_query: '' },
          value: { tags: [] }
        }
      ]
      this.searchClicked = false
      this.$emit('advancedSearch')
      const shouldSendEvent =
        this.origin &&
        this.sendFilterEvents &&
        this.$gtag &&
        !this.$store.getters.isEmployee
      shouldSendEvent &&
        this.$gtag.event('remove-filter-advanced', {
          event_category: `${this.origin}-list-filter`
        })
    },
    keyAlreadySelected (key) {
      return this.advancedQuery.find(
        item => item.key.name_query === key.name_query
      )
    },
    valueAlreadySelected (i, valueId) {
      return this.advancedQuery[i].value.tags.find(item => item.id === valueId)
    },
    setDate (date, i) {
      this.advancedQuery[i].value.date = date
    },
    getTagsFromUrl (options, tags) {
      const arrTags = Array.isArray(tags) ? tags : [tags]
      const urlTags = []
      arrTags.forEach(tag => {
        const option = options.find(opt => opt.id === tag)
        if (option) urlTags.push(option)
      })
      return urlTags
    },
    prepareQuery () {
      const query = {}
      this.totalQueries = 0
      this.advancedQuery.forEach(item => {
        if (item.value.tags.length)
          query[item.key.name_query] = item.value.tags.map(tag => tag.id)
        if (item.key.name_query === 'min_amount') {
          if (item.value.amount > 0) {
            query.min_amount = formatMoneyToCents(item.value.amount)
            query.max_amount = formatMoneyToCents(item.value.amount)
          }
        } else if (
          item.key.name_query === 'start_paid_at' &&
          !!item.value.date
        ) {
          query.start_paid_at = createSinceDate(item.value.date)
          query.end_paid_at = createUntilDate(item.value.date)
        }
        if (
          ((item.key.type === 'select' || item.key.type === 'text') &&
            item.value.tags.length) ||
          (item.key.type === 'date' && item.value.date !== '') ||
          (item.key.type === 'currency' && item.value.amount !== 0)
        )
          this.totalQueries++
      })
      return query
    },
    search () {
      if (this.loadingList || this.formIsInvalid) return
      this.searchClicked = true
      this.$emit('advancedSearch')
    }
  },
  mounted () {
    const routeQuery = Object.keys(this.$route.query)
    let totalKeysInOptions = 0
    if (routeQuery.length) {
      this.advancedQuery = []
      routeQuery.forEach((key, index) => {
        const keyInOptions = this.options.find(
          option => option.name_query === key
        )
        if (keyInOptions) {
          totalKeysInOptions = totalKeysInOptions + 1
          switch (keyInOptions.type) {
            case 'select':
            case 'text':
              this.advancedQuery.push({
                key: Object.assign({}, keyInOptions),
                value: {
                  tags: this.getTagsFromUrl(
                    keyInOptions.select_options,
                    this.$route.query[key]
                  )
                }
              })
              break
            case 'currency':
              this.advancedQuery.push({
                key: Object.assign({}, keyInOptions),
                value: {
                  amount: this.$route.query[key],
                  tags: []
                }
              })
              break
            case 'date':
              this.advancedQuery.push({
                key: Object.assign({}, keyInOptions),
                value: {
                  date: this.$route.query[key].split('T')[0],
                  error: false,
                  tags: []
                }
              })
              break
          }
        }
      })
      if (!totalKeysInOptions) {
        this.advancedQuery = [
          {
            key: { name: '', name_query: '' },
            value: { tags: [] }
          }
        ]
      }
    }
  }
}
</script>
<i18n>
{
  "pt-BR": {
    "key-select-placeholder": "Selecione",
    "value-select-placeholder": "Escolha um ou mais itens",
    "add-filter": "Adicionar filtro",
    "clean-filters": "Limpar filtros",
    "search": "Buscar",
    "warning-no-value": "Selecione pelo menos um valor nas opções para filtrar os resultados."
  },
  "en-US": {
    "key-select-placeholder": "Select",
    "value-select-placeholder": "Select one or more items",
    "add-filter": "Add filter",
    "clean-filters": "Clear filter",
    "search": "Search",
    "warning-no-value": "Select at least one option to filter the results"
  }
}
</i18n>
