<template>
  <div>
    <div class="el-section">
      <div class="emd-container _highlight_header _header_spaced">
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">
            {{ $t('account.keys.api-data') }}
          </h2>
        </div>
        <div
          v-if="loadingAccount"
          class="eu-pa_6 eu-align_center"
        >
          <emd-loader :type="'_invert'"></emd-loader>
        </div>
        <div
          v-else
          class="emd-container__content"
        >
          <div class="el-chunk .eu-pa_2 eu-pa_2">
            <div class="emd-panel__section">
              <h2
                class="emd-text _color_default _type_caption eu-mb_5 emd-panel__block"
              >
                {{ $t('account.keys.api-version') }}
              </h2>
              <span class="eu-mb_5 eu-display_inline-block emd-panel__block">
                V5.0
              </span>
            </div>
            <div
              class="emd-panel__section"
              v-if="permissionIsEnabled('developer.account_id:get')"
            >
              <h2
                class="emd-text _color_default _type_caption emd-panel__block eu-mb_2"
              >
                {{ $t('account.keys.id') }}
              </h2>
              <copy-clipboard
                :copiable="account.id"
                class="emd-panel__block"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="el-section">
      <div class="emd-container _highlight_header _header_spaced">
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">
            {{ $t('account.keys.api-keys') }}
          </h2>
        </div>
        <div
          v-if="loadingAccount"
          class="eu-pa_6 eu-align_center"
        >
          <emd-loader :type="'_invert'"></emd-loader>
        </div>
        <div
          v-else
          class="emd-container__content"
        >
          <div>
            <div class="el-chunk">
              <emd-alert
                data-testid="alert-secret-key"
                variant="warning"
                :autoclose="false"
                :closeless="true"
                class="keys-alert"
                v-if="isTestAccount"
              >
                <template slot="content">
                  {{ $t('account.keys.alert') }}
                </template>
              </emd-alert>
              <div class="eu-py_2">
                <p class="emd-text _color_default eu-py_1 eu-mx_2">
                  {{ $t('account.keys.description') }}
                  <a
                    target="_blank"
                    rel="noopener"
                    class="emd-text _color_primary _type_caption"
                    href="https://docs.pagar.me/docs/chaves-de-acesso"
                    >{{ $t('account.keys.description-anchor') }}
                  </a>
                </p>
              </div>
              <div
                class="el-chunk eu-mt_2"
                v-if="permissionIsEnabled('developer.key.public:get')"
              >
                <div class="emd-panel__section eu-pa_2 key_container">
                  <div class="eu-display_flex eu-column key_container_wrapped">
                    <h2 class="emd-text _color_default _type_caption eu-py_2">
                      {{ $t('account.keys.public-key') }}
                    </h2>
                    <p class="emd-text _color_nonessential _size_tiny eu-mb_2">
                      {{ $t('account.keys.public-key-description') }}
                    </p>
                  </div>
                  <copy-clipboard
                    fullstory-id="publicKey"
                    :copiable="publicKey"
                    class="emd-panel__block"
                  />
                </div>
              </div>
            </div>
            <div
              class="emd-panel__section eu-pa_2 key_container"
              v-if="permissionIsEnabled('developer.key.secret:get')"
            >
              <div
                class="description_secret_key eu-display_flex eu-column key_container_wrapped"
              >
                <h2 class="emd-text _color_default _type_caption eu-py_2">
                  {{ $t('account.keys.secret-key') }}
                </h2>
                <p class="emd-text _color_nonessential _size_tiny eu-mb_2">
                  {{ $t('account.keys.secret-key-description') }}
                </p>
              </div>
              <secret-key
                :passwordConfirmed="passwordConfirmed"
                :copiable="secretKey"
                @clicked="handleClicked"
                @onReset="verifyMfaIsEnabled"
                class="emd-panel__block btn_container_mobile"
              />
            </div>
          </div>
        </div>
      </div>
      <modal-password
        v-if="paramsModalPassword.passwordModalIsOpen"
        :callback="paramsModalPassword.callback"
        @cancel="closeModalPassword"
      />
      <modal-success-reset-secret-key
        :isOpen="modalReset.successResetIsOpen"
        @onCancel="closeSuccessModal"
        @onSubmit="closeSuccessModal"
      />
      <modal-default-reset
        :isOpen="modalReset.confirmResetIsOpen"
        @onCancel="closeConfirmModal"
        @onSubmit="confirmPasswordReset"
        :title="$t('modal.reset-secret-key.modal-confirm.title')"
        :textParagraph="[
          $t('modal.reset-secret-key.modal-confirm.paragraph-1.half-1'),
          $t('modal.reset-secret-key.modal-confirm.paragraph-1.half-2'),
          $t('modal.reset-secret-key.modal-confirm.paragraph-1.half-3')
        ]"
        :textSubimit="$t('modal.reset-secret-key.modal-confirm.button-2')"
        :resetText="$t('modal.reset-secret-key.modal-confirm.paragraph-2')"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { EmdLoader } from 'emerald-vue/src/components'
import ModalPassword from '@/components/project/globals/ModalPassword.vue'
import CopyClipboard from '@/components/emerald/molecules/CopyClipboard'
import SecretKey from '@/components/emerald/molecules/SecretKey'
import EmdAlert from '@/components/emerald/molecules/EmdAlert'
export default {
  components: {
    EmdLoader,
    EmdAlert,
    ModalPassword,
    CopyClipboard,
    SecretKey,
    ModalSuccessResetSecretKey: () =>
      import(
        '@/components/project/globals/ResetSecretKey/ModalSuccessResetSecretKey'
      ),
    ModalDefaultReset: () =>
      import('@/components/project/globals/ResetSecretKey/ModalDefaultReset')
  },
  data () {
    return {
      loadingAccount: false,
      publicKey: null,
      paramsModalPassword: {
        passwordModalIsOpen: false,
        callback: undefined
      },
      body: {},
      secretKey: '*******************',
      passwordConfirmed: false,
      modalReset: {
        confirmResetIsOpen: false,
        confirmResetIsLoad: false,
        confirmResetHasError: null,
        successResetIsOpen: false
      },
      modalMfaIsOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'merchant',
      'account',
      'settings',
      'hasUserTwoFactorAuthentication'
    ]),
    isTestAccount () {
      return this.account.account_type.value === 'test'
    },
    accountSettings () {
      return this.settings
    },
    params () {
      return { merchantId: this.merchant.id, accountId: this.account.id }
    }
  },
  methods: {
    ...mapActions({
      setConfigMfaModal: 'SET_CONFIG_MFA_MODAL',
      resetSecreKey: 'RESET_SECRET_KEY',
      verifySecretKey: 'VERIFY_SECRET_KEY'
    }),
    handleClicked () {
      if (!this.passwordConfirmed) {
        this.openModalPasswordShowKey()
        return false
      }
      this.passwordConfirmed = false
    },
    confirmPasswordReset () {
      this.closeConfirmModal()
      this.openModalPasswordModalResetKey()
      return false
    },
    setPasswordConfirmed () {
      this.passwordConfirmed = true
    },
    openModalPasswordShowKey () {
      this.paramsModalPassword.passwordModalIsOpen = true
      this.paramsModalPassword.callback = this.setSecretKey
    },
    openModalPasswordModalResetKey () {
      this.paramsModalPassword.passwordModalIsOpen = true
      this.paramsModalPassword.callback = this.submitResetSecretKey
    },
    closeModalPassword () {
      this.paramsModalPassword.passwordModalIsOpen = false
    },
    getAccount (params) {
      this.loadingAccount = true
      this.$store.dispatch('GET_ACCOUNT', params).then(res => {
        this.loadingAccount = false
        this.publicKey = res.public_key
      })
    },
    async getSecretKey () {
      return await this.verifySecretKey({
        params: this.params,
        body: this.body
      })
    },
    setSecretKey (password) {
      this.body.password = password
      return this.getSecretKey(password).then(res => {
        this.secretKey = res.secret_key
        this.setPasswordConfirmed()
      })
    },
    async resetKey () {
      return await this.resetSecreKey({
        params: this.params,
        body: this.body
      })
    },
    async submitResetSecretKey (password) {
      this.body.password = password

      await this.resetKey()
      const { secret_key: secretKey } = await this.getSecretKey()
      this.passwordConfirmed = false
      this.secretKey = secretKey

      this.closeModalPassword()
      this.modalReset.successResetIsOpen = true
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    },
    openConfirmModal () {
      this.modalReset.confirmResetIsOpen = true
    },
    closeConfirmModal () {
      this.modalReset.confirmResetIsOpen = false
    },
    closeSuccessModal () {
      this.modalReset.successResetIsOpen = false
    },
    verifyMfaIsEnabled () {
      if (this.hasUserTwoFactorAuthentication) {
        this.openConfirmModal()
      } else {
        this.setConfigMfaModal(true)
      }
    }
  },
  beforeMount () {
    this.getAccount(this.params)
  }
}
</script>
<style scoped>
.keys-alert {
  border: 1px solid #816404;
  justify-content: flex-start;
}

@media only screen and (min-width: 910px) {
  .key_container_wrapped:first-child {
    max-width: 30%;
  }
  .key_container_wrapped {
    padding-right: 16px;
    flex: 1 0 calc(70% - 12px);
  }
}

@media (max-width: 910px) {
  .key_container {
    flex-direction: column;
  }
  .key_container_wrapped {
    max-width: 100%;
  }
}

@media (max-width: 448px) {
  .btn_container_mobile {
    flex-direction: column;
    gap: 12px;
    align-items: flex-end;
  }
}
</style>
