<template>
  <div class="el-chunk">
    <router-view></router-view>
    <log-links
      position="initial"
      :entity="account"
      v-if="
        $route.name !== 'webhook-config' &&
        $route.name !== 'webhook-config-form'
      "
      :title="$t('log-links.account')"
    ></log-links>

    <last-log></last-log>
  </div>
</template>
<script>
import LogLinks from '@/components/project/molecules/LogLinks.vue'
import LastLog from '@/components/project/molecules/LastLog.vue'
export default {
  name: 'Settings',
  components: {
    LogLinks,
    LastLog
  },
  computed: {
    params: function () {
      return {
        merchantId: this.$store.getters.merchantId,
        accountId: this.$store.getters.accountId,
        entityId: this.$store.getters.accountId
      }
    },
    account () {
      return this.$store.getters.settings
    }
  },
  methods: {
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  },
  beforeRouteUpdate (to, from, next) {
    window.scrollTo(0, 0)
    this.$store.dispatch('GET_LAST_LOG', this.params)
    next()
  },
  created () {
    this.$store.dispatch('GET_LAST_LOG', this.params)
  }
}
</script>
