import store from '@/store'

import {
  routePaymentsLinks,
  routePaymentsLinksDetail
} from './payment-links.route'
import { isSeller } from '../enums/user-role.enum'

const routeOrders = {
  path: 'orders/',
  name: 'orders',
  component: () =>
    import(
      /* webpackChunkName: "orders" */ '@/views/Payments/Orders/Orders.vue'
    ),
  beforeEnter: (to, from, next) => {
    const { merchant_id, account_id } = to.params

    if (isSeller(store.getters.userRole.key)) {
      // eslint-disable-next-line
      next({ path: `/${merchant_id}/${account_id}/payment-links` })
      return
    }

    if (!store.getters.permissionIsEnabled('order:search')) {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }

    next()
  },
  meta: {
    title: 'browser-title.orders'
  }
}
const routeOrdersDetail = {
  path: 'orders/:id',
  name: 'ordersDetail',
  component: () =>
    import(
      /* webpackChunkName: "ordersdetail" */ '@/views/Payments/Orders/OrdersDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    const { merchant_id, account_id } = to.params

    if (isSeller(store.getters.userRole.key)) {
      // eslint-disable-next-line
      next({ path: `/${merchant_id}/${account_id}/payment-links` })
      return
    }

    if (!store.getters.permissionIsEnabled('order:get')) {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }

    next()
  },
  meta: {
    title: 'browser-title.order-detail'
  }
}
const routeCharges = {
  path: 'charges/',
  name: 'charges',
  component: () =>
    import(
      /* webpackChunkName: "charges" */ '@/views/Payments/Charges/Charges.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (store.getters.permissionIsEnabled('charge:search')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.charges'
  }
}
const routeChargesDetail = {
  path: 'charges/:id',
  name: 'chargesDetail',
  component: () =>
    import(
      /* webpackChunkName: "chargesdetail" */ '@/views/Payments/Charges/ChargesDetail.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (store.getters.permissionIsEnabled('charge:get')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.charge-detail'
  }
}
const routeTrf = {
  path: 'trf/',
  name: 'trf',
  component: () =>
    import(/* webpackChunkName: "trf" */ '@/views/Payments/TRF/TRF.vue'),
  beforeEnter: (to, from, next) => {
    if (store.getters.permissionIsEnabled('trf:search')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.trf'
  }
}

export {
  routeOrders,
  routeOrdersDetail,
  routeCharges,
  routeChargesDetail,
  routeTrf,
  routePaymentsLinks,
  routePaymentsLinksDetail
}
