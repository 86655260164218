<template>
  <div>
    <emd-filter
      :options="filterOptions"
      :default="queryString"
      v-model="queryString"
      :loadingList="loadingList"
      :action="action"
      v-if="permissionIsEnabled('plan:search')"
      @error="setError"
    />
    <div class="el-section">
      <div
        class="emd-container _highlight_header _header_spaced _footer_spaced_sm"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">{{ $t('plan.list.title') }}</h2>
        </div>
        <div
          v-if="loadingList"
          class="eu-pa_6 eu-align_center"
        >
          <emd-loader :type="'_invert'"></emd-loader>
        </div>
        <div
          v-else
          class="emd-table-responsive"
        >
          <emd-table
            :fields="fields"
            :items="plans.items"
            :toDetail="permissionIsEnabled('plan:get') && toDetail"
          />
          <empty-list
            v-if="showEmptyList()"
            :entity="emptyData.entity"
            :svg="emptyData.svg"
          />
          <empty-list-filter
            v-if="showEmptyListFilter()"
            entity="plans"
          />
        </div>
        <pagination
          v-if="!loadingList"
          :pagination="plans.pagination"
          :totalShowing="totalItems"
          :type="'list'"
          :action="action"
        ></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { EmdLoader } from 'emerald-vue/src/components'
import EmdFilter from '@/components/emerald/organisms/EmdFilter.vue'
import EmptyList from '@/components/project/globals/EmptyList.vue'
import EmptyListFilter from '@/components/project/globals/EmptyListFilter.vue'
import Pagination from '@/components/project/globals/Pagination.vue'
import EmdTable from '@/components/emerald/molecules/EmdTable.vue'
export default {
  components: {
    EmdLoader,
    EmdFilter,
    EmptyList,
    EmptyListFilter,
    Pagination,
    EmdTable
  },
  data () {
    return {
      queryString: {
        size: 30
      },
      hasLocked: false,
      action: 'GET_PLANS',
      fields: [
        {
          id: 'id',
          label: 'plan.list.id',
          type: this.permissionIsEnabled('plan:get') ? 'link' : 'text',
          link: {
            to: 'plansDetail',
            params: { id: 'id', tab: 'info' },
            query: ['created_at']
          }
        },
        {
          id: 'name',
          label: 'plan.list.name',
          type: 'text'
        },
        {
          id: 'status',
          label: 'plan.list.status',
          type: 'status'
        },
        {
          id: 'billing_type',
          label: 'plan.list.billing-type',
          type: 'text'
        },
        {
          id: 'interval',
          label: 'plan.list.interval',
          type: 'interval'
        },
        {
          id: 'created_at',
          label: 'plan.list.created-at',
          type: 'date',
          format: 'dateAndHour'
        }
      ],
      toDetail: {
        name: 'plansDetail',
        params: {
          id: 'id',
          tab: 'info'
        },
        query: ['created_at']
      }
    }
  },
  computed: {
    filterOptions: function () {
      return this.$store.state.plan.filterOptions
    },
    plans: function () {
      return this.$store.getters.plans
    },
    loadingList: function () {
      return this.$store.state.plan.loadingList
    },
    totalItems: function () {
      return this.plans.items.length
    },
    emptyData: function () {
      return {
        entity: this.hasLocked ? 'locked' : 'plans',
        svg: this.hasLocked ? 'no-results' : ''
      }
    }
  },
  methods: {
    showEmptyList () {
      const emptyList =
        !this.loadingList &&
        this.plans.pagination &&
        this.plans.pagination.total_items === 0 &&
        Object.entries(this.queryString).length <= 2
      return !this.loadingList && (this.hasLocked || emptyList)
    },
    showEmptyListFilter () {
      return (
        !this.loadingList &&
        this.plans.pagination &&
        this.plans.pagination.total_items === 0 &&
        Object.entries(this.queryString).length > 2
      )
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    },
    setError (e) {
      this.$store.dispatch('toggleLoadingList', false)
      this.hasLocked = e && e.body && e.body.hasLocked
    }
  }
}
</script>
