import { DashInstance, catchError } from '@/store'

const terminals = {
  state: {
    terminals: { items: [], pagination: {} },
    loadingList: true
  },
  mutations: {
    TOGGLE_LOADING_LIST (state, status) {
      state.loadingList = status
    },
    SET_TERMINALS (state, orders) {
      state.terminals = orders
    }
  },
  getters: {
    terminals (state) {
      return state.terminals
    },
    loadingList (state) {
      return state.loadingList
    }
  },
  actions: {
    GET_TERMINALS (context, params = {}) {
      context.commit('TOGGLE_LOADING_LIST', true)
      return DashInstance.account
        .getTerminals(params.merchantId, params.accountId, params.query)
        .then(res => {
          context.commit('SET_TERMINALS', res)
          context.commit('TOGGLE_LOADING_LIST', false)
          Promise.resolve(res)
        })
        .catch(err => catchError(err, context))
    },
    GET_TERMINAL_BY_SERIAL_NUMBER (context, params = {}) {
      return DashInstance.account
        .getTerminalBySerialNumber(
          params.merchantId,
          params.accountId,
          params.serialNumber,
          params.query
        )
        .then(res => Promise.resolve(res))
        .catch(err => catchError(err, context))
    },
    TOGGLE_LOADING_LIST ({ commit }, status) {
      commit('TOGGLE_LOADING_LIST', status)
    }
  }
}

export default terminals
