import { ScopeInstance, catchError } from '@/store'

const user = {
  state: {
    users: { items: [], pagination: {} },
    loadingList: true,
    filterOptions: [
      {
        name: 'users.filter.name',
        type: 'text',
        name_query: 'first_name'
      },
      {
        name: 'users.filter.surname',
        type: 'text',
        name_query: 'last_name'
      },
      {
        name: 'users.filter.email',
        type: 'text',
        name_query: 'email'
      }
    ]
  },
  getters: {
    users (state) {
      return state.users
    }
  },
  mutations: {
    toggleLoadingList (state, status) {
      state.loadingList = status
    },
    SET_USERS (state, users) {
      state.users = users
    }
  },
  actions: {
    GET_USERS (context, params = {}) {
      const query = {
        merchantId: params.merchantId,
        accountId: params.accountId,
        ...params.query
      }
      context.commit('toggleLoadingList', true)
      return ScopeInstance.user
        .getAll(query)
        .then(res => {
          context.commit('toggleLoadingList', false)
          context.commit('SET_USERS', res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => catchError(err, context))
    },
    GET_USER_DETAIL (context, params = {}) {
      const query = {
        merchantId: context.getters.merchantId,
        accountId: context.getters.accountId,
        userId: params.userId
      }

      return ScopeInstance.user
        .getByIdAsAccount(query)
        .then(res => Promise.resolve(res.data))
        .catch(err => catchError(err, context))
    },
    UPDATE_PERMISSION (context, params = {}) {
      const query = {
        merchantId: context.getters.merchantId,
        accountId: context.getters.accountId,
        userId: params.userId
      }

      return ScopeInstance.user
        .updateAccountPermission(query, params.body)
        .catch(err => catchError(err, context))
    },
    REMOVE_PERMISSION (context, params = {}) {
      const query = {
        merchantId: context.getters.merchantId,
        accountId: context.getters.accountId,
        userId: params.userId
      }

      return ScopeInstance.user
        .removePermission(params.body, query)
        .then(res => Promise.resolve(res.data))
        .catch(err => catchError(err, context))
    },
    toggleLoadingList ({ commit }, status) {
      commit('toggleLoadingList', status)
    }
  }
}

export default user
