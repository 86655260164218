import Recipients from '@/views/Recipients/Recipients.vue'
import store from '@/store'

function isPageEnabled (page) {
  return page
    ? store.getters.componentIsEnabled('recipients', page)
    : store.getters.componentIsEnabled('sidemenu', 'recipients')
}

function isElegible (action, page, separator = ':') {
  const hasPermission = store.getters.permissionIsEnabled(
    `recipients${separator}${action}`
  )
  const pageIsEnabled = isPageEnabled(page)
  return hasPermission && pageIsEnabled
}

function isBusinessPSPOrHibryd () {
  return ['p_s_p', 'gateway_and_psp'].includes(store.getters.businessModel)
}

const routeRecipients = {
  path: 'recipients/',
  name: 'recipients',
  component: Recipients,
  beforeEnter: (to, from, next) => {
    if (isElegible('view')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.recipients'
  }
}
const routeRecipientsCreate = {
  path: 'recipients/create',
  name: 'recipientsCreate',
  component: () =>
    import(
      /* webpackChunkName: "usersdetail" */ '@/views/Recipients/RecipientCreate/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (isElegible('create')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.recipient-create'
  }
}
const routeRecipientsDetails = {
  path: 'recipients/:id',
  name: 'recipientsDetail',
  component: () =>
    import(
      /* webpackChunkName: "usersdetail" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (isElegible('get')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.recipient-detail'
  }
}
const routeRecipientsData = {
  path: 'recipients/:id/recipient-info',
  name: 'recipientsData',
  component: () =>
    import(
      /* webpackChunkName: "usersdetail" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (isElegible('edit')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.recipient-data'
  }
}
const routeRecipientsSettings = {
  path: 'recipients/:id/recipient-settings',
  name: 'recipientsSettings',
  component: () =>
    import(
      /* webpackChunkName: "usersdetail" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (isElegible('edit')) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.recipient-settings'
  }
}

const routeRecipientsBalance = {
  path: 'recipients/:id/balance/:pg_recipient_id?',
  name: 'recipientsBalance',
  alias: [
    'recipients/:id/withdraw/:pg_recipient_id?',
    'recipients/:id/anticipation/:pg_recipient_id?'
  ],
  component: () =>
    import(
      /* webpackChunkName: "usersdetail" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (isElegible('balance:view', 'balance', '.') && isBusinessPSPOrHibryd()) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.recipient-detail'
  }
}

const routeRecipientsTransfers = {
  path: 'recipients/:id/transfers/:pg_recipient_id?/:transfer_id?',
  name: 'recipientsTransfers',
  component: () =>
    import(
      /* webpackChunkName: "usersdetail" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (isElegible('balance:view', 'balance', '.') && isBusinessPSPOrHibryd()) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.recipient-detail'
  }
}

const routeRecipientsSettlements = {
  path: 'recipients/:id/settlements/:pg_recipient_id?',
  name: 'recipientsSettlements',
  component: () =>
    import(
      /* webpackChunkName: "usersdetail" */ '@/views/Recipients/RecipientDetail/index.vue'
    ),
  beforeEnter: (to, from, next) => {
    if (
      isElegible('settlements:view', 'settlements', '.') &&
      isBusinessPSPOrHibryd()
    ) {
      next()
    } else {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }
  },
  meta: {
    title: 'browser-title.recipient-detail'
  }
}

export {
  routeRecipients,
  routeRecipientsDetails,
  routeRecipientsData,
  routeRecipientsSettings,
  routeRecipientsBalance,
  routeRecipientsTransfers,
  routeRecipientsSettlements,
  routeRecipientsCreate
}
