<template>
  <div class="emd-onboarding__greeting">
    <div class="emd-container _content_spaced _footer_spaced _footer_end_md">
      <div class="emd-container__header eu-justify_end eu-align-items_start">
        <a
          href="javascript:void(0)"
          class="emd-text _color_tertiary"
          @click="close"
        >
          <p class="emd-btn _color_tertiary _type_link">
            <i class="uil uil-times"></i>
          </p>
        </a>
      </div>
      <div class="emd-container__content">
        <div class="emd-onboarding__image">
          <emerald-icon :icon="'onboarding/greeting'" />
        </div>
        <h2 class="emd-text _size_page _color_default">
          {{ $t('title') }}
        </h2>
        <div class="emd-text _size_section _type_hint _color_default eu-my_3">
          <p>{{ $t('message') }}</p>
        </div>
      </div>
      <div class="emd-container__footer">
        <button
          class="emd-btn _type_text _color_secondary eu-mr_3"
          @click="close"
        >
          {{ $t('not-now') }}
        </button>
        <button
          class="emd-btn _color_secondary"
          @click="explore"
        >
          {{ $t('explore') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import EmeraldIcon from '@/components/emerald/atoms/EmeraldIcon.vue'
export default {
  name: 'Greeting',
  components: {
    EmeraldIcon
  },
  methods: {
    close () {
      this.$emit('close')
    },
    explore () {
      this.$emit('explore')
    }
  }
}
</script>

<i18n>
  {
    "pt-BR": {
      "title": "Bem vindo ao Dash!",
      "message": "Explore as funcionalidades com o nosso guia de 5 passos e simplifique o gerenciamento de pagamentos e integrações da sua loja online.",
      "not-now": "Agora não",
      "explore": "Explorar"
    },
    "en-US": {
      "title": "Welcome to your dashboard!",
      "message": "Explore all functionalities with our 5 steps guide and simplify the management of your store's payments and integrations.",
      "not-now": "Not now",
      "explore": "Explore"
    }
  }
  </i18n>
