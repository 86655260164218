<template>
  <emd-communication
    class="eu-pa_2 emd-communication"
    :class="{ _fixed: fixed }"
    color="pagarme"
  >
    <span class="emd-text _color_default eu-mt_1">
      <span class="emd-pagarme-icon-outline _active">
        <span><emd-pagarme-icon fillColor="white" /></span>
      </span>
    </span>
    <span
class="emd-text _type_bold eu-ml_3"
      >A Mundipagg agora é Pagar.me,
      <a
        class="emd-text emd-link _color_clear _decoration_underline"
        href="https://mundipagg.zendesk.com/hc/pt-br/categories/4404432249876-Incorpora%C3%A7%C3%A3o-Mundipagg-pelo-Pagar-me"
        target="_blank"
        rel="noopener noreferrer"
        >acesse o nosso FAQ</a
      >
      e entenda o que muda.</span
    >
  </emd-communication>
</template>

<script>
import { EmdCommunication, EmdPagarmeIcon } from 'emerald-vue/src/components'
export default {
  components: {
    EmdCommunication,
    EmdPagarmeIcon
  },
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  }
}
</script>
