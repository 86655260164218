<template>
  <div :class="['emd-alert', enumVariant[variant]]">
    <div class="emd-alert__icon">
      <i :class="`uil uil-${enumIcon[variant]}`" />
    </div>
    <div class="emd-alert__content">
      <h1
        class="emd-alert__title"
        v-if="title"
      >
        {{ title }}
      </h1>
      <slot name="content"></slot>
    </div>
    <a
      v-if="!closeless"
      href="javascript:void(0)"
      class="emd-alert__close"
      @click="$emit('cancel')"
    >
      <svg
        v-if="autoclose"
        width="24px"
        height="24px"
        viewBox="0 0 30 30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="close-with-timer"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <circle
            id="timer"
            stroke="#000000"
            stroke-width="3"
            cx="15"
            cy="15"
            r="13"
          ></circle>
          <path
            d="M17.2745455,15 L20.6860227,11.5885227 C21.1046591,11.1698864 21.1046591,10.4911364 20.6860227,10.0721591 L19.9278409,9.31397727 C19.5092045,8.89534091 18.8304545,8.89534091 18.4114773,9.31397727 L15,12.7254545 L11.5885227,9.31397727 C11.1698864,8.89534091 10.4911364,8.89534091 10.0721591,9.31397727 L9.31397727,10.0721591 C8.89534091,10.4907955 8.89534091,11.1695455 9.31397727,11.5885227 L12.7254545,15 L9.31397727,18.4114773 C8.89534091,18.8301136 8.89534091,19.5088636 9.31397727,19.9278409 L10.0721591,20.6860227 C10.4907955,21.1046591 11.1698864,21.1046591 11.5885227,20.6860227 L15,17.2745455 L18.4114773,20.6860227 C18.8301136,21.1046591 19.5092045,21.1046591 19.9278409,20.6860227 L20.6860227,19.9278409 C21.1046591,19.5092045 21.1046591,18.8304545 20.6860227,18.4114773 L17.2745455,15 Z"
            id="xis"
            fill="#000000"
            fill-rule="nonzero"
          ></path>
        </g>
      </svg>
      <i
        class="uil uil-times"
        v-else
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'EmdAlert',
  props: {
    closeless: {
      default: false,
      type: Boolean
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    autoclose: {
      type: Boolean,
      required: false,
      default: true
    },
    variant: {
      type: String,
      required: false,
      default: 'info',
      validator: value =>
        ['success', 'warning', 'danger', 'info'].includes(value)
    }
  },
  data () {
    return {
      isVisible: true,
      enumVariant: {
        success: '_color_success',
        warning: '_color_warning',
        danger: '_color_danger',
        info: '_color_info'
      },
      enumIcon: {
        success: 'check-circle',
        warning: 'exclamation-triangle',
        danger: 'times-circle',
        info: 'info-circle'
      }
    }
  },
  mounted () {
    if (this.autoclose) {
      setTimeout(() => {
        this.$emit('cancel')
      }, 7000)
    }
  }
}
</script>

<style scoped>
._color_info {
  background-color: #dfd5ff !important;
  color: #6045af !important;
}
h1.emd-alert__title {
  margin-bottom: 0;
}
</style>
