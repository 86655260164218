<template>
  <div>
    <emd-filter
      :options="filterOptions"
      :default="queryString"
      v-model="queryString"
      :action="action"
      v-if="permissionIsEnabled('action_log:search')"
    />
    <div>
      <div
        class="emd-container _highlight_header _header_spaced _footer_spaced_sm"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">
            {{ $t('log-actions.list.title') }}
          </h2>
        </div>
        <div
          v-if="loadingList"
          class="eu-pa_6 eu-align_center"
        >
          <emd-loader :type="'_invert'" />
        </div>
        <div v-else>
          <div
            class="emd-container__content"
            v-if="showWarning() && !closeWarning"
          >
            <emd-alert
              :variant="'warning'"
              :autoclose="false"
              @cancel="closeWarning = true"
            >
              <template slot="content">
                <p>{{ $t('log-actions.list.warning') }}</p>
              </template>
            </emd-alert>
          </div>
          <div
            class="emd-card-grid"
            v-for="(log, index) in logActions.items"
            :key="index"
          >
            <div class="emd-card-grid__content">
              <div class="eu-clearfix">
                <div class="eu-float_left">
                  <h2
                    class="emd-text _type_caption eu-pb_2 eu-pr_2 eu_no_padding_sm"
                  >
                    {{ log.action }}
                  </h2>
                </div>
                <div class="eu-float_left_mobile eu-float_right">
                  <span class="emd-badge _color_info">{{
                    log.timestamp | dateFormat('dateAndHour')
                  }}</span>
                </div>
              </div>
              <div
                class="emd-card-grid__content"
                v-if="Object.entries(log.metadata).length"
              >
                <label
                  class="emd-text _type_label _size_tiny _color_nonessential"
                  >{{ $t('log-actions.list.changes') }}</label
                >
                <div class="eu-mt_1">
                  <pre class="ccg-scrollbar _fixed">{{ log.metadata }}</pre>
                </div>
              </div>
            </div>
            <div class="emd-card-grid__row">
              <div class="emd-card-grid__column">
                <label
                  class="emd-text _type_label _size_tiny _color_nonessential"
                  >{{ $t('log-actions.list.name') }}</label
                >
                <p class="emd-card-grid__line">{{ log.user_name || '-' }}</p>
              </div>
              <div class="emd-card-grid__column">
                <label
                  class="emd-text _type_label _size_tiny _color_nonessential"
                  >{{ $t('log-actions.list.email') }}</label
                >
                <p class="emd-card-grid__line">{{ log.user_email || '-' }}</p>
              </div>
              <div class="emd-card-grid__column">
                <label
                  class="emd-text _type_label _size_tiny _color_nonessential"
                  >{{ $t('log-actions.list.origin-id') }}</label
                >
                <p class="emd-card-grid__line">{{ log.key || '-' }}</p>
              </div>
              <div class="emd-card-grid__column">
                <label
                  class="emd-text _type_label _size_tiny _color_nonessential"
                  >{{ $t('log-actions.list.ip') }}</label
                >
                <p class="emd-card-grid__line">{{ log.ip || '-' }}</p>
              </div>
              <div class="emd-card-grid__column _size_100">
                <label
                  class="emd-text _type_label _size_tiny _color_nonessential"
                  >{{ $t('log-actions.list.agent') }}</label
                >
                <p class="emd-card-grid__line">
                  {{ log.user_agent || '-' }}
                </p>
              </div>
            </div>
          </div>
          <empty-list
            v-if="showEmptyList()"
            entity="logs"
          ></empty-list>
          <empty-list-filter
            v-if="showEmptyListFilter()"
            entity="logs"
          ></empty-list-filter>
          <pagination
            v-if="!loadingList"
            :pagination="logActions"
            :totalShowing="totalItems"
            :type="'dynamic'"
            :action="action"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EmdLoader } from 'emerald-vue/src/components'
import EmdAlert from '@/components/emerald/molecules/EmdAlert.vue'
import EmdFilter from '@/components/emerald/organisms/EmdFilter.vue'
import EmptyList from '@/components/project/globals/EmptyList.vue'
import EmptyListFilter from '@/components/project/globals/EmptyListFilter.vue'
import Pagination from '@/components/project/globals/Pagination.vue'
export default {
  components: {
    EmdLoader,
    EmdAlert,
    EmdFilter,
    EmptyList,
    EmptyListFilter,
    Pagination
  },
  data () {
    return {
      queryString: {
        size: 30
      },
      action: 'GET_LOG_ACTIONS',
      closeWarning: false
    }
  },
  computed: {
    filterOptions () {
      return this.$store.state.log.filterOptions
    },
    logActions () {
      return this.$store.getters.logActions
    },
    loadingList () {
      return this.$store.state.customer.loadingList
    },
    totalItems () {
      return this.logActions.items.length
    }
  },
  methods: {
    showEmptyList () {
      return (
        !this.loadingList &&
        this.logActions &&
        this.logActions.total_items === 0 &&
        Object.entries(this.queryString).length <= 2
      )
    },
    showEmptyListFilter () {
      return (
        !this.loadingList &&
        this.logActions &&
        this.logActions.total_items === 0 &&
        Object.entries(this.queryString).length > 2
      )
    },
    showWarning () {
      return (
        Object.entries(this.queryString).length <= 2 &&
        !this.loadingList &&
        this.totalItems
      )
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  },
  beforeCreate () {
    this.$store.dispatch('GET_ACTIONS')
  }
}
</script>
<style>
/* Scrollbar */
.ccg-scrollbar._fixed::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 4px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
}
.ccg-scrollbar._fixed::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
.ccg-scrollbar._fixed::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #dfe9f0;
}
.ccg-scrollbar._fixed {
  scrollbar-color: #dfe9f0 transparent;
}
/* Dark mode */
._mode_dark .ccg-scrollbar._fixed::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.1);
}
._mode_dark .ccg-scrollbar._fixed::-webkit-scrollbar-thumb {
  background-color: #314c5e;
}
._mode_dark .ccg-scrollbar._fixed {
  scrollbar-color: #314c5e transparent;
}
/* Light mode */
._mode_light .ccg-scrollbar._fixed::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
}
._mode_light .ccg-scrollbar._fixed::-webkit-scrollbar-thumb {
  background-color: #dfe9f0;
}
._mode_light .ccg-scrollbar._fixed {
  scrollbar-color: #dfe9f0 transparent;
}
</style>
