<template>
  <div
    class="emd-onboarding"
    ref="onboarding"
  >
    <div
      class="emd-onboarding__wrapper"
      v-for="(item, i) in onboarding"
      :class="{ 'eu-display_none': i !== step }"
      :key="item.id"
      :ref="item.id"
    >
      <emd-pulse
        color="secondary"
        :class="item.pulseDirection"
      />
      <div class="emd-container _content_spaced">
        <div>
          <emd-status-bar :status="'secondary'" />
          <div
            class="emd-container__header eu-justify_end eu-align-items_start"
          >
            <button
              class="emd-btn _type_link _color_contrast"
              @click="close"
            >
              <i class="uil uil-times" />
            </button>
          </div>
        </div>
        <div class="emd-container__content eu-align_center">
          <div
            v-if="item.hasImage"
            class="emd-onboarding__image"
          >
            <emerald-icon :icon="`onboarding/${item.id}`" />
          </div>
          <div class="emd-text _color_default">
            <h2 class="emd-text _size_page _color_default">
              <span v-if="item.showStepNumber">{{ i + 1 }}. </span
              >{{ $t(`${item.id}.title`) }}
            </h2>
            <div class="eu-my_5">
              {{ $t(`${item.id}.text`, { account: account, url: docsUrl }) }}
            </div>
          </div>
        </div>
        <div class="emd-container__footer eu-align_center eu-mb_3">
          <template v-if="item.showStepNumber">
            <div class="eu-container">
              <div class="eu-flex eu-justify_space-between eu-mb_3 eu-px_4">
                <button
                  class="emd-btn _color_secondary _type_link"
                  :disabled="i === 0"
                  @click="goToStep(i - 1)"
                >
                  {{ $t('previous') }}
                </button>
                <button
                  class="emd-btn _color_secondary _type_link"
                  @click="goToStep(i + 1)"
                >
                  {{ i < totalSteps - 1 ? $t('next') : $t('conclude') }}
                </button>
              </div>
            </div>
            <emd-progress-indicator
              size="small"
              color="tertiary"
              interactive
              :totalSteps="totalSteps"
              :currentStep="i + 1"
              @goToStep="goToStep"
            />
          </template>
          <div
            v-else
            class="eu-flex eu-justify_end eu-mb_3 eu-px_4"
          >
            <button
              class="emd-btn _color_secondary"
              @click="close"
            >
              {{ $t('ok') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import {
  EmdStatusBar,
  EmdPulse,
  EmdProgressIndicator
} from 'emerald-vue/src/components'
import EmeraldIcon from '@/components/emerald/atoms/EmeraldIcon.vue'

export default {
  name: 'Onboarding',
  components: {
    EmeraldIcon,
    EmdStatusBar,
    EmdProgressIndicator,
    EmdPulse
  },
  props: {
    account: {
      type: String,
      default: null
    },
    currentStep: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data () {
    return {
      onboarding: [
        {
          id: 'navigation',
          pulseDirection: 'eu-top eu-left_negative_10',
          showStepNumber: true,
          hasImage: true,
          popper: {
            placement: 'right',
            offset: [180, 0],
            instance: null
          }
        },
        {
          id: 'realTime',
          pulseDirection: 'eu-top eu-left_negative_10',
          showStepNumber: true,
          hasImage: true,
          popper: {
            placement: 'right-start',
            offset: [80, 0],
            instance: null
          }
        },
        {
          id: 'settings',
          pulseDirection: 'eu-top eu-left_negative_10',
          showStepNumber: true,
          hasImage: true,
          popper: {
            placement: 'right-start',
            offset: [25, 8],
            instance: null
          }
        },
        {
          id: 'feedback',
          pulseDirection: 'eu-bottom eu-right_negative_10',
          showStepNumber: false,
          hasImage: false,
          popper: {
            placement: 'left-start',
            offset: [-30, 10],
            instance: null
          }
        },
        {
          id: 'profile',
          pulseDirection: 'eu-right_negative_5 eu-top',
          showStepNumber: false,
          hasImage: false,
          popper: {
            placement: 'left-end',
            offset: [35, -25],
            instance: null
          }
        }
      ],
      docsUrl: process.env.VUE_APP_URL_DOCUMENTATION,
      step: this.currentStep
    }
  },
  computed: {
    totalSteps () {
      return this.onboarding.filter(item => {
        return item.showStepNumber
      }).length
    }
  },
  created () {
    // remove item 'settings' se não tem permissão
    if (
      !this.componentIsEnabled('sidemenu', 'settings') ||
      !this.componentIsEnabled('onboarding', 'settings')
    ) {
      // todo: talvez precise mudar aqui, pq jpmorgan admin e owner tem acesso as configs
      this.removeItem('settings')
    }
    // remove item 'feedback' para whitelabel
    if (!this.componentIsEnabled('onboarding', 'feedback')) {
      this.removeItem('feedback')
    } else {
      /**
        adiciona atributo data-onboarding para referência
        do onboarding no botão de FEEDBACK gerado pelo jira
      */
      const feedbackbutton = document.querySelector('[id="atlwdg-trigger"]')
      feedbackbutton.setAttribute('data-onboarding', 'feedback')
    }
  },
  mounted () {
    this.createPopperInstance(this.onboarding[this.step])
  },
  methods: {
    close () {
      // se o step não for o último, exibir step 'profile'
      if (this.step < this.onboarding.length - 1) {
        this.$store.dispatch('UPDATE_ONBOARDING_STEP', this.step)
        this.goToStep(this.onboarding.length - 1)
      } else if (this.step === this.onboarding.length - 1) {
        // se o step for o último, emite evento de fechar
        this.$store.dispatch('TOGGLE_SHOW_ONBOARDING_HELP', false)
        // reset do step para o primeiro
        this.$store.dispatch('UPDATE_ONBOARDING_STEP', 0)
        this.$emit('close')
      }
    },
    removeItem (id) {
      const indexToRemove = this.onboarding.findIndex(
        element => element.id === id
      )
      this.onboarding.splice(indexToRemove, 1)
    },
    createPopperInstance (step) {
      const reference = document.querySelector(`[data-onboarding="${step.id}"]`)
      const popper = this.$refs[step.id][0]
      if (reference) {
        step.popper.instance = createPopper(reference, popper, {
          placement: step.popper.placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: step.popper.offset
              }
            },
            {
              name: 'eventListeners',
              enabled: true
            }
          ]
        })
      }
    },
    goToStep (step) {
      const nextIndex = step
      const currentIndex = this.step
      this.step = nextIndex
      /* remove display: none do popper para o posicionamento do popper ficar correto */
      if (nextIndex >= 0 && nextIndex < this.onboarding.length) {
        if (!this.onboarding[nextIndex].popper.instance) {
          this.createPopperInstance(this.onboarding[nextIndex])
        }
        this.hideStep(this.onboarding[currentIndex])
        this.showStep(this.onboarding[nextIndex])
      }
    },
    hideStep (step) {
      step.popper.instance.setOptions({
        modifiers: [{ name: 'eventListeners', enabled: false }]
      })
    },
    showStep (step) {
      step.popper.instance.setOptions({
        modifiers: [
          { name: 'eventListeners', enabled: true },
          { name: 'offset', options: { offset: step.popper.offset } }
        ]
      })
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  }
}
</script>

<style scoped>
/* previne scroll no mobile */
.emd-onboarding {
  position: fixed;
}

.emd-progress-indicator._size_small .emd-progress-indicator__item:before,
.emd-progress-indicator._size_small
  .emd-progress-indicator__item._active
  ~ .emd-progress-indicator__item:before {
  bottom: 5px;
}
@media screen and (max-width: 767px) {
  .emd-onboarding__wrapper[data-popper-placement] {
    transform: none !important;
  }
}
</style>

<i18n>
{
  "pt-BR": {
    "previous": "Anterior",
    "next": "Próximo",
    "conclude": "Concluir",
    "ok": "Ok, entendi!",
    "navigation": {
      "title": "Navegue com praticidade",
      "text": "<p class='eu-mb_5'>Você está no dash da <strong>{account}</strong>.</p><p>Clique aqui para trocar entre as contas da empresa ou criar contas de teste.</p>"
    },
    "realTime": {
      "title": "Acompanhe os dados em tempo real",
      "text": "<p>Acesse o <strong>menu</strong> para trocar a visualização dos dados e gerencie facilmente os pedidos, pagamentos e assinaturas do seu negócio.</p>"
    },
    "salesExperience": {
      "title": "Melhore sua experiência de venda",
      "text": "<p>Acesse o nosso HUB para conectar sua loja a diversos serviços do mercado. </p>"
    },
    "settings": {
      "title": "Configure sua loja",
      "text": "<p class='eu-mb_5'>Navegue entre os menus de configuração para definir especificações da loja ou consulte suas chaves para conectar-se a nossa API. Em caso de dúvida, você pode acessar a nossa <a href='{url}' target='_blank' class='emd-link' rel='noopener noreferrer'>documentação</a>.</p>"
    },
    "feedback": {
      "title": "Parabéns!",
      "text": "<p class='eu-mb_4'>Você concluiu o guia dos primeiros passos. Agora você está pronto para desfrutar do melhor da tecnologia com a sua loja online.</p><p class='eu-mb_5'>Quer contar pra nós como foi sua experiência? Utilize nosso feedback.</p>"
    },
    "profile": {
      "title": "Seu perfil",
      "text": "<p>Se tiver alguma dúvida, no seu menu de usuário você encontra acesso rápido ao nosso guia, seus convites e perfil.</p>"
    }
  },
  "en-US": {
    "previous": "Previous",
    "next": "Next",
    "conclude": "Conclude",
    "ok": "Got it!",
    "navigation": {
      "title": "Browse easily",
      "text": "<p class='eu-mb_5'>Welcome to account <strong>{account}</strong> dashboard.</p><p> Click here to switch between your accounts or to create test accounts.</p>"
    },
    "realTime": {
      "title": "Keep up with all data in real time",
      "text": "<p>Switch between views using the <strong>tabs menu</strong> to easily manage all your business’ sales, payments and signatures.</p>"
    },
    "salesExperience": {
      "title": "Improve your sales experience",
      "text": "<p>You can access our HUB to connect your store to others services.</p>"
    },
    "settings": {
      "title": "Set up your store",
      "text": "<p class='eu-mb_5'>Navigate between the configuration menus to config your store or check your credentials to connect to our API.</p><p>If you have any doubt, you can view our <a href='{url}' target='_blank' class='emd-link' rel='noopener noreferrer'>API documentation</a>.</p>"
    },
    "feedback": {
      "title": "Congratulations!",
      "text": "<p class='eu-mb_4'>You had successfully concluded our first steps guide. Now you’re ready to enjoy the best of technology with your online store.</p><p class='eu-mb_5'>Wanna tell us about your experience? Send us a feedback.</p>"
    },
    "profile": {
      "title": "Your profile",
      "text": "<p>If you have any questions, in your user menu you can find quick access to our onboarding guide, your invitations and profile.</p>"
    }
  }
}
</i18n>
