export function formatErrorMessage (error, defaultError) {
  let errors = []
  if (error && error.body && Object.keys(error.body).length) {
    let arrayOfArrays
    if (
      'errors' in error.body &&
      error.body.errors &&
      Object.keys(error.body.errors).length &&
      Array.isArray(error.body.errors)
    ) {
      errors = error.body.errors.map(err => err.message)
    } else if (
      'errors' in error.body &&
      error.body.errors &&
      Object.keys(error.body.errors).length
    ) {
      arrayOfArrays = error.body.errors ? Object.values(error.body.errors) : []
      errors = arrayOfArrays.reduce((a, b) => [...a, ...b], [])
    } else if (
      error.body &&
      error.body.notifications &&
      Object.keys(error.body.notifications).length
    ) {
      arrayOfArrays = error.body.notifications
        ? Object.values(error.body.notifications)
        : []
      errors = arrayOfArrays.reduce((a, b) => [...a, ...b], [])
    } else {
      arrayOfArrays = Object.values(error.body)
      errors = arrayOfArrays.reduce((a, b) => [...a, ...b], [])
    }
    return errors.length ? errors : [defaultError]
  }
}
