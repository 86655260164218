<template>
  <div
    :class="['emd-modal', open ? '_active' : '']"
    style="z-index: 10000"
  >
    <div class="emd-modal__background"></div>
    <div :class="['emd-modal__content', ...classList]">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    open: {
      type: Boolean,
      required: true,
      default: true
    },
    size: {
      type: String,
      required: false
    }
  },
  computed: {
    classList () {
      const classList = []
      if (this.size) classList.push(`_size_${this.size}`)
      return classList
    }
  }
}
</script>
<style>
.emd-modal {
  z-index: 10;
}

._size_medium {
  width: 768px !important;
}

._size_full_width {
  width: 100%;
}
</style>
