<template>
  <a
    class="emd-btn-menu _color_secondary"
    :class="{ _open: btnMenuState }"
    @click="toogleBtnMenu"
  >
    <span class="emd-action-button">
      <svg
        overflow="visible"
        width="14px"
        height="16px"
        viewBox="0 0 24 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        class="emd-action-button__icon"
      >
        <path
          d="M0,19 L26,19"
          id="down"
          stroke-width="4"
          stroke-linecap="round"
        ></path>
        <path
          d="M0,10 L26,10"
          id="middle"
          stroke-width="4"
          stroke-linecap="round"
        ></path>
        <path
          d="M0,1 L26,1"
          id="up"
          stroke-width="4"
          stroke-linecap="round"
        ></path>
      </svg>
    </span>
  </a>
</template>

<script>
export default {
  name: 'EmdButtonMenu',
  props: {
    btnMenuState: {
      type: Boolean
    }
  },
  methods: {
    toogleBtnMenu () {
      this.$emit('btnMenuClicked')
    }
  }
}
</script>
