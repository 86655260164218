<template>
  <div
    class="el-section"
    v-if="showLastLog"
  >
    <p
      class="emd-text _size_small _align_center"
      v-if="lastLog && lastLog.error"
    >
      <i class="uil uil-exclamation-triangle" />
      {{ $t('log-actions.detail.error') }}
    </p>
    <p
      class="emd-text _size_small _align_center"
      v-else
    >
      {{ $t('log-actions.detail.last-action') }}:
      <strong>{{ lastLog.action }}</strong> {{ $t('log-actions.detail.at') }}
      <strong>{{ lastLog.timestamp | dateFormat('dateAndHour') }}</strong>
      {{ $t('log-actions.detail.for') }}
      <strong>{{ lastLog.user_name }}</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: 'LastLog',
  props: {},
  computed: {
    lastLog () {
      return this.$store.getters.lastLog
    },
    showLastLog () {
      if (this.lastLog) {
        return (
          this.lastLog.key === this.$route.params.id ||
          this.lastLog.key === this.$route.params.account_id
        )
      }
      return false
    }
  }
}
</script>
