<template>
  <div>
    <emd-filter
      v-if="showFilter"
      v-model="queryString"
      :action="action"
      :default="queryString"
      :options="subscriptionFilterOptions"
      :loadingList="loadingListSubscriptions"
      @error="setError"
    />

    <subscriptions-table
      :query-string="queryString"
      :action="action"
      :has-locked="hasLocked"
    />
  </div>
</template>
<script>
import EmdFilter from '@/components/emerald/organisms/EmdFilter.vue'
import { mapGetters } from 'vuex'

import SubscriptionsTable from './SubscriptionsTable.vue'

export default {
  components: {
    EmdFilter,
    SubscriptionsTable
  },
  data () {
    return {
      queryString: {
        size: 30,
        period_type: 'date_filters',
        date_filters: 'SevenDays'
      },
      action: 'GET_SUBSCRIPTIONS',
      hasLocked: false
    }
  },
  computed: {
    ...mapGetters([
      'loadingListSubscriptions',
      'subscriptionFilterOptions',
      'permissionIsEnabled'
    ]),

    showFilter () {
      return this.permissionIsEnabled('subscription:search')
    }
  },

  methods: {
    setError (e) {
      this.$store.dispatch('toggleLoadingList', false)
      this.hasLocked = e?.body?.hasLocked ?? false
    }
  }
}
</script>
