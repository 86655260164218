<template>
  <div
    v-bind:class="{
      'emd-panel eu-no-padding': !label,
      'emd-panel__section': label
    }"
  >
    <div
      class="emd-panel__block"
      v-if="label"
    >
      <div class="el-chunk">
        <h2 class="emd-text _color_default _type_caption">{{ $t(label) }}</h2>
      </div>
    </div>
    <div v-bind:class="{ 'emd-panel__block': label }">
      <div class="emd-form-group">
        <label class="emd-label _required">{{
          $t('users.edit.password')
        }}</label>
        <div class="emd-input-group">
          <input
            name="passwordConfirmation"
            class="emd-input fs-hide"
            :type="inputType"
            :value="value"
            @input="onInput"
          />
          <a
            v-on:click="toggleInput()"
            class="emd-input-icon__link"
          >
            <i
              v-if="inputType === 'password'"
              class="uil uil-eye"
            />
            <i
              v-if="inputType === 'text'"
              class="uil uil-eye-slash"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordConfirmation',
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: 'payment-methods.config.password-label'
    }
  },
  data () {
    return {
      inputType: 'password'
    }
  },
  methods: {
    toggleInput: function () {
      this.inputType = this.inputType === 'text' ? 'password' : 'text'
    },
    onInput (event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>
