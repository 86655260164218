const ID = {
  id: 'id',
  label: 'subscription.list.id',
  permission: 'subscription:get',
  type: 'text',
  link: {
    to: 'subscriptionsDetail',
    params: { id: 'id', tab: 'info' },
    query: ['created_at']
  }
}
const CODE = {
  id: 'code',
  label: 'subscription.list.code',
  type: 'text'
}
const PLAN_NAME = {
  id: 'plan.name',
  label: 'subscription.list.plan',
  type: 'text'
}
const STATUS = {
  id: 'status',
  label: 'subscription.list.status',
  type: 'status'
}
const TYPE = {
  id: 'billing_type',
  label: 'subscription.list.billing-type',
  type: 'text'
}
const CUSTOMER_NAME = {
  id: 'customer.name',
  label: 'subscription.list.customer-name',
  type: 'text'
}
const START_AT = {
  id: 'start_at',
  label: 'subscription.list.start-at',
  type: 'date',
  format: 'date'
}

const FIELDS = [ID, CODE, PLAN_NAME, STATUS, TYPE, CUSTOMER_NAME, START_AT]

export { FIELDS }
