import buildUrl from '../utils/buildUrl.utils'

export default {
  CDN_BRAND_IMAGE: {
    url: (brand = '') => {
      if (!brand) throw new Error('Missing brand name')

      return buildUrl({
        url: `${process.env.VUE_APP_CDN_URL}/assets/images/logos/brands/png`,
        pathParams: `${brand}.png`
      })
    }
  },
  CANNY_URL: {
    url: ({ merchantId, token = '' }) => {
      if (!merchantId) throw new Error('Missing merchant id')
      if (!token) throw new Error('Missing sso token')

      return buildUrl({
        url: 'https://dashspagarme.canny.io/feature-requests',
        queryString: {
          category: 'dash-pagarme-20',
          selectedCategory: 'dash-pagarme-20',
          companyID: merchantId,
          ssoToken: token
        }
      })
    }
  }
}
