import { ScopeInstance, catchError } from '@/store'
const invite = {
  state: {
    invites: { items: [] },
    loadingList: true
  },
  getters: {
    invites (state) {
      return state.invites
    }
  },
  mutations: {
    toggleLoadingList (state, status) {
      state.loadingList = status
    },
    SET_INVITES (state, invites) {
      state.invites = invites
    }
  },
  actions: {
    GET_INVITES (context, params = {}) {
      context.commit('toggleLoadingList', true)
      params = params.query
        ? Object.assign(params.query, {
            merchantId: params.merchantId,
            accountId: params.accountId
          })
        : params
      return ScopeInstance.invite
        .getAll({ ...params, inviteId: '' })
        .then(res => {
          context.commit('toggleLoadingList', false)
          context.commit('SET_INVITES', res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    GET_INVITE (context, params = {}) {
      const { merchant_id, account_id } = params.query
      return ScopeInstance.invite
        .getById({
          merchantId: merchant_id,
          accountId: account_id,
          inviteId: params.inviteId
        })
        .then(res => {
          context.commit('SET_INVITES', res.data)
          return Promise.resolve(res.data)
        })
        .catch(err => {
          return catchError(err, context)
        })
    },
    CREATE_INVITE (context, params = {}) {
      const { merchantId, accountId } = params
      return ScopeInstance.invite
        .createAsAccount({ merchantId, accountId }, params.body)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    RESEND_INVITE (context, params = {}) {
      const { merchantId, accountId, inviteId } = params
      return ScopeInstance.invite
        .resendAsAccount({ merchantId, inviteId, accountId }, params.body)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    CANCEL_INVITE (context, params = {}) {
      const { merchantId, accountId, inviteId } = params
      return ScopeInstance.invite
        .cancelAsAccount({ merchantId, accountId, inviteId }, params.body)
        .then(res => Promise.resolve(res))
        .catch(err => {
          return catchError(err, context)
        })
    },
    toggleLoadingList ({ commit }, status) {
      commit('toggleLoadingList', status)
    }
  }
}

export default invite
