<template>
  <div class="emd-context-select">
    <header class="emd-context-select__header">
      <nav class="emd-segmented-controls">
        <button
          class="emd-segmented-controls__item"
          :class="{ _active: activeSegment === 'production' }"
          @click="toggleSegment('production')"
        >
          {{ $t('segment.prd') }}
        </button>
        <button
          class="emd-segmented-controls__item _warning"
          @click="toggleSegment('test')"
          :class="{ _active: activeSegment === 'test' }"
        >
          {{ $t('segment.tst') }}
        </button>
      </nav>
    </header>
    <main class="emd-context-select__body">
      <keep-alive>
        <component
          v-if="merchant && account"
          :is="activeSegmentPartial"
          :merchant="merchant"
          :account="account"
        />
      </keep-alive>
    </main>
    <footer
      class="emd-context-select__footer"
      v-if="activeSegment === 'test' && permissionIsEnabled('account:create')"
    >
      <a
        class="emd-btn _type_link _color_tertiary"
        @click="goToCreateTestAccount"
      >
        <i
          class="uil uil-plus"
          transform="width-14"
        />
        <span class="eu-ml_2">{{ $t('cta.createTestAccount') }}</span>
      </a>
    </footer>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "segment": {
      "prd": "Produção",
      "tst": "Teste"
    },
    "info": "Conta de Produção",
    "cta": {
      "createTestAccount": "Criar Conta de Teste"
    }
  },
  "en-US": {
    "segment": {
      "prd": "Production",
      "tst": "Test"
    },
    "info": "Production Account",
    "cta": {
      "createTestAccount": "Create Test Account"
    }
  }
}
</i18n>

<script>
import InteractiveListProduction from './InteractiveListProduction.vue'
import InteractiveListTest from './InteractiveListTest.vue'

export default {
  name: 'AppContextSelect',
  data () {
    return {
      activeSegment: 'production'
    }
  },
  computed: {
    merchant () {
      return this.$store.getters.merchant
    },
    account () {
      return this.$store.getters.account
    },
    isTestAccount () {
      return this.account.account_type.value === 'test'
    },
    activeSegmentPartial () {
      return this.activeSegment === 'production'
        ? InteractiveListProduction
        : InteractiveListTest
    }
  },
  methods: {
    toggleSegment (segment) {
      this.activeSegment = segment
    },
    goToCreateTestAccount () {
      this.$parent.contextDropdown()
      this.$router.push({ name: 'create-test-account' }).catch(err => err)
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  },
  mounted () {
    this.activeSegment = !this.isTestAccount ? 'production' : 'test'
  }
}
</script>
<style lang="css" scoped>
.emd-context-select__footer {
  margin: 0 12px;
}
</style>
