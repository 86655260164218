<template>
  <div
    class="emd-sidemenu sideMenu-container"
    :class="{ _open: isOpen }"
  >
    <div
      class="emd-sidemenu__body"
      ref="sideMenuBody"
    >
      <app-menu :merchantId="profile.merchant.id"></app-menu>
    </div>
  </div>
</template>

<script>
import AppMenu from './AppMenu'

export default {
  name: 'AppSidemenu',
  components: {
    AppMenu
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    profile: function () {
      return this.$store.getters.profile
    }
  },
  methods: {
    contextAnchorMerchant () {
      const url = `${process.env.VUE_APP_COMPANY_URL}/${this.profile.merchant.id}`
      window.location.assign(url)
    },
    toggleAppMenu () {
      this.$emit('appMenuClicked')
    },
    toggleMenuMobile () {
      this.$emit('mobileMenuClicked')
    }
  }
}
</script>
<style scoped>
.sideMenu-container {
  height: calc(100% - 140px) !important;
  width: 248px !important;
}

._has_communication .sideMenu-container {
  height: calc(100% - 180px) !important;
}

@media only screen and (max-width: 768px) {
  .sideMenu-container {
    height: 100% !important;
    width: 100% !important;
  }

  .sideMenu-container .emd-sidemenu__body {
    margin-top: 0 !important;
  }
}
</style>
