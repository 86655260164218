<template>
  <div>
    <hr
      class="emd-divider eu-bg_300 eu-my_4"
      v-if="
        entity.logs_url &&
        Object.entries(entity.logs_url) &&
        isEmployee &&
        !isSection
      "
    />
    <div
      v-if="
        entity.logs_url && Object.entries(entity.logs_url).length && isEmployee
      "
      :class="{ 'el-section': isSection, 'eu-mb_4': !isSection }"
    >
      <div
        :class="[classList, { 'emd-container _highlight_header': isSection }]"
      >
        <div
          class="emd-container__header"
          v-if="isSection"
        >
          <h2 class="emd-text _size_default _type_caption">{{ title }}</h2>
        </div>
        <div :class="{ 'emd-container__content': isSection }">
          <p
            class="emd-text _color_default _type_caption eu-mb_1"
            v-if="!isSection"
          >
            {{ title }}
          </p>
          <a
            :href="$sanitize(entity.logs_url.seq)"
            class="emd-btn _color_secondary _type_link eu-pr_3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t('log-links.seq') }}
            <i class="eu-ml_2 uil uil-external-link-alt" />
          </a>
          <a
            :href="$sanitize(entity.logs_url.splunk)"
            class="emd-btn _color_secondary _type_link eu-pl_3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t('log-links.splunk') }}
            <i class="eu-ml_2 uil uil-external-link-alt" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'LogLinks',
  props: {
    isSection: {
      type: Boolean,
      default: true
    },
    entity: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    position: {
      type: String,
      required: false
    }
  },
  computed: {
    isEmployee () {
      return this.$store.getters.isEmployee
    },
    classList () {
      var classList = []
      if (this.position) {
        classList.push(`_position_${this.position}`)
      }
      return classList
    }
  }
}
</script>

<style>
._position_initial {
  position: initial !important;
}
</style>
