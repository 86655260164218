<template>
  <div>
    <emd-new-filter
      v-if="showFilter"
      ref="filters"
      origin="orders"
      class="eu-width_100"
      sendFilterEvents
      :options="filterOptions"
      :placeholder="inputConfig.placeholder"
      :shortcutProp="inputConfig.shortcuts"
      :showShortcutCustomer="false"
      :additionalOptions="additionalOptions"
      :action="action"
      :loadingList="isLoading"
      :totalListItems="totalItems"
    />

    <section class="el-section">
      <div
        class="emd-container _highlight_header _header_spaced _footer_spaced_sm"
      >
        <section
          v-if="isLoading"
          class="eu-pa_6 eu-align_center"
        >
          <emd-loader type="_invert" />
        </section>

        <payment-links-table
          v-else
          :action="action"
          :isLoading="isLoading"
          :items="items"
          :pagination="pagination"
          :totalItems="totalItems"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { EmdLoader } from 'emerald-vue/src/components'

import EmdNewFilter from '@/components/emerald/organisms/EmdFilter/EmdNewFilter.vue'

import {
  paymentsLinksSearchEnabled,
  paymentsLinksDetailEnabled
} from '@/actions/payment-links.actions.js'

import PaymentLinksTable from './PaymentLinksTable.vue'

const FILTER_OPTIONS = [
  {
    name: 'filter.status',
    type: 'select',
    name_query: 'status',
    singleSelect: true,
    select_options: [
      {
        id: 'active',
        text: 'filter.active'
      },
      {
        id: 'expired',
        text: 'filter.expired'
      },
      {
        id: 'cancelled',
        text: 'filter.inactive'
      },
      {
        id: 'building',
        text: 'filter.building'
      }
    ]
  }
]

export default {
  name: 'PaymentLinks',

  components: {
    EmdLoader,
    EmdNewFilter,
    PaymentLinksTable
  },

  data () {
    return {
      action: 'GET_PAYMENTS_LINKS',
      additionalOptions: {
        sort_mode: 'desc'
      },
      filterOptions: FILTER_OPTIONS,
      inputConfig: {
        placeholder: this.$t('placeholder'),
        shortcuts: {
          idText: this.$t('shortcut.text'),
          idCode: undefined,
          example: undefined
        }
      },
    }
  },

  computed: {
    ...mapGetters([
      'PAYMENTS_LINKS',
      'PAYMENTS_LINKS_IS_LOADING',
      'permissionIsEnabled'
    ]),

    items () {
      return this.PAYMENTS_LINKS?.items ?? []
    },

    totalItems () {
      return this.items?.length ?? 0
    },

    pagination () {
      return this.PAYMENTS_LINKS?.pagination
    },

    isLoading () {
      return this.PAYMENTS_LINKS_IS_LOADING ?? false
    },

    showFilter () {
      return paymentsLinksSearchEnabled(this.permissionIsEnabled)
    },

    detail () {
      if (paymentsLinksDetailEnabled(this.permissionIsEnabled)) {
        return this.toDetail
      }
      return null
    }
  },

  mounted () {
    const routeQuery = this.$route.query
    const routeKeyQuery = Object.keys(routeQuery)
    if (routeKeyQuery.length) {
      routeKeyQuery.forEach(key => {
        if (Object.keys(this.additionalOptions).includes(key)) {
          this.additionalOptions[key] = routeQuery[key]
        }
      })
    }
    this.$refs.filters.search()
  }
}
</script>

<i18n>
  {
    "pt-BR": {
      "placeholder": "Buscar por nome do link...",
      "shortcut.text": "Nome do link"
    },
    "en-US": {
      "placeholder": "Find by link name...",
      "shortcut.text": "Name of the link"
    }
  }
</i18n>
