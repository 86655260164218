<template>
  <div>
    <emd-filter
      :options="filterOptions"
      :default="queryString"
      v-model="queryString"
      :loadingList="loadingList"
      :action="action"
      v-if="permissionIsEnabled('invoice:search')"
    />
    <div class="el-section">
      <div
        class="emd-container _highlight_header _header_spaced _footer_spaced_sm"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">{{ $t('invoice.list.title') }}</h2>
        </div>
        <div
          v-if="loadingList"
          class="eu-pa_6 eu-align_center"
        >
          <emd-loader :type="'_invert'" />
        </div>
        <div
          v-else
          class="emd-table-responsive"
        >
          <emd-table
            :fields="fields"
            :items="invoices.items"
            :toDetail="permissionIsEnabled('invoice:get') && toDetail"
          ></emd-table>
          <empty-list
            v-if="showEmptyList()"
            entity="invoices"
          ></empty-list>
          <empty-list-filter
            v-if="showEmptyListFilter()"
            entity="invoices"
          ></empty-list-filter>
        </div>
        <pagination
          v-if="!loadingList"
          :pagination="invoices.pagination"
          :totalShowing="totalItems"
          :type="'list'"
          :action="action"
        ></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { EmdLoader } from 'emerald-vue/src/components'
import EmdFilter from '@/components/emerald/organisms/EmdFilter.vue'
import EmdTable from '@/components/emerald/molecules/EmdTable.vue'
import EmptyList from '@/components/project/globals/EmptyList.vue'
import EmptyListFilter from '@/components/project/globals/EmptyListFilter.vue'
import Pagination from '@/components/project/globals/Pagination.vue'
export default {
  components: {
    EmdLoader,
    EmdFilter,
    EmdTable,
    EmptyList,
    EmptyListFilter,
    Pagination
  },
  data () {
    return {
      queryString: {
        size: 30,
        period_type: 'date_filters',
        date_filters: 'SevenDays'
      },
      action: 'GET_INVOICES',
      fields: [
        {
          id: 'id',
          label: 'invoice.list.id',
          type: this.permissionIsEnabled('invoice:get') ? 'link' : 'text',
          link: {
            to: 'invoicesDetail',
            params: { id: 'id' }
          }
        },
        {
          id: 'code',
          label: 'invoice.list.code',
          type: 'text'
        },
        {
          id: 'customer.name',
          label: 'invoice.list.name',
          type: 'text'
        },
        {
          id: 'amount',
          label: 'invoice.list.amount',
          type: 'text'
        },
        {
          id: 'status',
          label: 'invoice.list.status',
          type: 'status'
        },
        {
          id: 'due_at',
          label: 'invoice.list.due-at',
          type: 'date',
          format: 'date'
        },
        {
          id: 'payment_method',
          label: 'invoice.list.payment-method',
          type: 'text'
        },
        {
          id: 'created_at',
          label: 'invoice.list.created-at',
          type: 'date',
          format: 'dateAndHour'
        }
      ],
      toDetail: {
        name: 'invoicesDetail',
        params: {
          id: 'id'
        }
      }
    }
  },
  computed: {
    filterOptions () {
      return this.$store.state.invoice.filterOptions
    },
    invoices () {
      return this.$store.getters.invoices
    },
    loadingList () {
      return this.$store.state.invoice.loadingList
    },
    totalItems () {
      return this.invoices.items.length
    }
  },
  methods: {
    showEmptyList () {
      return (
        !this.loadingList &&
        this.invoices.pagination &&
        this.invoices.pagination.total_items === 0 &&
        Object.entries(this.queryString).length <= 2
      )
    },
    showEmptyListFilter () {
      return (
        !this.loadingList &&
        this.invoices.pagination &&
        this.invoices.pagination.total_items === 0 &&
        Object.entries(this.queryString).length > 2
      )
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  }
}
</script>
