<template>
  <div
    class="el-core__header menu-header"
    :class="{ _open: isOpen, '_drawer-open': drawerOpen }"
  >
    <app-core-header-left
      :merchant="profile.merchant"
      :account="profile.merchant.account"
      :companyDocument="companyDocument"
      :isOpen="isOpen"
      @appMenuClicked="toggleAppMenu"
    ></app-core-header-left>
    <app-core-header-right
      :merchant="profile.merchant"
      :account="profile.merchant.account"
      :profile="profile.user"
      :isOpen="drawerOpen"
      @openOnboarding="openOnboarding"
      @drawerMenuClicked="toggleDrawerMenu"
    ></app-core-header-right>
  </div>
</template>

<script>
import AppCoreHeaderLeft from '@/components/project/ui/menu/AppCoreHeaderLeft.vue'
import AppCoreHeaderRight from '@/components/project/ui/menu/AppCoreHeaderRight.vue'
import { formatCnpj } from '@/utils/formatCpfCnpj'

export default {
  name: 'AppCoreHeader',
  components: {
    AppCoreHeaderLeft,
    AppCoreHeaderRight
  },
  props: {
    isOpen: {
      type: Boolean
    },
    drawerOpen: {
      type: Boolean
    }
  },
  computed: {
    profile: function () {
      return this.$store.getters.profile
    },
    companyDocument: function () {
      if (this.permissionIsEnabled('settings.account:get')) {
        this.$store.dispatch('GET_ACCOUNT', {
          merchantId: this.$store.getters.merchantId,
          accountId: this.$store.getters.accountId
        })
        const companySettings = this.$store.getters.accountSettings

        return formatCnpj(companySettings.account.document || '')
      } else {
        return ''
      }
    }
  },
  methods: {
    toggleAppMenu () {
      this.$emit('appMenuClicked')
    },
    toggleDrawerMenu () {
      this.$emit('drawerMenuClicked')
    },
    openOnboarding () {
      this.$emit('openOnboarding')
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  }
}
</script>
<style scoped>
.menu-header {
  position: relative !important;
}
</style>
