<template>
  <div>
    <div class="el-section">
      <div
        class="emd-container _highlight_header _header_spaced _footer_spaced_sm"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">
            {{ $t('recipients.list.title') }}
          </h2>
          <emd-button
            v-if="!hasUserTwoFactorAuthentication"
            @click="openConfigMfaModal"
            color="primary"
            :text="$t('recipients.create.button')"
          />
          <router-link
            :to="{ name: 'recipientsCreate' }"
            tag="button"
            class="emd-btn _color_primary _size_small jsModalOpen"
            v-if="showCreatRecipient"
          >
            {{ $t('recipients.create.button') }}
          </router-link>
        </div>
        <div
          v-if="loadingList"
          class="eu-display_flex eu-align-items_center_md eu-justify_center eu-pa_6"
        >
          <emd-loader :type="'_invert'" />
        </div>
        <div
          v-else
          class="emd-table-responsive"
        >
          <emd-table
            :fields="fields"
            :items="recipients.items"
            :toDetail="this.permissionIsEnabled('recipients:get') && toDetail"
          >
            <div style="text-transform: initial; letter-spacing: 0">
              <p>{{ $t('recipients.list.info', { doc: link }) }}</p>
            </div>
          </emd-table>
          <empty-list
            v-if="showEmptyList"
            :entity="emptyData.entity"
            :svg="emptyData.svg"
          ></empty-list>
        </div>
        <pagination
          v-if="!loadingList"
          :pagination="recipients.pagination"
          :totalShowing="totalItems"
          :type="'list'"
          :action="'GET_RECIPIENTS'"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyList from '@/components/project/globals/EmptyList.vue'
import Pagination from '@/components/project/globals/Pagination.vue'
import EmdTable from '@/components/emerald/molecules/EmdTable.vue'
import { EmdLoader, EmdButton } from 'emerald-vue/src/components'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    EmptyList,
    Pagination,
    EmdTable,
    EmdLoader,
    EmdButton
  },
  data () {
    return {
      queryString: {
        size: 5
      },
      fields: [
        {
          id: 'id',
          label: 'recipients.list.id',
          type: this.permissionIsEnabled('recipients:get') ? 'link' : 'text',
          link: {
            to: 'recipientsDetail',
            params: { id: 'id', tab: 'info' }
          }
        },
        {
          id: 'name',
          label: 'recipients.list.name',
          type: 'text'
        },
        {
          id: 'document',
          label: 'recipients.list.document',
          type: 'text'
        },
        {
          id: 'status',
          label: 'recipients.list.status',
          type: 'status',
          info: true
        },
        {
          id: 'default_bank_account_id',
          label: 'recipients.list.bank_account',
          type: 'text'
        },
        {
          id: 'created_at',
          label: 'recipients.list.created_at',
          type: 'date',
          format: 'date'
        }
      ],
      toDetail: {
        name: 'recipientsDetail',
        params: { id: 'id', tab: 'info' }
      }
    }
  },
  computed: {
    ...mapGetters([
      'hasUserTwoFactorAuthentication',
      'recipients',
      'hasLocked',
      'whitelabel'
    ]),
    merchantId: function () {
      return this.$route.params.merchant_id
    },
    accountId: function () {
      return this.$route.params.account_id
    },
    loadingList: function () {
      return this.$store.state.customer.loadingList
    },
    totalItems: function () {
      return this.recipients.items.length
    },
    queryParams: function () {
      return this.$route.query
    },
    tags: function () {
      return {
        mundipagg: '#recebedores',
        pagarme: '#recebedores-1'
      }
    },
    theme: function () {
      return this.whitelabel.theme
    },
    link: function () {
      return `${process.env.VUE_APP_URL_DOCUMENTATION}reference${
        this.tags[this.theme]
      }`
    },
    showEmptyList: function () {
      const emptyList =
        this.recipients.pagination &&
        this.recipients.pagination.total_items === 0
      return !this.loadingList && (this.hasLocked || emptyList)
    },
    emptyData: function () {
      return {
        entity: this.hasLocked ? 'locked' : 'recipients',
        svg: this.hasLocked ? 'no-results' : 'orders'
      }
    },
    showCreatRecipient () {
      return (
        this.permissionIsEnabled('recipients:create') &&
        this.hasUserTwoFactorAuthentication
      )
    }
  },
  methods: {
    ...mapActions({
      setConfigMfaModal: 'SET_CONFIG_MFA_MODAL'
    }),
    componentIsEnabled (entity, action) {
      return this.$store.getters.componentIsEnabled(entity, action)
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    },
    openConfigMfaModal () {
      this.setConfigMfaModal(true)
    }
  },
  created () {
    const query = {
      page: 1,
      size: 30
    }

    Object.assign(query, this.queryParams)
    this.$router.replace({ query: query }).catch(err => err)
    this.$store.dispatch('GET_RECIPIENTS', {
      query,
      merchantId: this.merchantId,
      accountId: this.accountId
    })
  }
}
</script>
