import dayjs from 'dayjs'
const localizedFormat = require('dayjs/plugin/localizedFormat')
const utc = require('dayjs/plugin/utc')
require('dayjs/locale/pt')

dayjs.extend(localizedFormat)
dayjs.extend(utc)

const formats = {
  date: 'L',
  dateAndHour: 'L - LTS'
}

export function setDateLocale (locale) {
  if (locale) {
    dayjs.locale(locale.substring(0, 2))
  }
}

export function dateFormat (date, format = 'date') {
  if (!date) {
    return '-'
  }

  return dayjs.utc(date).format(formats[format])
}

export function statusClass (status) {
  status = status.toLowerCase()

  const STATUS_MAP = {
    success: [
      'active',
      'ativo',
      'paid',
      'pago',
      'overpaid',
      'paga a maior',
      'underpaid',
      'paga a menor',
      'captured',
      'capturado',
      'sent',
      'enviado',
      'approved',
      'aprovado',
      'parcialmente capturado',
      'partial capture',
      'none',
      'accepted',
      'aceito',
      'paga',
      'to receive'
    ],
    warning: [
      'future',
      'futuro',
      'pending',
      'pendente',
      'processing',
      'processando',
      'partial refunded',
      'parcialmente estornado',
      'parcialmente cancelado',
      'partial void',
      'generated',
      'gerado',
      'manual',
      'authorized pending capture',
      'autorizado captura pendente',
      'cartão de crédito pendente',
      'pending credit card',
      'aguardando cancelamento',
      'waiting cancellation',
      'aguardando captura',
      'waiting capture',
      'major',
      'pending authentication',
      'pendente de autenticação',
      'registration',
      'em registro',
      'in registration',
      'affiliation',
      'em afiliação',
      'in affiliation',
      'suspended',
      'suspenso',
      'suspended',
      'resgistro',
      'registration',
      'afiliação',
      'affiliation',
      'a receber',
      'to recive'
    ],
    danger: [
      'canceled',
      'cancelado',
      'estornado',
      'refunded',
      'inactive',
      'inativo',
      'failed',
      'falha',
      'deleted',
      'deletado',
      'with error',
      'com erro',
      'not paid',
      'não pago',
      'expired',
      'expirado',
      'not authorized',
      'não autorizado',
      'voided',
      'negado',
      'erro no cancelamento',
      'error on voiding',
      'erro no estorno',
      'error on refunding',
      'critical',
      'refused',
      'recusado',
      'blocked',
      'bloqueado'
    ],
    info: [
      'gerado',
      'generated',
      'minor',
      'maintenance',
      'waiting refund',
      'aguardando estorno',
      'waiting payment',
      'aguardando pagamento'
    ],
    black: ['chargeback', 'chargeback reapresentado', 'chargeback refund'],
    purple: [
      'estorno',
      'estorno parcial',
      'estornada',
      'refunded',
      'partial refund'
    ]
  }

  return {
    _color_success: STATUS_MAP.success.includes(status),
    _color_warning: STATUS_MAP.warning.includes(status),
    _color_danger: STATUS_MAP.danger.includes(status),
    _color_info: STATUS_MAP.info.includes(status),
    _color_black: STATUS_MAP.black.includes(status),
    _color_purple: STATUS_MAP.purple.includes(status)
  }
}

export function pluralIntervals (intervalCount, interval) {
  interval = interval?.toLowerCase()
  if (intervalCount > 1) {
    const plurals = [
      {
        dia: 'dias',
        day: 'days',
        semana: 'semanas',
        week: 'weeks',
        mês: 'meses',
        month: 'months',
        ano: 'anos',
        year: 'years'
      }
    ]
    const plural = plurals.find(e => e[interval])
    return plural[interval]
  } else {
    return interval
  }
}

export function formatCurrency (value) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  })
  return formatter.format(value)
}

export function currencyToNumber (value) {
  const mapObj = {
    R$: '',
    '.': '',
    ',': '.'
  }
  return Number(+value.replace(/R\$|\.|,/gi, matched => mapObj[matched]))
}

export function percentageToNumber (value) {
  const number = +replaceAll(value, {
    ',': '.',
    '%': ''
  })

  return Number(number) / 100
}

export function replaceAll (str, mapObj) {
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi')
  return str.replace(re, matched => mapObj[matched.toLowerCase()])
}
