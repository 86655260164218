<template>
  <div class="emd-container__footer">
    <span
      data-testid="pagination-results"
      class="emd-text _color_default _size_small"
    >
      <span
v-if="pagination.total_items"
        >{{ $t('pagination.showing') }} {{ this.totalShowing }}
        {{ $t('pagination.of') }} {{ pagination.total_items }}
        {{ $t('pagination.results') }}</span
      >
    </span>
    <div>
      <button
        data-testid="button-previous"
        class="emd-btn _color_primary _type_text"
        :disabled="disablePreviousButton()"
        v-on:click="changePage('previous')"
      >
        {{ $t('pagination.previous') }}
      </button>
      <button
        data-testid="button-next"
        class="emd-btn _color_primary _type_text"
        :disabled="disableNextButton()"
        v-on:click="changePage('next')"
      >
        {{ $t('pagination.next') }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: ['pagination', 'totalShowing', 'action', 'type', 'entity'],
  computed: {
    merchant: function () {
      return this.$store.getters.merchant
    },
    account: function () {
      return this.$store.getters.account
    }
  },
  methods: {
    refreshQueryUrl: function (query) {
      delete query.merchantId
      delete query.accountId
      if (query.after || query.before) delete query.page
      this.$router.replace({ query: query }).catch(err => err)
    },
    disablePreviousButton: function () {
      if (
        (this.type === 'dynamic' && !this.pagination.before) ||
        (this.type === 'list' && this.pagination.current_page === 1)
      ) {
        return true
      }
      return false
    },
    disableNextButton: function () {
      if (
        (this.type === 'dynamic' && !this.pagination.after) ||
        (this.type === 'list' &&
          this.pagination.current_page === this.pagination.total_page) ||
        this.pagination.total_page === 0
      ) {
        return true
      }
      return false
    },
    changePage: function (change) {
      if (change === 'previous' && this.type === 'dynamic') {
        this.goToPage('before', this.pagination.before)
      } else if (change === 'previous' && this.type === 'list') {
        this.goToPage('page', this.pagination.current_page - 1)
      } else if (change === 'next' && this.type === 'dynamic') {
        this.goToPage('after', this.pagination.after)
      } else if (change === 'next' && this.type === 'list') {
        this.goToPage('page', this.pagination.current_page + 1)
      }
    },
    goToPage: function (key, value) {
      const queryString = Object.assign({}, this.$route.query)
      delete queryString.page
      delete queryString.before
      delete queryString.after
      queryString[key] = value
      const params = {
        merchantId: this.merchant.id,
        accountId: this.account.id,
        query: queryString
      }
      if (this.entity) {
        params[this.entity] = this.$route.params.id
      }
      this.$store.dispatch(this.action, params)
      this.refreshQueryUrl(queryString)
    }
  }
}
</script>
