export function formatCpfCnpj (value) {
  const cnpjCpf = value.replace(/\D/g, '')

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '***.$2.$3-**')
  }

  return cnpjCpf.replace(
    /(\d{2})(\d{3})(\d{3})(\d{2})(\d{4})/g,
    '***.$2.$3/$4****'
  )
}

export function formatCnpj (value) {
  const cnpj = value.replace(/\D/g, '')
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}
