<template>
  <div class="el-section">
    <div class="emd-breadcrumb">
      <ol>
        <li
          class="emd-breadcrumb__item"
          v-for="(route, index) in routes"
          :key="index"
          v-bind:class="{ ' _active': index === routes.length - 1 }"
        >
          <router-link
            v-if="route.name"
            :to="{ name: route.name }"
            class="emd-link"
            >{{ $t(`breadcrumb.${route.name}`) }}</router-link
          >
          <a
            v-else
            class="emd-text _color_default"
            >{{ $t(`breadcrumb.${route}`) }}</a
          >
        </li>
      </ol>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmdBreadcrumb',
  computed: {
    routeName: function () {
      return this.$route.name
    },
    categoryRoutes: function () {
      return this.$store.getters.categoryRoutes
    },
    routes: function () {
      let parentRoute, actualRoute, breadcrumb

      if (this.routeName.includes('Detail')) {
        parentRoute = this.routeName.replace(/Detail/g, '')
        actualRoute =
          this.categoryRoutes.find(route =>
            route.children.includes(parentRoute)
          ) || {}
        if (Object.entries(actualRoute).length) {
          breadcrumb = [actualRoute.category, { name: parentRoute }, 'detail']
        } else {
          breadcrumb = [{ name: parentRoute }, 'detail']
        }
      } else if (this.routeName.includes('Request')) {
        parentRoute = this.routeName.replace(/Request/g, '')
        actualRoute =
          this.categoryRoutes.find(route =>
            route.children.includes(parentRoute)
          ) || {}
        if (Object.entries(actualRoute).length) {
          breadcrumb = [actualRoute.category, { name: parentRoute }, 'request']
        } else {
          breadcrumb = [{ name: parentRoute }, 'request']
        }
      } else if (this.routeName.includes('recipients')) {
        if (this.routeName === 'recipients') return ['recipients']

        parentRoute = 'recipients'

        const current = this.routeName.replace('recipients', '').toLowerCase()
        breadcrumb = [{ name: parentRoute }, `recipients-${current}`]

        return breadcrumb
      } else {
        actualRoute =
          this.categoryRoutes.find(route =>
            route.children.includes(this.routeName)
          ) || {}
        if (Object.entries(actualRoute).length) {
          if (
            this.routeName === 'webhook-config-form' &&
            this.$route.params.id
          ) {
            breadcrumb = [actualRoute.category, 'webhook-config-edit']
          } else {
            breadcrumb = [actualRoute.category, this.routeName]
          }
        } else {
          breadcrumb = [this.routeName]
        }
      }
      return breadcrumb
    }
  }
}
</script>
