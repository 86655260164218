<template>
  <button
    class="stn-button is-primary is-small"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'jade-button'
}
</script>

<style scoped>
@import '@stone-payments/jade/css/button.css';
</style>
