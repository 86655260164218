const BUSINESS_MODEL_ENUM = {
  PSP: 'p_s_p',
  GATEWAY: 'gateway',
  HYBRID: 'gateway_and_psp'
}

export const isPsP = businessModel => businessModel === BUSINESS_MODEL_ENUM.PSP

export const isGateway = businessModel =>
  businessModel === BUSINESS_MODEL_ENUM.GATEWAY

export const isHybrid = businessModel =>
  businessModel === BUSINESS_MODEL_ENUM.HYBRID

export const isPsPOrHybrid = businessModel =>
  [BUSINESS_MODEL_ENUM.PSP, BUSINESS_MODEL_ENUM.HYBRID].includes(businessModel)

export const isGatewayOrHybrid = businessModel =>
  [BUSINESS_MODEL_ENUM.GATEWAY, BUSINESS_MODEL_ENUM.HYBRID].includes(
    businessModel
  )

export default {
  BUSINESS_MODEL_ENUM,
  isPsP,
  isGateway,
  isHybrid,
  isPsPOrHybrid,
  isGatewayOrHybrid
}
