<template>
  <div
    class="emd-input-tag"
    @click="focus"
    v-bind:class="{ _focus: isFocus, _disabled: disabled }"
  >
    <ul>
      <li
        v-for="(item, index) in items"
        :key="index"
      >
        <span
          class="emd-tag"
          v-bind:class="{ _color_info: !isLoading, _disabled: disabled }"
        >
          {{ item }}
          <i
            class="uil uil-times-circle"
            v-on:click="removeItem(index)"
          />
        </span>
      </li>
    </ul>
    <input
      type="text"
      v-on:keydown="addItem"
      v-on:blur="isFocus = false"
      v-on:focus="isFocus = true"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
export default {
  name: 'EmdInputTag',
  props: {
    list: {
      type: Array,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      isFocus: false,
      items: [],
      lastKey: null
    }
  },
  watch: {
    items (newValue) {
      this.$emit('update', this.items)
    },
    list (newValue) {
      this.items = this.list
    }
  },
  computed: {
    input () {
      return this.$el.getElementsByTagName('input')[0]
    }
  },
  created () {
    this.items = this.list
  },
  methods: {
    removeItem (index) {
      if (this.disabled) return
      this.items.splice(index, 1)
    },
    addItem (e) {
      if (this.disabled) return
      const value = e.target.value.replace(';', '')
      if (e.key === 'Enter' || e.key === 'Tab' || e.key === ';') {
        e.preventDefault()
        if (value) this.items.push(value)
        this.input.value = ''
      } else if (e.key === 'Backspace' && this.lastKey === 'Backspace') {
        if (value === '') this.items.splice(this.items.length - 1, 1)
      }
      this.lastKey = e.key
    },
    focus () {
      if (this.disabled) return
      if (!this.isFocus) {
        this.input.focus()
      }
    }
  }
}
</script>
