const USER_ROLE_TYPES = {
  SELLER: 'seller'
}

/**
 * @param {String} type user.role.type.key
 * @returns Boolean
 */
const isSeller = type => {
  return USER_ROLE_TYPES.SELLER.toUpperCase() === type.toUpperCase()
}

export { USER_ROLE_TYPES, isSeller }
