<template>
  <div>
    <emd-filter
      :options="filterOptions"
      :default="queryString"
      v-model="queryString"
      :loadingList="loadingList"
      :action="action"
      v-if="permissionIsEnabled('account_staff:search')"
    />
    <div class="el-section">
      <div
        class="emd-container _highlight_header _header_spaced _footer_spaced_sm"
      >
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">{{ $t('users.title') }}</h2>
          <router-link
            v-if="permissionIsEnabled('account_staff.invite:create')"
            :to="{ name: 'invites-create' }"
            class="jsModalOpen"
          >
            <jade-button>
              {{ $t('users.invite') }}
            </jade-button>
          </router-link>
        </div>
        <div
          v-if="loadingList"
          class="eu-pa_6 eu-align_center"
        >
          <emd-loader :type="'_invert'" />
        </div>
        <div
          v-else
          class="emd-table-responsive"
        >
          <emd-table
            :fields="fields"
            :items="users.items"
            :toDetail="permissionIsEnabled('account_staff:get') && toDetail"
          ></emd-table>
          <empty-list
            v-if="showEmptyList"
            entity="users"
          ></empty-list>
        </div>
        <pagination
          v-if="!loadingList"
          :pagination="users.pagination"
          :totalShowing="totalItems"
          :type="'list'"
          :action="action"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { EmdLoader } from 'emerald-vue/src/components'
import EmdTable from '@/components/emerald/molecules/EmdTable.vue'
import EmptyList from '@/components/project/globals/EmptyList.vue'
import EmdFilter from '@/components/emerald/organisms/EmdFilter.vue'
import Pagination from '@/components/project/globals/Pagination.vue'
import JadeButton from '@/components/jade-experimental/JadeButton.vue'

export default {
  name: 'Users',
  components: {
    EmdLoader,
    EmdTable,
    Pagination,
    EmptyList,
    EmdFilter,
    JadeButton
  },
  data () {
    return {
      action: 'GET_USERS',
      queryString: {
        size: 30,
        page: 1,
        first_name: '',
        last_name: '',
        email: ''
      },
      fields: [
        {
          id: 'first_name',
          label: 'users.name',
          type: this.permissionIsEnabled('account_staff:get') ? 'link' : 'text',
          link: {
            to: 'usersDetail',
            params: { id: 'id' }
          }
        },
        {
          id: 'email',
          label: 'users.email',
          type: 'text'
        },
        {
          id: 'role.value;type.value',
          label: 'users.permission',
          type: 'conditional'
        }
      ],
      toDetail: {
        name: 'usersDetail',
        params: {
          id: 'id'
        }
      }
    }
  },
  computed: {
    users () {
      return this.$store.getters.users
    },
    loadingList () {
      return this.$store.state.user.loadingList
    },
    totalItems () {
      return this.users.items.length
    },
    merchantId () {
      return this.$route.params.merchant_id
    },
    accountId () {
      return this.$route.params.account_id
    },
    queryParams () {
      return this.$route.query
    },
    showEmptyList () {
      return (
        !this.loadingList &&
        this.users.pagination &&
        this.users.pagination.total_items === 0
      )
    },
    filterOptions () {
      return this.$store.state.user.filterOptions
    }
  },
  methods: {
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  },
  created () {
    const params = {
      merchantId: this.merchantId,
      accountId: this.accountId
    }
    Object.assign(params, this.queryParams)
    this.$store.dispatch('GET_USERS', params)
  }
}
</script>
